<template>
        <jet-form-section>
            <template #title>
                Approve coach
            </template>

            <template #description>
                <div v-if="user.approval_status === 1">
                    This coach has not yet completed the first part of the profile.
                </div>
                <div v-if="user.approval_status === 2">
                    This coach is waiting for approval.
                </div>
                <div v-if="user.approval_status === 3">
                    This coach has been approved.
                </div>
            </template>

            <template #form>
                <div
                    v-if="showMessage"
                    class="confirm-msg show"
                >
                    <i><img
                        src="./../../images/icon-correct.svg"
                        alt="Correct"
                    ></i>Coach approved.
                </div>

                <form
                    v-if="needsApproval"
                    @submit.prevent="submitForm"
                >
                    <ul class="form-wrapper">
                        <li class="form-row checkbox-input">
                            <div class="checkbox-grp" data-hj-suppress>
                                <div class="checkbox-row">
                                    <input
                                        id="approved"
                                        v-model="form.approved"
                                        type="checkbox"
                                    >
                                    <label
                                        for="approved"
                                        class="checkbox-label"
                                    >Mark coach as approved</label>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <input
                        class="form-button"
                        :disabled="isDisabled"
                        value="Save"
                        type="submit"
                        :class="{ 'opacity-25': isDisabled }"
                    >
                </form>
            </template>
        </jet-form-section>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import JetFormSection from "@/Jetstream/FormSection";
    
    export default {
        components: {
            JetFormSection,
        },
        props: {
            user: {
                type: Object,
                default: () => {},
            }
        },
        emits: ["UserUpdated"],
        data() {
            return {
                showMessage: false,
                form: {
                    errors: {},
                    processing: false,
                    approved: (this.user.approval_status === 3)
                }
            };
        },
        computed: {
            isDisabled() {
                return this.form.processing;
            },
            needsApproval() {
                return (this.user.approval_status === 2);
            }
        },
        methods: {
            async submitForm() {
                this.form.processing = true;
                this.form.errors = {};
                this.showMessage = false;

                let payload = {
                    approval_status: (this.form.approved) ? 3 : 2
                };

                let url = "users/" + this.user.id;
                let method = "patch";

                await apiClient.get( ApiService.getApiUrl() + "/sanctum/csrf-cookie");
                apiClient({
                    method: method,
                    url: url,
                    data: payload,
                })
                    .then(response => {
                        this.showMessage = true;
                        this.$emit("UserUpdated");
                    }).catch(error => {
                    this.form.errors = error.response.data.errors;
                }).finally(() => {
                    this.form.processing = false;
                });
                
                
            }
        }
    };
</script>