<template>
        <textarea 
            :class="['h-40 w-full text-base p-4 border rounded-md', disabled && 'bg-white resize-none']"
            :disabled="disabled"
            :placeholder="placeholder"
            :value="value"
            @input="onInput"
        />
</template>
<script>
    export default {
        props: {
            value: {
                type: String,
                default: "",
            },
            placeholder: {
                type: String,
                default: "",
            },
            disabled: Boolean
        },
        emits: ["inputText"],
        methods: {
            onInput: function (event) {
                this.$emit("inputText", event.target.value);
            }
        }
    };
</script>