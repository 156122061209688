<template>
    
    
    <page-header title="Users"/>

    <div class="right-col mb-4">
        <router-link class="back-btn" :to="{ name: 'users' }">Back to overview</router-link>
    </div>
        

    <jet-form-section @submitted="submitForm">

        <template #title>
            Invite user
        </template>


        <template #form>
            <form class="main-form">
                <ul class="form-wrapper">
                    <div
                        class="confirm-msg"
                        :class="{ 'show' : userInvited}"
                    >
                        <i><img
                            src="./../../images/icon-correct.svg"
                            alt="Correct"
                        ></i>User invited.
                    </div>
                    <li class="form-row two-input">
                        <div
                            class="input-grp"
                            :class="{ 'input-error' : form.errors.first_name, 'input-filled' : !v$.form.first_name.$invalid }"
                        >
                            <jet-label
                                for="first_name"
                                class="form-label"
                                value="First name *"
                            />
                            <jet-input
                                data-hj-suppress
                                id="first_name"
                                v-model="form.first_name"
                                type="text"
                                class="form-input"
                                autofocus
                            />
                            <span
                                v-for="error in form.errors.first_name"
                                :key="error"
                                class="error-msg"
                            >{{ error }}</span>
                        </div>
                        <div
                            class="input-grp"
                            :class="{ 'input-error' : form.errors.last_name, 'input-filled' : !v$.form.last_name.$invalid }"
                        >
                            <jet-label
                                for="last_name"
                                class="form-label"
                                value="Last name *"
                            />
                            <jet-input
                                data-hj-suppress
                                id="last_name"
                                v-model="form.last_name"
                                type="text"
                                class="form-input"
                            />
                            <span
                                v-for="error in form.errors.last_name"
                                :key="error"
                                class="error-msg"
                            >{{ error }}</span>
                        </div>
                    </li>
                    <li class="form-row">
                        <div
                            class="input-grp"
                            :class="{ 'input-error' : form.errors.email, 'input-filled' : !v$.form.email.$invalid }"
                        >
                            <jet-label
                                for="email"
                                class="form-label"
                                value="E-mail *"
                            />
                            <jet-input
                                data-hj-suppress
                                id="email"
                                v-model="form.email"
                                type="text"
                                class="form-input"
                                autocomplete="email"
                            />
                            <span
                                v-for="error in form.errors.email"
                                :key="error"
                                class="error-msg"
                            >{{ error }}</span>
                        </div>
                    </li>
                    <li class="form-row">
                        <div
                            class="input-grp"
                            :class="{ 'input-error' : form.errors.role_id, 'input-filled' : !v$.form.role_id.$invalid }"
                        >
                            <jet-label
                                for="role_id"
                                class="form-label"
                                value="Role"
                            />
                            <select
                                data-hj-suppress
                                id="role_id"
                                v-model="form.role_id"
                                class="form-input"
                            >
                                <option
                                    disabled
                                    value=""
                                >
                                    Please select
                                </option>
                                <option
                                    v-for="role in roles"
                                    :key="role.id"
                                    :value="role.id"
                                >
                                    {{ role.name }} ({{ role.type.name }})
                                </option>
                            </select>
                            <span class="error-msg">{{ form.errors.country_id }}</span>
                        </div>
                    </li>
                    <li class="form-row">
                        <primary-button
                            title="Invite user"
                            :disabled="isDisabled"
                            @click.prevent="submitForm"
                        />
                    </li>
                </ul>
            </form>
        </template>
    </jet-form-section>
</template>
<script>
    import { mapGetters } from "vuex";
    import JetFormSection from "@/Jetstream/FormSection";
    import JetInput from "@/Jetstream/InputField";
    import JetLabel from "@/Jetstream/InputLabel";
    import PageHeader from "@/components/PageHeader";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import { default as ApiService, apiClient } from "@/services/ApiService";
    import { useVuelidate } from "@vuelidate/core";
    import {
        required, email
    } from "@vuelidate/validators";


    export default {
        components: {
            JetFormSection,
            JetInput,
            JetLabel,
            PrimaryButton,
            PageHeader,
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                userInvited: false,
                roles: {},
                form: {
                    processing: false,
                    errors: {},
                    first_name: "",
                    last_name: "",
                    email: "",
                    role_id: ""
                }
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
            ...mapGetters(["authUser"]),
        },
        created() {
            this.fetchRoles();
        },
        validations: {
            form: {
                first_name: {required},
                last_name: {required},
                email: { required, email },
                role_id: { required }
            }
        },
        methods: {
            async submitForm() {
                this.form.processing = true;
                this.form.errors = {};
                this.userInvited = false;
                const payload = {
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    email: this.form.email,
                    role_id: this.form.role_id
                };
                
                await apiClient.get( ApiService.getApiUrl() + "/sanctum/csrf-cookie");
                apiClient({
                    method: "post",
                    url: "/users/invite",
                    data: payload,
                })
                    .then(response => {
                        console.log(response);
                        this.userInvited = true;

                        this.form.first_name = "";
                        this.form.last_name = "";
                        this.form.email = "";
                        this.form.role_id = "";
                        })
                    .catch(error => {
                        console.log(error);
                        if( error.response ){
                            this.form.errors = error.response.data.errors;
                        }
                    }).finally(() => {
                        this.form.processing = false;

             
                    });
                
                
            },
            fetchRoles() {
                apiClient.get("/roles")
                .then(response => {
                    this.roles = response.data.data;
                });
            }
        }
    };
</script>