<template>
        <button
            :disabled="disabled"
            class="text-primary hover:underline transition-all duration-300 text-base font-semibold"
        >
            {{ title }}
        </button>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
                default: "",
            },
            disabled: Boolean,
        },
    };
</script>