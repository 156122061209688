import moment from "moment";

const formatMonthlyDate = (date) => {
    if (!date) return "now";
    let formattedDate =  moment(date);
    if (formattedDate.diff(moment()) >= 0) return "now";
    return `${formattedDate.format("MMMM").slice(0, 3)} - ${formattedDate.format("YYYY")}`;
};

export default formatMonthlyDate;
