<template>
        <div>
            <page-header
                :title="user?.name"
            />
            <approve-coach-form
                v-if="isCoach"
                :user="user"
                @user-updated="fetchUser()"
            />
            <edit-role-form
                v-if="$can('Edit user roles')"
                :user="user"
                @user-updated="fetchUser()"
            />
            <trajectory-form
                v-if="!isCoach && !isAdmin"
                :id="id"
            />
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import EditRoleForm from "@/Pages/Users/EditRoleForm";
    import TrajectoryForm from "@/Pages/Users/TrajectoryForm";
    import ApproveCoachForm from "@/Pages/Users/ApproveCoachForm";
    import PageHeader from "@/components/PageHeader";
    
    export default {
        components: {
            EditRoleForm,
            ApproveCoachForm,
            TrajectoryForm,
            PageHeader,
        },
        props: {
            id: {
                type: Number,
                default: 0,
            }
        },
        data() {
            return {
                "user": {},
            };
        },
        computed: {
            isCoach() {
                return (this.user?.roles) ? this.user.roles[0].type.name.toLowerCase() === "coach" : false;
            },
            isAdmin() {
                return (this.user?.roles) ? this.user.roles[0].type.name.toLowerCase() === "admin" : false;
            },
        },
        mounted() {
            this.fetchUser();
        },
        methods: {
            fetchUser() {
                apiClient.get("/users/"+this.id)
                    .then(response => {
                        this.user = response.data?.data;
                    });
            },
        }
    };
</script>