<template>
        <jet-form-section @submitted="submitForm">
            <template #title>
                Update Role
            </template>

            <template #description>
                Update the users' role.
            </template>

            <template #form>
                <div
                    class="confirm-msg"
                    :class="{ 'show' : roleUpdated}"
                >
                    <i><img
                        src="./../../images/icon-correct.svg"
                        alt="Correct"
                    ></i>Role updated successfully.
                </div>
                <form>
                    <ul class="form-wrapper">
                        <li
                            class="form-row"
                            :class="{ 'input-error' : form.errors['roles.0'] }"
                        >
                            <jet-label
                                class="form-label"
                                for="role"
                            >
                                Role
                            </jet-label>
                            <select
                                data-hj-suppress
                                id="role"
                                v-model="selectedRole"
                                class="form-input"
                            >
                                <option value="">
                                    Select role
                                </option>
                                <option
                                    v-for="role in roles.data"
                                    :key="role.id"
                                    :value="role.id"
                                >
                                    {{ role.name }}
                                </option>
                            </select>
                            <span
                                v-for="error in form.errors['roles.0']"
                                v-show="form.errors['roles.0']"
                                :key="error"
                                class="error-msg"
                            >{{ error }}</span>
                        </li>
                        <li class="form-row mt-4">
                            <primary-button
                                :disabled="isDisabled"
                                title="Save role"
                                @click.prevent="submitForm"
                            />
                        </li>
                    </ul>
                </form>
            </template>
        </jet-form-section>
</template>

<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import JetFormSection from "@/Jetstream/FormSection";
    import JetLabel from "@/Jetstream/InputLabel";
    import { useVuelidate } from "@vuelidate/core";
    import PrimaryButton from "@/components/form/PrimaryButton";

    import {
        required,
        helpers,
        integer,
        minLength
    } from "@vuelidate/validators";
    
    export default {
        components: {
            JetFormSection,
            JetLabel,
            PrimaryButton
        },
        props: {
            user: {
                type: Object,
                default: () => {},
            }
        },
        emits: ["UserUpdated"],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                roles: [],
                roleUpdated: false,
                selectedRole: "",
                form: {
                    errors: {},
                    processing: false
                }
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        watch: {
            user: function(user) {
                this.selectedRole = (user && user?.roles && user?.roles.length > 0 ) ? user.roles[0].id : "";
            }
        },
        validations: {
            selectedRole: {required},
        },
        mounted() {
            this.fetchRoles();
        },
        methods: {
            fetchRoles() {
                apiClient.get("/roles")
                    .then(response => {
                        this.roles = response.data;
                    });
            },
            async submitForm() {
                this.form.processing = true;
                this.form.errors = {};
                this.roleUpdated = false;
                let payload = {
                    roles: [this.selectedRole]
                };

                let url = "users/" + this.user.id;
                let method = "patch";

                await apiClient.get( ApiService.getApiUrl() + "/sanctum/csrf-cookie");
                apiClient({
                    method: method,
                    url: url,
                    data: payload,
                })
                    .then(response => {
                        this.$emit("UserUpdated");
                        this.roleUpdated = true;
                    }).catch(error => {
                        this.form.errors = error.response.data.errors;
                    }).finally(() => {
                        this.form.processing = false;
                });
            }
        }
    };
</script>