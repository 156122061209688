<template>
        <div>
            <form>
                <ul class="">
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.name}"
                    >
                        <div class="input-grp">
                            <jet-label
                                for="name"
                                class="form-label"
                                value="Name *"
                            />
                            <jet-input
                                id="name"
                                v-model="form.name"
                                type="text"
                                class="form-input"
                                data-hj-suppress
                            />
                            <span
                                v-show="form.errors.name"
                                class="error-msg"
                            >{{ form.errors.name }}</span>
                        </div>
                    </li>

                    <li 
                            class="form-row custom-file-upload mb-4" 
                            :class="{ 'input-error' : form.errors.file}"
                    >
                        <upload-field
                            :file="certification"
                            data-hj-suppress
                            @upload="(value) => form.file = value"
                        />
                        <span
                                v-for="error in form.errors.file"
                                :key="error"
                                class="error-msg"
                        >{{ error }}</span>
                    </li>

                    <li class="form-row">
                        <primary-button
                            :title="certification ? 'Save certification' : 'Add certification / reference'"
                            :disabled="isDisabled"
                            @click.prevent="submitForm"
                        />
                    </li>
                </ul>
            </form>
        </div>
</template>
<script>
    import JetInput from "@/Jetstream/InputField";
    import JetLabel from "@/Jetstream/InputLabel";
    import { useVuelidate } from "@vuelidate/core";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import UploadField from "@/components/form/UploadField";

    export default {
        components: {
            JetInput,
            PrimaryButton,
            UploadField,
            JetLabel
        },
        props: {
            certification: {
                type: Object,
                default: null,
            },
            user: {
                type: Object,
                default: null,
            }
        },
        emits: {"editedCertification": null},
        setup: () => ({ v$: useVuelidate({$scope: false}) }),
        data() {
            return {
                countries: {},
                form: {
                    errors: {},
                    processing: false,
                    name: (this.certification)?this.certification.name:"",
                    file: null,
                },
                filePreview: {},
            };
        },
        computed : {
            isDisabled() {
                return !this.form || !this.form.name || (!this.certification && !this.form.file) || this.form.processing;
            },
        },
        methods: {
            submitForm() {
                if (this.certification){
                    var url = "/files/"+this.certification.id;
                    var method = "put";
                } else {
                    var url = "/users/" + this.$store.getters.authUser.id + "/files";
                    var method = "post";
                }
                    const formData = new FormData();
                    formData.append("_method", method);
                    formData.append("name", this.form.name);
                    if (this.form.file) formData.append("file", this.form.file);
                

                apiClient({
                    method: "post",
                    url: url,
                    data: formData
                })
                .then(response => {
                    this.$emit("editedCertification");

                    // only clean form when adding a new record
                    if (!this.certification) {
                        this.form.name = null;
                        this.form.file = null;
                    }

                    this.form.errors = {};
                    this.clearFileInput();

                } ).catch((error) => {
                    if (error.response) {
                        this.form.errors = error.response.data.errors;
                    }
                });
            },

            updateFilePreview() {
                const [file] = this.$refs.file.files;

                this.filePreview = {
                    type: file.type,
                    name: file.name,
                    data: URL.createObjectURL(file),
                };
            },
            deleteFile() {
                let store = this.$store;
                this.form.file = null;
                apiClient.put("/users/" + this.$store.getters.authUser.id, this.form)
                    .then(response => {
                        store.dispatch("setAuthUser", { user: response.data.data });
                        this.clearFileInput();
                    })
                    .catch(error => {
                    });
            },

            clearFileInput() {
                this.filePreview = {};
                if (this.$refs.file?.value) {
                    this.$refs.file.value = null;
                }
            },
        }
    };
</script>
