<template>
        <div
            v-if="authUser"
            class="dashboard-header py-3"
        >
            <div class="mobile-logo">
                <Logo />
            </div>
					<div class="sidebar-header-part mt-3">
					<div class="main-logo">
						<Logo />
					</div>
					</div>
					<div class="header-right-part mx-5">
						<div
							v-can="'View progress bar'"
							class="profile-count"
						>
							<h4>Your profile is on</h4>
							<div :class="['progress','progress-'+(Math.round(authUser.profile_percentage / 10) * 10)]">
								<div class="progress-value">
									{{ authUser.profile_percentage }}%
								</div>
							</div>
						</div>

                <notification-component
                    :show-notifications="showNotifications"
                    @click="showNotifications=!showNotifications"
                />
                <div
                    v-if="authUser"
                    class="header-dropdown"
                >
                    <div
                        data-hj-suppress
                        class="dropdown-btn"
                        @click="showHeaderDropdown = !showHeaderDropdown"
                    >
                        <span class="avatar-part"><img
                            :src="authUser.profile_photo"
                            :alt="authUser.name"
                        ></span>
                    </div>
                    <ul
                        class="dropdown-menu"
                        :class="{'open':showHeaderDropdown}"
                    >
                        <li>
                            <router-link :to="{ name: 'profile' }">
                                <i><img
                                    src="@/images/icon-profile.svg"
                                    alt="icon"
                                ></i>
                                <span>My profile</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'account' }">
                                <i><img
                                    src="@/images/account-management.svg"
                                    alt="icon"
                                ></i>
                                <span>My account</span>
                            </router-link>
                        </li>
                        <li v-can="'Edit users'">
                            <router-link :to="{ name: 'users' }">
                                <i><img
                                    src="@/images/User_management.svg"
                                    alt="icon"
                                ></i>
                                <span>User management</span>
                            </router-link>
                        </li>
                        <li v-can="'Edit roles'">
                            <router-link :to="{ name: 'roles' }">
                                <i><img
                                    src="@/images/Roles.svg"
                                    alt="icon"
                                ></i>
                                <span>Role management</span>
                            </router-link>
                        </li>
                        <li v-can="'Add vacancies'">
                            <router-link :to="{ name: 'vacancies' }">
                                <i><img
                                    src="@/images/vacancies.webp"
                                    alt="icon"
                                ></i>
                                <span>Vacancies</span>
                            </router-link>
                        </li>
                        <li v-if="isAdmin">
                            <router-link :to="{ name: 'learning' }">
                                <i><img
                                    src="@/images/icon-cap.svg"
                                    alt="icon"
                                ></i>
                                <span>Learnings</span>
                            </router-link>
                        </li>
                        <li>
                            <form @submit.prevent="logout">
                                <button type="submit">
                                    <i><img
                                        src="@/images/icon-logout.svg"
                                        alt="icon"
                                    ></i>
                                    <span>Logout</span>
                                </button>
                            </form>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            v-else
            class="login-header"
        >
            <div class="right-col">
                <p
                    v-if="$route.name === 'login'"
                    class="flex whitespace-nowrap gap-4"
                >
                    Don’t have an account yet?  
                    <primary-button
                        title="Create account"
                        @click="$router.push({name: 'register'})"
                    />
                </p>
                <p
                    v-if="$route.name === 'register' || $route.name === 'verify-email'"
                    class="flex whitespace-nowrap gap-4"
                >
                    I already have an account. 
                    <primary-button
                        title="Login"
                        @click="$router.push({name: 'login'})"
                    />
                </p>
            </div>
            <div class="left-col">
                <p>
                    <a
                        v-if="$route.name === 'login' && theme === 'youngheroes'"
                        class="back-btn"
                        href="https://www.youngheroes.com"
                    >
                        <span class="icon" />
                        <span>Back to homepage</span>
                    </a>
                    <router-link
                        v-if="$route.name === 'forgot-password' || $route.name === 'register' || $route.name === 'verify-email'"
                        class="back-btn"
                        :to="{ name: 'login' }"
                    >
                        <span class="icon" />
                        <span>Back to login</span>
                    </router-link>
                </p>
            </div>
            <div class="header-logo">
                <Logo />
            </div>
        </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import Logo from "@/components/LogoComponent";
    import NotificationComponent from "@/components/NotificationComponent";
    import PrimaryButton from "@/components/form/PrimaryButton";
    
    export default {
        components: {
            Logo,
            NotificationComponent,
            PrimaryButton
        },
        data() {
            return {
                showHeaderDropdown: false,
                showNotifications: false,
            };
        },
        computed: {
            ...mapGetters(["authUser", "roleType", "theme", "isAdmin"]),
        },
        watch:{
            $route (to, from){
                this.showHeaderDropdown = false;
            }
        },
        methods: {
            logout() {
                this.$store.dispatch("logout");
            },
        },
    };
</script>