<template>
        <div
            v-if="vacancy"
            class="vacancy-detail"
        >
            <h1>{{ vacancy.title }}</h1>

            <section class="vacancy-header">
                <div class="info-top-part">
                    <div class="posted-on">
                        posted on {{ publishDate }}
                    </div>
                    <h2 class="bottom-shape">
                        <span
                            v-for="(work_type, index) in vacancy.work_types"
                            :key="index"
                        >
                            <span class="name">{{ work_type.name }}</span><span
                                v-if="index+1 < vacancy.work_types.length"
                                class="separator"
                            >/</span>
                        </span>
                    </h2>
                </div>

                <div class="vacancy-highlights">
                    <div class="block location">
                        <div class="title">
                            Location
                        </div>
                        <div><span class="image" />{{ vacancy.company_location.city }}</div>
                    </div>
                    <div class="block education-level"> 
                        <div class="title">
                            Education
                        </div>
                        <div><span class="image" />{{ vacancy.education_level.name }}</div>
                    </div>
                    <div class="block employment-type">
                        <div class="title">
                            Type of employment
                        </div>
                        <div><span class="image" />{{ vacancy.employment_type.name }}</div>
                    </div>
                </div>
            </section>

            <section class="vacancy-content images">
                <img
                    v-for="image in vacancy.images"
                    :key="image"
                    :src="image.link"
                >
            </section>

            <section class="vacancy-content">
                <h3>About the role</h3>
                <div v-html="vacancy.summary" />
            </section>

            <section class="vacancy-content">
                <h3>About the company</h3>
                <div v-html="vacancy.company_description" />
            </section>

            <section class="vacancy-content">
                <h3>What you'll do</h3>
                <div v-html="vacancy.job_description" />
            </section>

            <section class="vacancy-content">
                <h3>What you'll need</h3>
                <div v-html="vacancy.requirements" />
            </section>

            <section class="vacancy-content">
                <h3>Benefits & Conditions</h3>
                <div v-html="vacancy.benefits" />
            </section>
        
            <jet-form-section
                v-if="$can('Respond to vacancies')"
                class="vacancy-contact"
            >
                <template #right>
                    <div class="contact-info">
                        <div v-if="!interested">
                            <form
                                class="emailform"
                                @submit.prevent="submitForm"
                            >
                                <button
                                    class="form-button"
                                    type="submit"
                                    :class="{ 'opacity-25': isDisabled }"
                                    :disabled="isDisabled"
                                >
                                    Let us know
                                </button>
                            </form>
                        </div>
                        <div v-else>
                            <strong>Thank you, we will be in touch the next business day.</strong>
                        </div>
                        <div class="email">
                            <span class="image" /><a href="mailto:sarah@youngheroes.com">sarah@youngheroes.com</a>
                        </div>
                        <div class="phone">
                            <span class="image" /><a href="tel:+31616635358">+31 6 16 63 53 58</a>
                        </div>
                    </div>
                </template>
            
                <template #title>
                    Are you interested in this challenge?
                </template>
                <template #button />
                <template #form>
                    <div class="coach-image">
                        <span><img
                            src="../images/people/sarah-transparent.png"
                            alt="image"
                        ></span>
                    </div>
                    <div class="statement">
                        “Even if you think you might not meet 100% of the qualifications, let us know that you are interested in this position. Growth and learning will always be an essential part of your career going forth. So, why not start this learning journey on your new job? We are here to support you.”
                    </div>
                    <div class="recruiter-name">
                        Sarah H&ouml;ckner
                    </div>
                </template>
            </jet-form-section>
        
            <router-link
                v-can="'Add vacancies'"
                class="back-btn"
                :to="{ name: 'vacancies' }"
            >
                <span class="icon" />Back to all jobs
            </router-link>
            <a
                v-cannot="'Add vacancies'"
                class="back-btn"
                href="https://youngheroes.com/vacancies/"
            ><span class="icon" />Back to all jobs</a>
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import moment from "moment";
    import { required } from "@vuelidate/validators";
    import JetFormSection from "@/Jetstream/FormSection";
    
    export default {
        components: {
            JetFormSection,
            
        },
        data() {
            return {
                interested: false,
                form: {
                    processing: false,
                    errors: {},
                    question: "Vacancy",
                    answer: []
                },
                vacancy: null
            };
        },
        validations: {
            form: {
                question: {required},
                answer: {required}
            }
        },
        computed: {
            isDisabled() {
                return this.form.processing;
            },
            publishDate() {
                return moment(this.vacancy.date_published).format("Do MMMM YYYY");
            },
        },
        created() {
            this.fetchVacancy(true);
        },
        methods: {
            async submitForm() {
                this.form.processing = true;
                this.form.errors = {};
                await apiClient.get(ApiService.getApiUrl() + "/sanctum/csrf-cookie");
                apiClient({
                    method: "POST",
                    url: "/form/answer",
                    data: {
                        question: this.form.question,
                        answer: this.form.answer,
                    }
                })
                    .then(response => {
                        this.form.processing = false;
                        this.interested = true;
                    })
                    .catch(error => {
                        this.form.processing = false;
                        this.form.errors = (error.response.data.errors) ? error.response.data.errors : {};
                    });
            },
            fetchVacancy(initial=false) {
                return apiClient.get("vacancies/" + this.$route.params.vacancy)
                    .then(response => {
                        this.vacancy = response.data.data;
                        this.form.answer = [this.vacancy.title];
                        if (initial === true) {
                            document.title = this.vacancy.title + " - " + this.$defaultTitle;
                            this.$gtm.trackView(this.vacancy.title, "/vacancies/"+this.vacancy.id);
                        }
                    });
            }
        }
    };
</script>