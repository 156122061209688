<template>
        <span>
            <span @click="startConfirmingPassword">
                <slot />
            </span>

            <jet-dialog-modal
                :show="confirmingPassword"
                @close="closeModal"
            >
                <template #title>
                    {{ title }}
                </template>

                <template #content>
                    {{ content }}

                    <div class="mt-4">
                        <jet-input
                            ref="password"
                            v-model="form.password"
                            type="password"
                            class="form-input float-none"
                            placeholder="Password"
                            @keyup.enter="confirmPassword"
                        />

                        <jet-input-error
                            :message="form.error"
                            class="mt-2"
                        />
                    </div>
                </template>

                <template #footer>
                    <div class="flex justify-between w-full">

                        <secondary-button
                            title="Cancel"
                            :disabled="form.processing"
                            @click.prevent="closeModal"
                        />

                        <div class="w-40 inline-block">
                            <primary-button
                                :title="button"
                                :disabled="form.processing"
                                @click.prevent="confirmPassword"
                            />
                        </div>
                    </div>
                </template>
            </jet-dialog-modal>
        </span>
</template>

<script>
    import PrimaryButton from "@/components/form/PrimaryButton";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import JetDialogModal from "./DialogModal";
    import JetInput from "./InputField";
    import JetInputError from "./InputError";
    import JetSecondaryButton from "./SecondaryButton";
    import AuthService from "@/services/AuthService";

    export default {

        components: {
            PrimaryButton,
            SecondaryButton,
            JetDialogModal,
            JetInput,
            JetInputError,
            JetSecondaryButton,
        },

        props: {
            title: {
                type: String,
                default: "Confirm Password",
            },
            content: {
                type: String,
                default: "For your security, please confirm your password to continue.",
            },
            button: {
                type: String,
                default: "Confirm",
            }
        },
        emits: ["confirmed"],

        data() {
            return {
                confirmingPassword: false,
                form: {
                    password: "",
                    error: "",
                },
            };
        },

        methods: {
            startConfirmingPassword() {
                AuthService.getPasswordConfirmationStatus()
                    .then((response) => {
                        if (response.data.confirmed) {
                            this.$emit("confirmed");
                        } else {
                            this.confirmingPassword = true;

                            setTimeout(() => this.$refs.password.focus(), 250);
                        }
                    }).catch(error => {
                        
                    });
            },

            confirmPassword() {
                this.form.processing = true;

                AuthService.confirmPassword({
                    password: this.form.password,
                }).then(() => {
                    this.form.processing = false;
                    this.closeModal();
                    this.$nextTick(() => this.$emit("confirmed"));
                }).catch(error => {
                    this.form.processing = false;
                    this.form.error = error.response.data.errors.password[0];
                    this.$refs.password.focus();
                });
            },

            closeModal() {
                this.confirmingPassword = false;
                this.form.password = "";
                this.form.error = "";
            },
        }
    };
</script>
