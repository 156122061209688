<template>
        <div>
            <div class="title-part">
                <h1 v-if="theme === 'youngheroes' && this.user.id">
                    <span v-if="isOwnProfile">{{ userIsCoach ? "My coach" : "My Hero" }}</span>
                    <span v-else data-hj-suppress>{{ this.user.name }}</span>
                    profile
                </h1>
                <h1 v-if="theme === 'yfk' && isOwnProfile">
                    My YFK profile
                </h1>
            </div>

            <jet-form-section>
                <template #image>
                    <img
                        src="./../../images/icon-profile-large.svg"
                        alt="icon"
                    >
                </template>
                <template #title>
                    Personal information
                </template>
                <template
                    v-if="isOwnProfile"
                    #button
                >
                    <secondary-button
                        icon="pencil"
                        title="Edit"
                        @click="showEditPersonalInformation = !showEditPersonalInformation"
                    />
                </template>

                <template #form>
                    <div class="col-span-12 sm:col-span-12">
                        <div>
                            <!-- content -->
                            <div v-if="showEditPersonalInformation">
                                <edit-personal-information-form
                                    :user="authUser"
                                    @user-edited="showEditPersonalInformation=!showEditPersonalInformation; fetchUser();"
                                />
                            </div>
                            <div
                                v-else
                                class="personal-info-part"
                            >
                                <div
                                    v-if="user.profile_photo"
                                    class="image-add-part"
                                    data-hj-suppress
                                >
                                    <div class="profile-area">
                                        <img

                                            class="rounded-full"
                                            :src="user.profile_photo"
                                            :alt="user.name"
                                        >
                                    </div>
                                    <p>
                                        Profile picture <span v-if="user.profile_photo.includes('ui-avatars')">
                                            <span
                                                class="cursor-pointer"
                                                @click="showEditPersonalInformation = !showEditPersonalInformation"
                                            >Not yet added</span>
                                        
                                        </span>
                                    </p>
                                </div>
                                <div class="personal-info-data">
                                    <div class="pl-info-row">
                                        <label>First name</label>
                                        <span data-hj-suppress>{{ user.first_name }}</span>
                                    </div>
                                    <div class="pl-info-row">
                                        <label>Last name</label>
                                        <span data-hj-suppress>{{ user.last_name }}</span>
                                    </div>
                                    <div class="pl-info-row">
                                        <label>City of residence</label>
                                        <span data-hj-suppress>{{ user.city }}</span>
                                    </div>
                                    <div class="pl-info-row">
                                        <label>Province</label>
                                        <span data-hj-suppress>{{ user.province }}</span>
                                    </div>
                                    <div class="pl-info-row">
                                        <label>Country</label>
                                        <span data-hj-suppress>{{ user.country?.name }}</span>
                                    </div>
                                    <div
                                        v-if="userIsCoach && !isProfessional && user.approval_status === 3 && user.profile_percentage >= 70"
                                        class="pl-info-row"
                                    >
                                        <label>Date of birth</label>
                                        <span data-hj-suppress>{{ dateOfBirth }}</span>
                                    </div>
                                    <div
                                        v-if="userIsCoach && !isProfessional && user.approval_status === 3 && user.profile_percentage >= 70"
                                        class="pl-info-row"
                                    >
                                        <label>Address</label>
                                        <span data-hj-suppress>{{ `${user.street ? user.street : ""} ${user.house_number ? user.house_number :
                                            ""} ${user.house_number_addition ? user.house_number_addition : "" }` }}</span>
                                    </div>
                                    <div
                                        v-if="userIsCoach && !isProfessional && user.approval_status === 3 && user.profile_percentage >= 70"
                                        class="pl-info-row"
                                    >
                                        <label>Postal code</label>
                                        <span data-hj-suppress>{{ user.postal_code }}</span>
                                    </div>
                                    <div class="pl-info-row">
                                        <label>About me</label>
                                    </div>
                                    <div class="pl-info-row" data-hj-suppress>
                                        <div v-html="user.biography" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </jet-form-section>
            <jet-form-section v-if="userIsCoach && !isProfessional">
                <template #image>
                    <img
                        src="./../../images/icon-coach.svg"
                        alt="icon"
                    >
                </template>
                <template #title>
                    Coaching
                </template>
                <template #form>
                    <div class="col-span-12 sm:col-span-12">
                        <div v-if="showEditCoaching">
                            <edit-coaching-form
                                :user="authUser"
                                @user-edited="showEditCoaching=!showEditCoaching; fetchUser();"
                            />
                        </div>
                        <div
                            v-else
                            class="personal-info-part"
                        >
                            <div
                                v-if="user.years_experience"
                                class="pl-info-row relative group"
                                data-hj-suppress
                            >
                                <label class="w-full" >
                                    I have 
                                    {{ user.years_experience }}
                                    years of experience
                                </label>
                                <float-right-component v-if="!$route.params.id">
                                    <action-button
                                        color="secondary"
                                        icon="pencil"
                                        @click="showEditCoaching = !showEditCoaching"
                                    />
                                </float-right-component>
                            </div>
                            <div class="pl-info-row relative group">
                                <label class="w-full">My motivation to coach?</label>
                                <float-right-component v-if="!$route.params.id">
                                    <action-button
                                        color="secondary"
                                        icon="pencil"
                                        @click="showEditCoaching = !showEditCoaching"
                                    />
                                </float-right-component>
                            </div>
                            <div class="pl-info-row" data-hj-suppress>
                                <div v-html="user.motivation" />
                            </div>
                        </div>
                    </div>
                </template>
            </jet-form-section>

            <jet-form-section v-if="userIsProfessional">
                <template #image>
                    <img
                        src="@/images/icon-education.svg"
                        alt="icon"
                    >
                </template>
                <template #title>
                    Education
                </template>
                <template #form>
                    <div class="col-span-12 sm:col-span-12">
                        <education-list :can-add-education="isOwnProfile" />
                    </div>
                </template>
            </jet-form-section>

            <jet-form-section>
                <template #image>
                    <img
                        src="@/images/icon-lang.svg"
                        alt="icon"
                    >
                </template>
                <template #title>
                    Languages
                </template>
                <template #form>
                    <div
                        v-if="runQueries || !$route.params.id"
                        class="col-span-12 sm:col-span-12"
                    >
                        <language-list :can-add-language="isOwnProfile" />
                    </div>
                </template>
            </jet-form-section>

            <jet-form-section>
                <template #image>
                    <img
                        src="@/images/icon-work.svg"
                        alt="icon"
                    >
                </template>
                <template #title>
                    Work experience
                </template>
                <template #form>
                    <div
                        v-if="runQueries || !$route.params.id"
                        class="col-span-12 sm:col-span-12"
                    >
                        <experience-list :can-add-experience="isOwnProfile" />
                    </div>
                </template>
            </jet-form-section>

            <jet-form-section v-if="userIsProfessional || userIsCoach">
                <template #image>
                    <img
                        src="./../../images/icon-skill.svg"
                        alt="icon"
                    >
                </template>
                <template #title>
                    {{ (userIsCoach || (isCoach && isOwnProfile)) ? "Coaching topics" : "Skills" }}
                </template>

                <template #form>
                    <div
                        v-if="runQueries || !$route.params.id"
                        class="col-span-12 sm:col-span-12"
                    >
                        <skill-list :can-add-skill="isOwnProfile" />
                    </div>
                </template>
            </jet-form-section>

            <jet-form-section v-if="userIsProfessional">
                <template #image>
                    <img
                        src="./../../images/icon-heart.svg"
                        alt="icon"
                    >
                </template>
                <template #title>
                    Interests
                </template>
                <template #form>
                    <div class="col-span-12 sm:col-span-12">
                        <div v-if="showEditInterests">
                            <edit-interests-form
                                :user="user"
                                @interests-edited="showEditInterests=!showEditInterests;fetchUser()"
                            />
                        </div>
                        <div v-if="user.interests_csv && !showEditInterests" data-hj-suppress>
                            <span
                                v-for="interest in user.interests_csv.split(',')"
                                :key="interest"
                                class="interests-row relative group"
                            >
                                <span class="icon icon-interests-row" /> <span class="uppercase-first">{{ interest }}</span><br>
                                <float-right-component v-if="!$route.params.id">
                                    <action-button
                                        color="secondary"
                                        icon="pencil"
                                        @click="showEditInterests = !showEditInterests"
                                    />
                                </float-right-component>
                            </span>
                        </div>
                        <span v-if="!user.interests_csv && !showEditInterests">No interests given yet.</span>
                        <div
                            v-if="!showEditInterests"
                            class="add-skill common-add-btn extend-btn-info"
                            @click="showEditInterests=!showEditInterests"
                        >
                            <h4>Add interest</h4>
                        </div>
                    </div>
                </template>
            </jet-form-section>

            <jet-form-section
                v-show="userIsCoach && !isProfessional"
                class="relative"
            >
                <template #image>
                    <img
                        src="@/images/icon-docs.svg"
                        alt="icon"
                    >
                </template>
                <template #title>
                    Certification / references
                </template>
                <template #form>
                    <div class="col-span-12 sm:col-span-12">
                        <certification-list
                            v-if="user.id"
                            :user="user"
                        />
                    </div>
                </template>
            </jet-form-section>
        </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import moment from "moment";
    import router from "../../router";
    import JetFormSection from "@/Jetstream/FormSection";
    import EducationList from "@/Pages/Profile/EducationList";
    import CertificationList from "@/Pages/Profile/CertificationList";
    import LanguageList from "@/Pages/Profile/LanguageList";
    import ExperienceList from "@/Pages/Profile/ExperienceList";
    import SkillList from "@/Pages/Profile/SkillList";
    import EditPersonalInformationForm from "@/Pages/Profile/EditPersonalInformationForm";
    import EditCoachingForm from "@/Pages/Profile/EditCoachingForm";
    import EditInterestsForm from "@/Pages/Profile/EditInterestsForm";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import ActionButton from "@/components/form/button/ActionButton";
    import FloatRightComponent from "@/components/FloatRightComponent";
    
    export default {
        components: {
            JetFormSection,
            EducationList,
            CertificationList,
            LanguageList,
            ExperienceList,
            SkillList,
            EditPersonalInformationForm,
            EditCoachingForm,
            SecondaryButton,
            EditInterestsForm,
            ActionButton,
            FloatRightComponent
        },
        data() {
            return {
                showEditPersonalInformation: false,
                showEditInterests: false,
                showEditCoaching: false,
                user: {},
                runQueries: false,
                isOwnProfile: this.$route.params.id ? false: true,
            };
        },
        computed: {
            ...mapGetters(["authUser", "isAdmin", "theme", "isCoach", "isProfessional"]),
            dateOfBirth() {
                return (this.authUser.date_of_birth) ? moment(new Date(this.authUser.date_of_birth)).format("DD-MM-YYYY") : null;
            },
            userIsCoach() {
                let isCoach = false;
                this.user.roles?.forEach(function(role) {
                    if (role.type.name === "Coach") isCoach = true;
                });
                return isCoach;
            },
            userIsProfessional() {
                let isProfessional = false;
                 this.user.roles?.forEach(function(role) {
                    if (role.type.name === "Professional") isProfessional = true;
                });
                return isProfessional;
            }
        },
        watch: {
            "$route.params.id": function(id){
                this.fetchUser(+id);
            },
        },
        created() {
            this.fetchUser();
        },
        methods: {
            fetchUserProfilePercentage() {
                this.$store.dispatch("getAuthUser");
            },
            fetchUser(id) {
                if (this.$route.params.id) {
                    id = this.$route.params.id;
                }

                if (!id) {
                    this.user = this.authUser;
                    this.isOwnProfile = true;
                    return this.user;
                } else {
                    this.isOwnProfile = false;
                    return apiClient.get("/users/"+ id)
                    .then(response => {
                        this.user = response.data?.data;
                        this.runQueries = true;
                    }).catch((e) => {
                        // eslint-disable-next-line no-console
                        console.log(e);
                            console.log("error get user", e);
                        router.push({ path: "/403"});
                        });
                }
            },
        },
    };
</script>
