<template>
        <div class="info-blocks">
            <jet-section-title>
                <template #title>
                    <slot name="title" />
                </template>
                <template #description>
                    <slot name="description" />
                </template>
            </jet-section-title>

            <div>
                <slot name="content" />
            </div>
        </div>
</template>

<script>
    import JetSectionTitle from "./SectionTitle";

    export default {
        components: {
            JetSectionTitle,
        }
    };
</script>
