<template>
        <div class="relative">
            <button class="notification-button-collapsed">
                <div class="w-5">
                    <img
                        v-if="calculateNotificationAmount() > 0"
                        src="../images/icon-notification-unread.svg"
                        alt="unread-notification"
                    >
                    <img
                        v-else
                        src="../images/icon-notification-read.svg"
                        alt="read-notification"
                    >
                </div>

            </button>
            <div class="notifications" v-if="showNotifications">
							<h4
								v-if="calculateNotificationAmount() === 1"
							>
								1 new notification
							</h4>
							<h4
								v-if="calculateNotificationAmount() > 1"
							>
								{{ calculateNotificationAmount() }} new notifications
							</h4>
							<h4
								v-if="calculateNotificationAmount() === 0"
							>
								No unread notifications
							</h4>
                <notification-list
                    :notifications="notifications"
                    :notification-amount="calculateNotificationAmount()"
                    :show-notifications="showNotifications"
                />
            </div>
        </div>
</template>
<script>
import NotificationList from "@/components/NotificationList";
    export default {
        components: {
            NotificationList,
        },
        props: {
            showNotifications: {
                type: Boolean,
            },
        },
        data() {
            return {
                notifications: this.$store.state.notifications
            };
        },
        created() {
            this.getNotificationsFromSession();
        },
        methods: {
            getNotificationsFromSession() {
               this.notifications = this.$store.state.notifications;
               let storedNotifications = JSON.parse(sessionStorage.getItem('youngheroes_notifications')) || [];
                for (let i = 0, len = storedNotifications.length; i < len; i++) {

                    // only add unread messages after refresh if not exists (after page reload)
                    if (storedNotifications[i].read === 0 &&
                        typeof this.notifications.find(n => n.id === storedNotifications[i].id) === "undefined"
                    ) {
                        this.notifications.push(storedNotifications[i]);
                    }
                }
            },
            calculateNotificationAmount() {
                return this.notifications.filter((notification) => notification.read === 0).length;
            }
        }
    };
</script>