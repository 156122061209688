<template>
        <div>
            <form>
                <ul>
                    <li
                        class="my-4"
                    >
                        <div class="">
                            <jet-label
                                for="name"
                                class="form-label"
                                value="Name *"
                            />
                            <jet-input
                                data-hj-suppress
                                id="name"
                                v-model="form.name"
                                type="text"
                                class="form-input"
                            />
                            <span
                                v-show="form.errors.name"
                                class="error-msg"
                            >{{ form.errors.name }}</span>
                        </div>
                    </li>

                    <li
                            class="w-full custom-file-upload mb-4"
                            :class="{ 'input-error' : form.errors.file}"
                    >
                        <upload-field
                            data-hj-suppress
                            :file="form.file"
                            @upload="(value) => form.file = value"
                        />
                        <span
                                v-for="error in form.errors.file"
                                :key="error"
                                class="error-msg"
                        >{{ error }}</span>
                    </li>

                    <li class="w-full">
                        <primary-button
                            :title="file ? 'Save document' : 'Save and add document'"
                            :disabled="isDisabled"
                            @click.prevent="submitForm"
                        />
                    </li>
                </ul>
            </form>
        </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import JetInput from "@/Jetstream/InputField";
    import JetLabel from "@/Jetstream/InputLabel";
    import { useVuelidate } from "@vuelidate/core";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import { required } from "@vuelidate/validators";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import UploadField from "@/components/form/UploadField";

    export default {
        components: {
            JetInput,
            JetLabel,
            PrimaryButton,
            UploadField,
        },
        props: {
            file: {
                type: Object,
                default: null,
            },
        },
        emits: {"editedDocument": null},
        validations: {
            form: {
                name: {required},
            }
        },
        setup: () => ({ v$: useVuelidate({$scope: false}) }),
        data() {
            return {
                countries: {},
                form: {
                    errors: {},
                    processing: false,
                    name: (this.file)?this.file.name:"",
                    file: this.file ? this.file : null,
                },
                filePreview: {},
            };
        },
        computed: {
            ...mapGetters(["authUser", "isProfessional"]),
            isDisabled() {
                return !this.form || !this.form.name || (!this.file && !this.form.file) || this.form.processing;
            },
        },
        methods: {
            submitForm() {
                if (this.file){
                    var url = "/files/" + this.file.id;
                    var method = "put";
                } else {
                    var url = "/trajectories/" + (this.isProfessional ? this.authUser.activeTrajectory?.id : this.$route.params.id) + "/files";
                    var method = "post";
                }

                const formData = new FormData();
                formData.append("_method", method);
                formData.append("name", this.form.name);

                if (this.form.file instanceof File) formData.append("file", this.form.file);

                apiClient({
                    method: "post",
                    url: url,
                    data: formData
                })
                .then(response => {
                    this.$emit("editedDocument");

                    this.form.name = null;
                    this.form.file = null;

                    this.form.errors = {};

                } ).catch((error) => {
                    if (error.response) {
                        this.form.errors = error.response.data.errors;
                    }
                });
            },
            deleteFile() {
                let store = this.$store;
                this.form.file = null;
                apiClient.put("/users/" + this.$store.getters.authUser.id, this.form)
                    .then(response => {
                        store.dispatch("setAuthUser", { user: response.data.data });
                        this.clearFileInput();
                    })
                    .catch(error => {
                    });
            },
        }
    };
</script>