<template>
        <div>
            <div class="title-bar">
                <h2 class="icon-title">
                    <i><img
                        src="./../images/icon-bar.svg"
                        alt="icon"
                    ></i>Your opinion please
                </h2>
            </div>
            <div class="opinion-card common-card">
                <h3 v-if="theme === 'youngheroes'">
                    What do you want to see next on My Young Heroes?
                </h3>
                <h3 v-if="theme === 'yfk'">
                    What do you want to see next on My YFK?
                </h3>
                <form
                    v-if="!voted"
                >
                    <ul class="form-wrapper">
                        <li
                            class="form-row"
                            :class="{ 'input-error' : (form.errors.answer || form.errors.invalid)}"
                        >
                            <div class="radio-grp">
                                <div class="radio-row">
                                    <input
                                        id="opinion1"
                                        v-model="form.answer"
                                        type="radio"
                                        value="Trainings"
                                    >
                                    <label
                                        class="radio-label"
                                        for="opinion1"
                                    >Trainings</label>
                                </div>
                                <div class="radio-row">
                                    <input
                                        id="opinion3"
                                        v-model="form.answer"
                                        type="radio"
                                        value="More coaches"
                                    >
                                    <label
                                        class="radio-label"
                                        for="opinion3"
                                    >More coaches</label>
                                </div>
                            </div>
                            <span
                                v-for="msg in form.errors.answer"
                                :key="msg"
                                class="error-msg mb-3"
                            >{{ msg }}</span>
                        </li>
                    </ul>
                    <secondary-button
                        :disabled="form.processing"
                        title="Vote now"
                        @click.prevent="submitForm"
                    />
                </form>
                <div
                    v-if="voted"
                    class=""
                >
                    <strong>Thank you for voting! Your vote has been registered.</strong>
                </div>
            </div>
        </div>
</template>

<script>
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import { mapGetters } from "vuex";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import {default as ApiService, apiClient } from "@/services/ApiService";

    export default {
        components: {
            SecondaryButton
        },
        data() {
            return {
                voted: false,
                form: {
                    processing: false,
                    errors: {},
                    question: "Dashboard poll",
                    answer: []
                },
            };
        },
        computed: {
            ...mapGetters(["theme"]),
        },
        validations: {
            form: {
                question: {required},
                answer: {required}
            }
        },
        methods: {
            async submitForm() {
                this.form.processing = true;
                this.form.errors = {};

                await apiClient.get( ApiService.getApiUrl() + "/sanctum/csrf-cookie");
                apiClient({
                    method: "POST",
                    url: "/form/answer",
                    data: {
                        question: this.form.question,
                        answer: this.form.answer,
                    }
                })
                .then(response => {
                    this.form.processing = false;
                    this.voted = true;
                })
                .catch(error => {
                    this.form.processing = false;
                    this.form.errors = error.response.data.errors;
                });
            }
        }
    };
</script>
