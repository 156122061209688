<template>
        <router-link :to="{ name: 'home' }">
            <img
                v-if="theme === 'yfk'"
                src="./../images/yfk-logo.png"
                width="150"
                alt="YFK"
            >
            <img
                v-else
                src="./../images/logo_youngheroes.png"
                width="47"
                alt="Young Heroes"
            >
        </router-link>
</template>
<script>
    import { mapGetters } from "vuex";
    export default {
        computed: {
            ...mapGetters(["theme"]),
        },
    };
</script>