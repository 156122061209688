<template>
        <section class="login-main-part">
            <div class="sign-up-part login-wrapper">
                <div class="login-area">
                    <div class="login-box">
                        <div class="confirm-msg">
                            <i><img
                                src="./../../images/icon-correct.svg"
                                alt="Correct"
                            ></i>Your email address is verified!
                        </div>
                        <div class="login-logo">
                            <Logo />
                        </div>
                        <div class="login-form-wrapper">
                            <form class="main-form">
                                <ul class="form-wrapper">
                                    <li class="form-row html-row text-center">
                                        <h2>Become part of something big!</h2>
                                        <p class="bottom-shape shape-center">
                                            Sign up with e-mail
                                        </p>
                                    </li>
                                    <li class="form-row two-input">
                                        <div
                                            class="input-grp"
                                            :class="{ 'input-error' : form.errors.first_name, 'input-filled' : !v$.form.first_name.$invalid }"
                                        >
                                            <label class="form-label">First name *</label>
                                            <input
                                                data-hj-suppress
                                                id="first_name"
                                                v-model="form.first_name"
                                                type="text"
                                                class="form-input"
                                                autofocus
                                            >
                                            <span
                                                v-for="error in form.errors.first_name"
                                                :key="error"
                                                class="error-msg"
                                            >{{ error }}</span>
                                        </div>
                                        <div
                                            class="input-grp"
                                            :class="{ 'input-error' : form.errors.last_name, 'input-filled' : !v$.form.last_name.$invalid }"
                                        >
                                            <label class="form-label">Last name *</label>
                                            <input
                                                data-hj-suppress
                                                id="last_name"
                                                v-model="form.last_name"
                                                type="text"
                                                class="form-input"
                                            >
                                            <span
                                                v-for="error in form.errors.last_name"
                                                :key="error"
                                                class="error-msg"
                                            >{{ error }}</span>
                                        </div>
                                    </li>
                                    <li class="form-row">
                                        <div
                                            class="input-grp"
                                            :class="{ 'input-error' : form.errors.email, 'input-filled' : !v$.form.email.$invalid }"
                                        >
                                            <label class="form-label">Email *</label>
                                            <input
                                                data-hj-suppress
                                                id="email"
                                                v-model="form.email"
                                                type="email"
                                                class="form-input"
                                            >
                                            <span
                                                v-for="error in form.errors.email"
                                                :key="error"
                                                class="error-msg"
                                            >{{ error }}</span>
                                        </div>
                                    </li>
                                    <li class="form-row tooltip-show">
                                        <div
                                            class="input-grp"
                                            :class="{ 'input-error' : form.errors.password, 'input-filled' : !v$.form.password.$invalid }"
                                        >
                                            <label class="form-label">Password *</label>
                                            <input
                                                data-hj-suppress
                                                id="password"
                                                v-model="form.password"
                                                type="password"
                                                class="form-input"
                                                @focus="showPasswordTooltip = true"
                                                @blur="showPasswordTooltip = false"
                                            >
                                            <span
                                                v-for="error in form.errors.password"
                                                :key="error"
                                                class="error-msg"
                                            >{{ error }}</span>
                                            <div
                                                v-show="showPasswordTooltip"
                                                class="info-tooltip"
                                            >
                                                <ul class="password-hint">
                                                    <li :class="{ 'checked': (!v$.form.password.minLength.$invalid && !v$.form.password.required.$invalid) }">
                                                        min. 8 characters
                                                    </li>
                                                    <li :class="{ 'checked': (!v$.form.password.containsNumber.$invalid) }">
                                                        min. 1  number
                                                    </li>
                                                    <li :class="{ 'checked': (!v$.form.password.containsUppercase.$invalid) }">
                                                        min. 1 uppercase character
                                                    </li>
                                                    <li :class="{ 'checked': (!v$.form.password.containsLowercase.$invalid) }">
                                                        min. 1 lowercase character
                                                    </li>
                                                    <li :class="{ 'checked': (!v$.form.password.containsSpecial.$invalid) }">
                                                        min. 1 special character
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li
                                        class="form-row"
                                        :class="{ 'input-error' : form.errors.password_confirmation, 'input-filled' : !v$.form.password_confirmation.$invalid }"
                                    >
                                        <div class="input-grp">
                                            <label class="form-label">Confirm password *</label>
                                            <input
                                                data-hj-suppress
                                                id="password_confirmation"
                                                v-model="form.password_confirmation"
                                                type="password"
                                                class="form-input"
                                            >
                                        </div>
                                    </li>
                                    <li class="form-row html-row">
                                        <p>
                                            We care about you. And your privacy.<br>
                                            Please read our <router-link
                                                :to="{ name: 'privacy-policy' }"
                                                target="_blank"
                                            >
                                                privacy policy
                                            </router-link> and the <router-link
                                                :to="{ name: 'terms-of-service' }"
                                                target="_blank"
                                            >
                                                terms of service
                                            </router-link>.
                                        </p>
                                    </li>

                                    <li
                                        class="form-row"
                                        :class="{ 'input-error' : form.errors.terms }"
                                    >
                                        <div class="checkbox-grp">
                                            <div class="checkbox-row">
                                                <jet-checkbox
                                                    id="terms"
                                                    v-model="form.terms"
                                                    name="terms"
                                                    :checked="form.terms"
                                                />
                                                <jet-label
                                                    class="checkbox-label"
                                                    for="terms"
                                                >
                                                    I agree with the privacy policy and terms of service *
                                                </jet-label>
                                            </div>
                                            <span
                                                v-for="error in form.errors.terms"
                                                :key="error"
                                                class="error-msg"
                                            >{{ error }}</span>
                                        </div>
                                    </li>
                                    <li class="form-row">
                                        <primary-button
                                            :disabled="isDisabled"
                                            title="Sign up"
                                            @click.prevent="submit"
                                        />
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
    import AuthService from "@/services/AuthService";
    import Logo from "@/components/LogoComponent";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import JetCheckbox from "@/Jetstream/JetCheckbox";
    import JetLabel from "@/Jetstream/InputLabel";
    import { useVuelidate } from "@vuelidate/core";
    import {
        required,
        minLength,
        email,
    } from "@vuelidate/validators";

    export default {
        components: {
            JetCheckbox,
            JetLabel,
            Logo,
            PrimaryButton
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                showPasswordTooltip: false,
                form: {
                    processing: false,
                    errors: {},
                    first_name: null,
                    last_name: null,
                    email: null,
                    password: null,
                    password_confirmation: null,
                    terms: false,
                }
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        validations: {
            form: {
                first_name: { required },
                last_name: { required },
                email: { required, email },
                password: { required,
                    valid: function(value) {
                        const containsUppercase = /[A-Z]/.test(value);
                        const containsLowercase = /[a-z]/.test(value);
                        const containsNumber = /[0-9]/.test(value);
                        const containsSpecial = /[#?!@$%^&*-]/.test(value);
                        return containsUppercase && containsLowercase && containsNumber && containsSpecial;
                    },
                    minLength: minLength(8),
                    containsUppercase: function(value) {
                        return /[A-Z]/.test(value);
                    },
                    containsLowercase: function(value) {
                        return /[a-z]/.test(value);
                    },
                    containsNumber: function(value) {
                        return /[0-9]/.test(value);
                    },
                    containsSpecial: function(value) {
                        return /[#?!@$%^&*-]/.test(value);
                    }
                },
                password_confirmation: { required,
                    sameAsPassword: function(value){
                        return this.form.password_confirmation === this.form.password;
                    }
                },
                terms: {
                    checked: value => value === true
                }
            },
        },
        methods: {
            submit() {
                const payload = {
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    email: this.form.email,
                    password: this.form.password,
                    password_confirmation: this.form.password_confirmation,
                    terms: this.form.terms
                };
                AuthService.registerUser(payload)
                    .then(() => {
                        this.$router.push({name: "verify-email"});
                    })
                    .catch((error) => {
                        this.form.errors = error.response.data.errors;
                    }).finally(() => {
                        this.form.password = "";
                        this.form.password_confirmation = "";
                });
            }
        }
    };
</script>
