<template>
        <div class="confirm-msg show">
            <i><img
                src="./../images/icon-correct.svg"
                alt="Correct"
            ></i>Your email address is verified!
        </div>
</template>
<script>
    export default {
        
    };
</script>