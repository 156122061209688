<template>
        <div class="learnings-catalogue">
            <div class="title-part">
                <img
                    src="../../images/icon-cap.svg"
                    alt="icon"
                    class="mr-3"
                >
                <h1>
                    {{ isAdmin || isCoach ? "Learnings catalogue" : "My learnings" }}
                </h1>
                <secondary-button
                    v-if="isAdmin"
                    title="Add new learning"
                    class="ml-auto"
                    @click="addNewLearning"
                />
            </div>
            <learnings-filter
                :categories="categories"
                :types="types"
                @select-category="(id) => category = id"
                @select-type="(id) => type = id"
            />
            <learning-tiles 
                :type="type"
                :category="category"
            />

        </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import LearningsFilter from "./LearningsFilter.vue";
    import LearningTiles from "./LearningTiles.vue";
    import SecondaryButton from "@/components/form/SecondaryButton";

    export default {
        components: {
            LearningsFilter,
            LearningTiles,
            SecondaryButton,
        },
        data() {
            return {
                categories: [],
                category: null,
                types: [],
                type: null,
            };
        },
        validations: {
        },
        computed: {
            ...mapGetters(["authUser", "isAdmin", "isCoach"]),
        },
        created() {
            this.fetchCategories();
            this.fetchTypes();
        },
        methods: {
            addNewLearning() {
                this.$router.push({
                    path: "addlearning"
                });
            },
            fetchCategories() {
                return apiClient.get("/learning-categories")
                    .then(response => {
                        this.categories = response.data;
                    }).catch((e) => {
                        // eslint-disable-next-line no-console
                        console.log("error get categories", e);
                        router.push({ path: "/403"});
                    });
            },
            fetchTypes() {
                return apiClient.get("/learning-types")
                    .then(response => {
                        this.types = response.data;
                    }).catch((e) => {
                        // eslint-disable-next-line no-console
                        console.log("error get learning types", e);
                        router.push({ path: "/403"});
                    });
            },
        }
    };
</script>
