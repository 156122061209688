<template>
        <jet-form-section @submitted="submitForm">
            <template #title>
                Match professional to coach
            </template>

            <template #form>
                <div
                    class="confirm-msg"
                    :class="{ 'show' : trajectoryUpdate}"
                    data-hj-suppress
                >
                    <i><img
                        src="./../../images/icon-correct.svg"
                        alt="Correct"
                    ></i>Trajectory {{ trajectoryMessage }}
                </div>
                <form>
                    <ul class="form-wrapper">
                        <li
                            class="form-row"
                            :class="{ 'input-error' : form.errors.coach_id }"
                        >
                            <jet-label
                                class="form-label"
                                for="coach"
                            >
                                Coach
                            </jet-label>
                            <select
                                data-hj-suppress
                                id="coach"
                                v-model="selectedCoach"
                                class="form-input"
                            >
                                <option value="">
                                    Select coach
                                </option>
                                <option
                                    v-for="coach in coaches.data"
                                    :key="coach.id"
                                    :value="coach.id"
                                >
                                    {{ coach.name }}
                                </option>
                            </select>
                        </li>
                        <li class="form-row two-input">
                            <div
                                class="input-grp"
                                :class="{ 'input-error' : form.errors.start_date }"
                            >
                                <jet-label
                                    class="form-label"
                                    for="startDate"
                                >
                                    Start date
                                </jet-label>
                                <datepicker
                                    data-hj-suppress
                                    v-model="startDate"
                                    class="datepicker"
                                    :enable-time-picker="false"
                                    format="dd-MM-yyyy"
                                />
                                <span
                                    v-for="error in form.errors.start_date"
                                    v-show="form.errors.start_date"
                                    :key="error"
                                    class="error-msg"
                                >{{ error }}</span>
                            </div>
                            <div
                                class="input-grp"
                                :class="{ 'input-error' : form.errors.end_date }"
                            >
                                <jet-label
                                    class="form-label"
                                    for="endDate"
                                >
                                    End date
                                </jet-label>
                                <datepicker
                                    data-hj-suppress
                                    v-model="endDate"
                                    class="datepicker"
                                    :enable-time-picker="false"
                                    format="dd-MM-yyyy"
                                />
                                <span
                                    v-for="error in form.errors.end_date"
                                    v-show="form.errors.end_date"
                                    :key="error"
                                    class="error-msg"
                                >{{ error }}</span>
                            </div>
                        </li>
                        <li
                            class="form-row"
                            :class="{ 'input-error' : form.errors.status }"
                        >
                            <jet-label
                                class="form-label"
                                for="status"
                            >
                                Status
                            </jet-label>
                            <select
                                data-hj-suppress
                                id="status"
                                v-model="trajectoryStatus"
                                class="form-input"
                            >
                                <option value="">
                                    Select status
                                </option>
                                <option
                                    v-for="status in statuses"
                                    :key="status.id"
                                    :value="status.id"
                                >
                                    {{ status.status }}
                                </option>
                            </select>
                            <span
                                v-for="error in form.errors.status"
                                v-show="form.errors.status"
                                :key="error"
                                class="error-msg"
                            >{{ error }}</span>
                        </li>
                        <li class="form-row mt-4">
                            <div class="group">
                                <primary-button
                                    :disabled="isDisabled"
                                    title="Save match"
                                    @click.prevent="submitForm"
                                />
                                <div class="info-tooltip arrow-up left-1/2 transform -translate-x-1/2 right-auto top-full bottom-auto mt-3 group-hover:block">
                                    <p>When saving, the selected users will be notified.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </form>
            </template>
        </jet-form-section>
</template>

<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import JetFormSection from "@/Jetstream/FormSection";
    import JetLabel from "@/Jetstream/InputLabel";
    import { useVuelidate } from "@vuelidate/core";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import {
        required,
    } from "@vuelidate/validators";
    import Datepicker from "@vuepic/vue-datepicker";
    import "@vuepic/vue-datepicker/dist/main.css";
    
    export default {
        components: {
            JetFormSection,
            JetLabel,
            Datepicker,
            PrimaryButton,
        },
        props: {
            id: {
                type: Number,
                default: () => {},
            }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                coaches: [],
                coachUpdate: false,
                statuses: [{ id: 1, status: "Pending" }, { id: 2, status: "Active" }, {id: 3, status: "Deactivated" }],
                trajectoryUpdate: false,
                trajectoryMessage: "",
                selectedCoach: "",
                startDate: "",
                endDate: "",
                trajectoryStatus: "",
                trajectoryId: "",
                form: {
                    errors: {},
                    processing: false
                }
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        validations: {
            selectedCoach: {required},
            startDate: {required},
            endDate: {required},
            trajectoryStatus: {required},
        },
        mounted() {
            this.fetchCoaches();
            this.fetchUserTrajectory();
                
        },
        methods: {
            fetchCoaches() {
                return apiClient.get("/users?filter[type]=3&filter[status]=3")
                    .then(response => {
                        this.coaches = response.data;
                    // eslint-disable-next-line no-console
                    }).catch((err) => console.log(err));
            },
            fetchUserTrajectory() {
                const url = `/users/${this.id}/trajectories?filter[status]=1,2`;
                return apiClient.get(url)
                    .then(response => {
                        if(response.data.data.length > 0) {
                            this.trajectoryId = response.data.data[0].id;
                            this.selectedCoach = response.data.data[0].coach_id;
                            this.startDate = response.data.data[0].start_date;
                            this.endDate = response.data.data[0].end_date;
                            this.trajectoryStatus = response.data.data[0].status;
                        }
                    // eslint-disable-next-line no-console
                    }).catch((err) => console.log(err));
            },
            formatDate (date) {
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            },
            async submitForm() {
                if (typeof this.startDate === "object") this.startDate = this.formatDate(this.startDate);
                if (typeof this.endDate === "object") this.endDate = this.formatDate(this.endDate);

                this.form.processing = true;
                this.form.errors = {};
                this.coachUpdate = false;
                let payload = {
                    coach_id: this.selectedCoach,
                    start_date: this.startDate,
                    end_date: this.endDate,
                    status: this.trajectoryStatus,
                };


                let url = this.trajectoryId ? `trajectories/${this.trajectoryId}` : `users/${this.id}/trajectories`;
                let method = this.trajectoryId ? "patch" : "post";

                await apiClient.get( ApiService.getApiUrl() + "/sanctum/csrf-cookie");
                apiClient({
                    method: method,
                    url: url,
                    data: payload,
                })
                    .then(response => {
                        this.trajectoryUpdate = true;
                        if(this.trajectoryId) {
                        return this.trajectoryMessage = "was updated sucessfully!";
                        }
                        this.trajectoryMessage = "was created!";
                        return this.fetchUserTrajectory();
                    }).catch(error => {
                        this.form.errors = error.response.data.errors;
                    }).finally(() => {
                        this.form.processing = false;
                });
            }
        }
    };
</script>