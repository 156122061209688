<template>
        <div>
            <div class="data-display-part">
                <div class="certification-info">
                    <certification-item
                        v-for="item in certifications"
                        :key="item.id"
                        :certification="item"
                        @download="downloadCertification"
                        @update-certification-list="fetchUserCertifications(); fetchUserProfilePercentage();"
                    />
                </div>
            </div>

            <div
                v-if="isCoach"
                class="add-certification extend-btn-info "
                @click="showAddCertification=!showAddCertification"
            >
                <h4>Add certification / reference</h4>
            </div>
            <button
                v-else-if="isAdmin"
                class="edit-btn absolute top-0 right-0 mt-6 mr-5"
                @click="downloadAllCertifications"
            >
                <span class="icon icon-download" /> download all
            </button>

            <certification-form
                v-show="showAddCertification"
                :user="authUser"
                @edited-certification="fetchUserCertifications(); fetchUserProfilePercentage();"
            />
        </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import CertificationItem from "@/Pages/Profile/CertificationItem";
    import CertificationForm from "@/Pages/Profile/CertificationForm";
    import downloadBase64 from "@/helpers/downloadBase64";
    
    export default {
        components: {
            CertificationItem,
            CertificationForm
        },
        props: {
            user: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                showAddCertification: false,
                certifications: null
            };
        },
        computed: {
            ...mapGetters(["authUser", "isCoach", "isAdmin"]),
        },
        created() {
            this.fetchUserCertifications(); 
        },
        methods: {
            fetchUserCertifications() {
                if (this.user?.id) {
                    apiClient.get("/users/" + this.user.id + "/files")
                        .then(response => {
                            this.certifications = response.data.data;
                            this.showAddCertification = false;
                        });
                }
            },
            fetchUserProfilePercentage() {
                this.$store.dispatch("getAuthUser");
            },
            downloadCertification(id) {
                apiClient.get("/files/" + (id))
                    .then(response => downloadBase64(response.data.data));
            },
            downloadAllCertifications() {
                this.certifications.forEach(({ id }) => this.downloadCertification(id));
            }
        }
    };
</script>
