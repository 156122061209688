<template>
        <div>
            <form>
                <ul class="">
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.university, 'input-filled' : !v$.form.university.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                for="institution"
                                class="form-label mt-4"
                                value="Institution *"
                            />
                            <jet-input
                                data-hj-suppress
                                id="institution"
                                v-model="form.university"
                                type="text"
                                class="form-input"
                            />
                            <span
                                v-show="form.errors.university"
                                class="error-msg"
                            >{{ form.errors.university }}</span>
                        </div>
                    </li>

                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.city, 'input-filled' : !v$.form.city.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                for="city"
                                class="form-label"
                                value="City *"
                            />
                            <jet-input
                                data-hj-suppress
                                id="city"
                                v-model="form.city"
                                type="text"
                                class="form-input"
                            />
                            <span
                                v-show="form.errors.city"
                                class="error-msg"
                            >{{ form.errors.city }}</span>
                        </div>
                    </li>

                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.country_id, 'input-filled' : !v$.form.country_id.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                for="country_id"
                                class="form-label"
                                value="Country *"
                            />
                            <select
                                data-hj-suppress
                                id="country_id"
                                v-model="form.country_id"
                                class="form-input"
                                autocomplete="country_id"
                            >
                                <option
                                    disabled
                                    value=""
                                >
                                    Please select
                                </option>
                                <option
                                    v-for="country in countries"
                                    :key="country.id"
                                    :value="country.id"
                                >
                                    {{ country.name }}
                                </option>
                            </select>

                            <span
                                v-show="form.errors.country_id"
                                class="error-msg"
                            >{{ form.errors.country_id }}</span>
                        </div>
                    </li>
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.name, 'input-filled' : !v$.form.name.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                for="name"
                                class="form-label"
                                value="Course name *"
                            />
                            <jet-input
                                data-hj-suppress
                                id="name"
                                v-model="form.name"
                                type="text"
                                class="form-input"
                            />
                            <span
                                v-show="form.errors.name"
                                class="error-msg"
                            >{{ form.errors.name }}</span>
                        </div>
                    </li>
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.level_of_education, 'input-filled' : !v$.form.level_of_education.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                for="level_of_education"
                                class="form-label"
                                value="Degree *"
                            />
                            <jet-input
                                data-hj-suppress
                                id="level_of_education"
                                v-model="form.level_of_education"
                                type="text"
                                class="form-input"
                            />
                            <span
                                v-show="form.errors.level_of_education"
                                class="error-msg"
                            >{{ form.errors.level_of_education }}</span>
                        </div>
                    </li>
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.field_of_study, 'input-filled' : !v$.form.field_of_study.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                for="field_of_study"
                                class="form-label"
                                value="Field of study *"
                            />
                            <jet-input
                                data-hj-suppress
                                id="field_of_study"
                                v-model="form.field_of_study"
                                type="text"
                                class="form-input"
                            />
                            <span
                                v-show="form.errors.field_of_study"
                                class="error-msg"
                            >{{ form.errors.field_of_study }}</span>
                        </div>
                    </li>

                    <li class="form-row two-input date-input">
                        <div class="input-grp">
                            <label class="form-label">Start date *</label>
                            <div
                                class="inner-input"
                                :class="{ 'input-error' : form.errors.start_date_month, 'input-filled' : !v$.form.start_date_month.$invalid }"
                            >
                                <select
                                    data-hj-suppress
                                    v-model="form.start_date_month"
                                    class="form-input"
                                >
                                    <option value="">
                                        Month
                                    </option>
                                    <option
                                        v-for="month in months"
                                        :key="month"
                                        :value="month"
                                    >
                                        {{ month }}
                                    </option>
                                </select>
                            </div>
                            <div
                                class="inner-input"
                                :class="{ 'input-error' : form.errors.start_date_year, 'input-filled' : !v$.form.start_date_year.$invalid }"
                            >
                                <select
                                    data-hj-suppress
                                    v-model="form.start_date_year"
                                    class="form-input"
                                >
                                    <option value="">
                                        Year
                                    </option>
                                    <option
                                        v-for="year in years"
                                        :key="year"
                                        :value="year"
                                    >
                                        {{ year }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="input-grp">
                            <label class="form-label">End date (Expected) * </label>
                            <div
                                class="inner-input"
                                :class="{ 'input-error' : form.errors.end_date_month, 'input-filled' : !v$.form.end_date_month.$invalid }"
                            >
                                <select
                                    data-hj-suppress
                                    v-model="form.end_date_month"
                                    class="form-input"
                                >
                                    <option value="">
                                        Month
                                    </option>
                                    <option
                                        v-for="month in months"
                                        :key="month"
                                        :value="month"
                                    >
                                        {{ month }}
                                    </option>
                                </select>
                            </div>
                            <div
                                class="inner-input"
                                :class="{ 'input-error' : form.errors.end_date_year, 'input-filled' : !v$.form.end_date_year.$invalid }"
                            >
                                <select
                                    data-hj-suppress
                                    v-model="form.end_date_year"
                                    class="form-input"
                                >
                                    <option value="">
                                        Year
                                    </option>
                                    <option
                                        v-for="year in years"
                                        :key="year"
                                        :value="year"
                                    >
                                        {{ year }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </li>
                    <li
                        class="form-row"
                        :class="{ 'tooltip-show' : showGradeTooltip, 'input-error' : form.errors.grade_avg }"
                    >
                        <div class="input-grp small-input">
                            <jet-label
                                for="grade"
                                class="form-label"
                                value="Average grade"
                            />
                            <jet-input
                                data-hj-suppress
                                id="grade"
                                v-model="form.grade_avg"
                                type="text"
                                class="form-input"
                                @focus="showGradeTooltip = true"
                                @blur="showGradeTooltip = false"
                            />
                            <span
                                v-show="form.errors.grade_avg"
                                class="error-msg"
                            >{{ form.errors.grade_avg }}</span>
                            <div
                                v-show="showGradeTooltip"
                                class="info-tooltip"
                            >
                                <p>A sophisticated guess is more than nothing. You can always come back and change the grade when it is final. </p>
                            </div>
                        </div>
                    </li>

                    <li class="form-row">
                        <primary-button
                            :title="education ? 'Save education' : 'Save and add education'"
                            :disabled="isDisabled"
                            @click.prevent="submitForm"
                        />
                    </li>
                </ul>
            </form>
        </div>
</template>
<script>
    import JetInput from "@/Jetstream/InputField";
    import JetLabel from "@/Jetstream/InputLabel";
    import { useVuelidate } from "@vuelidate/core";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import {
        integer,
        required,
    } from "@vuelidate/validators";

    export default {
        components: {
            JetInput,
            PrimaryButton,
            JetLabel,
        },
        props: {
            user: {
                type: Object,
                default: () => {},
            },
            education: {
                type: Object,
                default: () => {},
            }
        },
        emits: ["editedEducation"],
        setup: () => ({ v$: useVuelidate({$scope: false}) }),
        data() {
            return {
                showGradeTooltip: false,
                university: (this.education)?this.education.university_fields_of_study.university.name:null,
                countries: {},
                form: {
                    errors: {},
                    processing: false,
                    university: (this.education)?this.education.university_fields_of_study.university.name:null,
                    name: (this.education)?this.education.name:null,
                    level_of_education: (this.education)?this.education.university_fields_of_study.level_of_education.name:null,
                    field_of_study: (this.education)?this.education.university_fields_of_study.field_of_study.name:null,
                    grade_avg: (this.education)?(this.education.grade_avg_percentage/10):null,
                    country_id: (this.education)?this.education.university_location.country_id:null,
                    city: (this.education)?this.education.university_location.city:null,
                    start_date_month: (this.education)?this.education.start_date_month:null,
                    start_date_year: (this.education)?this.education.start_date_year:null,
                    end_date_month: (this.education)?this.education.end_date_month:null,
                    end_date_year: (this.education)?this.education.end_date_year:null,

                    // university: "Hanze",
                    // name: "Name",
                    // level_of_education: "HBO",
                    // field_of_study: "ICT",
                    // grade_avg: "6",
                    // country_id: 158,
                    // city: "Beilen",
                    // start_date_month: 9,
                    // start_date_year: 2011,
                    // end_date_month: 5,
                    // end_date_year: 2014,
                    
                }
            };
        },
        validations: {
            form: {
                university: {required},
                name: {required},
                level_of_education: {required},
                field_of_study: {required},
                country_id: {required},
                city: {required},
                start_date_month: {integer, required},
                start_date_year: {integer, required},
                end_date_month: {integer, required},
                end_date_year: {integer, required},
            }
        },
        computed : {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
            months() {
                return Array.from({length: 12 }, (value, index) => 1 + index);
            },
            years() {
                const year = new Date().getFullYear();
                const start_year = year-50;
                // array from 50 years ago until 6 years from now
                return Array.from({length: year - start_year + 6}, (value, index) => start_year + 1 + index);
            }
        },
        created() {
            this.fetchCountries();
        },
        methods: {
            fetchCountries() {
                apiClient.get("/countries")
                    .then(response => {
                        this.countries = response.data;
                    });
            },
            submitForm() {
                if(this.education){
                    var url = "/users/" + this.$store.getters.authUser.id + "/educations/"+this.education.id;
                    var method = "put";
                }else{
                    var url = "/users/" + this.$store.getters.authUser.id + "/educations";
                    var method = "post";
                }

                apiClient({
                    method: method,
                    url: url,
                    data: {
                        id: this.education?.id,
                        university: this.form.university,
                        name: this.form.name,
                        level_of_education: this.form.level_of_education,
                        field_of_study: this.form.field_of_study,
                        grade_avg: this.form.grade_avg,
                        country_id: this.form.country_id,
                        city: this.form.city,
                        start_date_month: this.form.start_date_month,
                        start_date_year: this.form.start_date_year,
                        end_date_month: this.form.end_date_month,
                        end_date_year: this.form.end_date_year
                    }
                })
                .then(response => {
                    this.$emit("editedEducation");

                    // only clean form when adding a new record
                    if (!this.education) {
                        this.form.university = null;
                        this.form.name = null;
                        this.form.level_of_education = null;
                        this.form.field_of_study = null;
                        this.form.grade_avg = null;
                        this.form.country_id = null;
                        this.form.city = null;
                        this.form.start_date_month = null;
                        this.form.start_date_year = null;
                        this.form.end_date_month = null;
                        this.form.end_date_year = null;
                    }

                    this.form.errors = {};

                } ).catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    if (error.response) {
                        this.form.errors = error.response.data.errors;
                    }
                });


            }
        }
    };
</script>
