<template>
        <div class="flex flex-col relative mr-10 flex-grow">
            <back-to-nav
                title="Back to all learnings"
                to-name="learning"
            />
            <h1 
                v-if="show('title')"
                class="text-3xl font-bold mb-3"
            >
                {{ learning.title }}
            </h1>
            <div
                v-if="show('category')"
                class="content-text-part"
            >
                <h2 class="bottom-shape text-secondary text-base uppercase font-bold">
                    {{ learning.learning_category?.name }}
                </h2>
            </div>
        </div>
        <div class="md:flex max-w-full">
            <div class="flex flex-col relative mr-10 flex-grow">
                <learning-description
                    v-if="show('description')"
                    :title="show('description')"
                    :summary="learning.summary"
                />
                <learning-outline
                    v-if="show('outline')"
                    :outline="learning.outline"
                />
                <div
                    v-if="show('link')"
                    class="w-44 mb-10"
                >
                    <primary-button
                        v-if="learning.learning_type?.name === 'Template'"
                        title="Download"
                        icon="download bg-white"
                        reversed
                        @click="downloadFile"
                    />
                    <learning-link
                        v-else
                        :title="show('link')"
                        :link="learning.external_link"
                    />
                </div>
                <a
                    v-if="show('videoPlayer')"
                    :href="learning.external_link"
                    target="_blank"
                >
                    <img
                        src="../../images/video-preview.png"
                        alt="learning preview"
                        class="object-fit w-full shadow-block rounded-lg"
                    >
                </a>
                <a
                    v-if="show('image') && learning.image"
                    :href="learning.external_link"
                    target="_blank"
                >
                    <img
                        :src="learning.image"
                        alt="learning thumbnail"
                        class="object-fit w-72 h-72 max-w-full shadow-block rounded-lg"
                    >
                </a>
            </div>
            <div style="max-width: 370px; min-width: 300px">
                <learning-banner :learning="learning" />
            </div>
        </div>
</template>

<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import BackToNav from "@/components/nav/BackToNav";
    import LearningBanner from "./LearningBanner";
    import LearningDescription from "./details/LearningDescription";
    import LearningLink from "./details/LearningLink";
    import LearningOutline from "./details/LearningOutline";
    import learningContent from "./lib/learningContent";
    import downloadBase64 from "@/helpers/downloadBase64";
    import PrimaryButton from "@/components/form/PrimaryButton";

    export default {
        components: {
            BackToNav,
            LearningBanner,
            LearningDescription,
            LearningLink,
            LearningOutline,
            PrimaryButton,
        },
        data() {
            return {
                learning: {},
            };
        },
        created() {
            this.fetchLearning();
        },
        methods: {
            fetchLearning() {
                return apiClient.get("/learnings/"+ this.$route.params.id)
                    .then(response => {
                        this.learning = response.data?.data;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line no-console
                        console.log("error get learning", e);
                        router.push({ path: "/403"});
                    });
            },
            show(category) {
                return learningContent[this.learning.learning_type?.name]?.[category];
            },
            clickImage() {
                
            },
            downloadFile() {
                if (this.learning.files.length) {
                    apiClient.get("/files/" + (this.learning.files[0].id))
                    .then(response => downloadBase64(response.data.data));
                }
            },
        },
    };
</script>
