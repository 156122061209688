<template>
        <div class="pt-4 container">
            <div class="common-card">
                <h2>
                    Privacy Policy
                </h2>
                <p>
                    We want you to know that protecting your personal information is our top priority. <br>
                    For that reason, we only use your data in strict compliance with the General Data Protection Regulation (“GDPR”). We want to keep you fully informed about how Young Heroes processes personal data in accordance with the GDPR. <br>
                    We are currently drafting our privacy policy carefully. <br>
                    This is quite a lengthy process, as we want to ensure that everything is correct. Therefore, we also have joined forces with a legal team and we’re able to present a more detailed outline here by October 2021. That privacy policy will automatically apply to our processing of personal data.<br>
                </p>
                <p>If you have any questions or comments about how we process personal data in the meantime, you can always contact us at <a href="mailto:info@youngheroes.com">info@youngheroes.com</a>.</p>
            </div>
        </div>
</template>

<script>

</script>
