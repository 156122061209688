<template>
        <div>
            <div class="content-text-part body-text">
                <h2 class="bottom-shape">
                    Coaches
                </h2>
                <h3>Meet our Coaches</h3>
                <p>
                    <span v-if="theme === 'youngheroes'">Young Heroes</span><span v-if="theme === 'yfk'">My YFK</span> is your Coaching and Career Network. And let’s be honest, what would a Coaching and Career Network be without fantastic coaches? <br>
                    Our coaches are experienced professionals who’ve seen it all. They are experts in the marketing, communication and data field and are more than keen to share their knowledge with you, discuss current market trends and hear your perspective on the innovations in the industry.<br>
                    So, we are excited to introduce these great experts!
                </p>
            </div>

            <!-- Coach Section -->
            <div class="section-row">
                <div class="title-bar">
                    <h2 class="icon-title">
                        <i><img
                            src="./../images/icon-coach.svg"
                            alt="icon"
                        ></i>Coaches
                    </h2>
                <!-- <a href="javascript:void(0)" class="text-link icon-link"><i><img src="./../images/icon-box.svg" alt="icon"></i><span>Show all coaches</span></a> -->
                </div>
                <div class="coach-main-list">
                    <div class="coach-row">
                        <div class="coach-col">
                            <div class="coach-box">
                                <a
                                    href="javascript:void(0)"
                                    class="link-cover"
                                />
                                <div class="coach-image">
                                    <span><img
                                        src="./../images/people/rene.png"
                                        alt="image"
                                    ></span>
                                </div>
                                <div class="coach-info-box">
                                    <h3 class="bottom-shape shape-center">
                                        René
                                    </h3>
                                    <h4>MARKETING & CHANGE MANAGEMENT, DIGITAL TRANSFORMATION</h4>
                                    <p>René is strategic, conceptual and appreciates a good read. He knows how to build your personal brand and helps teams to use their strengths.</p>
                                </div>
                            </div>
                        </div>
                        <div class="coach-col">
                            <div class="coach-box">
                                <a
                                    href="javascript:void(0)"
                                    class="link-cover"
                                />
                                <div class="coach-image">
                                    <span><img
                                        src="./../images/people/wiebe.png"
                                        alt="image"
                                    ></span>
                                </div>
                                <div class="coach-info-box">
                                    <h3 class="bottom-shape shape-center">
                                        Wiebe
                                    </h3>
                                    <h4>CHANGE MANAGEMENT</h4>
                                    <p>Wiebe is a networker at heart. He is proactive and loves the challenge of working in interdisciplinary teams, enabling you to drive your team forward.</p>
                                </div>
                            </div>
                        </div>
                        <div class="coach-col">
                            <div class="coach-box">
                                <a
                                    href="javascript:void(0)"
                                    class="link-cover"
                                />
                                <div class="coach-image">
                                    <span><img
                                        src="./../images/people/gerda.png"
                                        alt="image"
                                    ></span>
                                </div>
                                <div class="coach-info-box">
                                    <h3 class="bottom-shape shape-center">
                                        Gerda
                                    </h3>
                                    <h4>MARKETING & COMMUNICATION</h4>
                                    <p>Gerda is a communications force. She is entrepreneurial, innovative and loves to empower you through building your self-awareness and confidence.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="coach-row mt-5">
                        <div class="coach-col">
                            <div class="coach-box">
                                <a
                                    href="javascript:void(0)"
                                    class="link-cover"
                                />
                                <div class="coach-image">
                                    <span><img
                                        src="./../images/people/krista.png"
                                        alt="image"
                                    ></span>
                                </div>
                                <div class="coach-info-box">
                                    <h3 class="bottom-shape shape-center">
                                        Krista
                                    </h3>
                                    <h4>MARKETING & SUSTAINABILITY</h4>
                                    <p>Krista is enthusiastic and decisive, traits that make a true leader. She is perfect to cheer you on from the sidelines and guide you in complex situations.</p>
                                </div>
                            </div>
                        </div>
                        <div class="coach-col">
                            <div class="coach-box">
                                <a
                                    href="javascript:void(0)"
                                    class="link-cover"
                                />
                                <div class="coach-image">
                                    <span><img
                                        src="./../images/people/miranda.png"
                                        alt="image"
                                    ></span>
                                </div>
                                <div class="coach-info-box">
                                    <h3 class="bottom-shape shape-center">
                                        Miranda
                                    </h3>
                                    <h4>BUSINESS DEVELOPMENT, EXPORT & MARKETING</h4>
                                    <p>Miranda always keeps her cool. She immerses herself in projects but constantly has the bigger picture in mind, making her a detail-oriented and impactful coach.</p>
                                </div>
                            </div>
                        </div>
                        <div class="coach-col">
                            <div class="coach-box">
                                <a
                                    href="javascript:void(0)"
                                    class="link-cover"
                                />
                                <div class="coach-image">
                                    <span><img
                                        src="./../images/people/lesley.png"
                                        alt="image"
                                    ></span>
                                </div>
                                <div class="coach-info-box">
                                    <h3 class="bottom-shape shape-center">
                                        Lesley
                                    </h3>
                                    <h4>PROJECT & CHANGE MANAGEMENT</h4>
                                    <p>Lesley is a problem solver at heart. With her strong customer focus and her love for connections, she puts people at the center of her work.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import { mapGetters } from "vuex";
    export default {
        computed: {
            ...mapGetters(["authUser", "isAdmin", "theme"]),
        },
    };
</script>
