<template>
        <button
            :type="type"
            class="edit-btn"
        >
            <slot />
        </button>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: "submit",
            },
        }
    };
</script>
