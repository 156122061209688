import store from "@/store";

export function isAllowed(value) {
    let allowed = false;

    let permissionArr = value.split("|");
    permissionArr.forEach(function(permission) {
        let check = store.getters.hasPermission(permission.trim());
        if (check === true && allowed === false) {
            allowed = true;
        }
    });
    return allowed;
}

export function isNotAllowed(value)
{
    return !isAllowed(value);
}

//create v-can directive (this will mount the element and do api calls if there called in mounted())
const can = {
    mounted: function (el, binding, vnode) {
        if (typeof el !== "undefined" && !isAllowed(binding.value)) {
            let comment = document.createComment("v-can ");
            el.parentNode.replaceChild(comment, el);
        }
    },
    updated :  function (el, binding, vnode) {
        if (!isAllowed(binding.value)) {
            el.parentNode && el.parentNode.removeChild(el);
        }
    }
};

//create v-cannot directive
const cannot = {
    mounted: function (el, binding, vnode) {
        if (typeof el !== "undefined" && isAllowed(binding.value)) {
            let comment = document.createComment("v-cannot ");
            el.parentNode.replaceChild(comment, el);
        }
    }
};

export { can, cannot };