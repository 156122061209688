<template>
        <section class="profile-main-part">
            <div class="profile-bulid-wrapper login-wrapper">
                <div class="login-area">
                    <div class="login-box">
                        <email-verified v-if="$route.query.verified" />
                        <div class="login-logo">
                            <Logo />
                        </div>
                        <div class="login-form-wrapper">
                            <form
                                class="main-form"
                                @submit.prevent="submitForm"
                            >
                                <ul class="form-wrapper">
                                    <li class="form-row html-row">
                                        <h2>Create your profile now</h2>
                                        <p>
                                            <strong data-hj-suppress>Hey {{ authUser.first_name }}, let’s go!</strong><br>
                                            <span v-if="theme === 'yfk'">Finish up your profile and start exploring YFK.</span>
                                            <span v-else>Finish up your profile and start exploring Young Heroes.</span>
                                        </p>
                                    </li>
                                    <li class="form-row two-input">
                                        <div
                                            class="input-grp"
                                            :class="{ 'input-error' : form.errors.first_name, 'input-filled' : !v$.form.first_name.$invalid }"
                                        >
                                            <jet-label
                                                for="first_name"
                                                class="form-label"
                                                value="First name *"
                                            />
                                            <jet-input
                                                data-hj-suppress
                                                id="first_name"
                                                v-model="form.first_name"
                                                type="text"
                                                class="form-input"
                                                autofocus
                                            />
                                            <span class="error-msg">{{ form.errors.first_name }}</span>
                                        </div>
                                        <div
                                            class="input-grp"
                                            :class="{ 'input-error' : form.errors.last_name, 'input-filled' : !v$.form.last_name.$invalid }"
                                        >
                                            <jet-label
                                                for="last_name"
                                                class="form-label"
                                                value="Last name *"
                                            />
                                            <jet-input
                                                data-hj-suppress
                                                id="last_name"
                                                v-model="form.last_name"
                                                type="text"
                                                class="form-input"
                                            />
                                            <span class="error-msg">{{ form.errors.last_name }}</span>
                                        </div>
                                    </li>
                                    <li class="form-row">
                                        <div
                                            class="input-grp"
                                            :class="{ 'input-error' : form.errors.city, 'input-filled' : !v$.form.city.$invalid }"
                                        >
                                            <jet-label
                                                for="city"
                                                class="form-label"
                                                value="City *"
                                            />
                                            <jet-input
                                                data-hj-suppress
                                                id="city"
                                                v-model="form.city"
                                                type="text"
                                                class="form-input"
                                                autocomplete="city"
                                            />
                                            <span class="error-msg">{{ form.errors.city }}</span>
                                        </div>
                                    </li>
                                    <li class="form-row">
                                        <div
                                            class="input-grp"
                                            :class="{ 'input-error' : form.errors.province, 'input-filled' : !v$.form.province.$invalid }"
                                        >
                                            <jet-label
                                                for="province"
                                                class="form-label"
                                                value="Province *"
                                            />
                                            <jet-input
                                                data-hj-suppress
                                                id="province"
                                                v-model="form.province"
                                                type="text"
                                                class="form-input"
                                                autocomplete="province"
                                            />
                                            <span class="error-msg">{{ form.errors.province }}</span>
                                        </div>
                                    </li>
                                    <li class="form-row">
                                        <div
                                            class="input-grp"
                                            :class="{ 'input-error' : form.errors.country_id, 'input-filled' : !v$.form.country_id.$invalid }"
                                        >
                                            <jet-label
                                                for="country_id"
                                                class="form-label"
                                                value="Country of residence *"
                                            />
                                            <select
                                                data-hj-suppress
                                                id="country_id"
                                                v-model="form.country_id"
                                                class="form-input"
                                                autocomplete="country_id"
                                            >
                                                <option
                                                    disabled
                                                    value=""
                                                >
                                                    Please select
                                                </option>
                                                <option
                                                    v-for="country in countries"
                                                    :key="country.id"
                                                    :value="country.id"
                                                >
                                                    {{ country.name }}
                                                </option>
                                            </select>
                                            <span class="error-msg">{{ form.errors.country_id }}</span>
                                        </div>
                                    </li>
                                    <li class="form-row">
                                        <div
                                            class="radio-grp"
                                            data-hj-suppress
                                            :class="{ 'input-error' : form.errors.lang_nl }"
                                        >
                                            <label class="form-label">Do you speak Dutch? *</label>
                                            <div class="radio-row">
                                                <input
                                                    id="lang_nl_5"
                                                    v-model="form.lang_nl"
                                                    type="radio"
                                                    value="5"
                                                >
                                                <label
                                                    for="lang_nl_5"
                                                    class="radio-label"
                                                >Yes, it's my mother tongue</label>
                                            </div>
                                            <div class="radio-row">
                                                <input
                                                    id="lang_nl_4"
                                                    v-model="form.lang_nl"
                                                    type="radio"
                                                    value="4"
                                                >
                                                <label
                                                    for="lang_nl_4"
                                                    class="radio-label"
                                                >Yes, I am business fluent</label>
                                            </div>
                                            <div class="radio-row">
                                                <input
                                                    id="lang_nl_3"
                                                    v-model="form.lang_nl"
                                                    type="radio"
                                                    value="3"
                                                >
                                                <label
                                                    for="lang_nl_3"
                                                    class="radio-label"
                                                >Yes, I can watch movies without subtitles</label>
                                            </div>
                                            <div class="radio-row">
                                                <input
                                                    id="lang_nl_2"
                                                    v-model="form.lang_nl"
                                                    type="radio"
                                                    value="2"
                                                >
                                                <label
                                                    for="lang_nl_2"
                                                    class="radio-label"
                                                >Yeah, but only know the basics</label>
                                            </div>
                                            <div class="radio-row">
                                                <input
                                                    id="lang_nl_1"
                                                    v-model="form.lang_nl"
                                                    type="radio"
                                                    value="1"
                                                >
                                                <label
                                                    for="lang_nl_1"
                                                    class="radio-label"
                                                >No</label>
                                            </div>
                                            <span class="error-msg">{{ form.errors.lang_nl }}</span>
                                        </div>
                                    </li>
                                    <li class="form-row">
                                        <div
                                            class="radio-grp"
                                            :class="{ 'input-error' : form.errors.lang_en }"
                                            data-hj-suppress
                                        >
                                            <label class="form-label">Do you speak English? *</label>
                                            <div class="radio-row">
                                                <input
                                                    id="lang_en_5"
                                                    v-model="form.lang_en"
                                                    type="radio"
                                                    value="5"
                                                >
                                                <label
                                                    for="lang_en_5"
                                                    class="radio-label"
                                                >Yes, it's my mother tongue</label>
                                            </div>
                                            <div class="radio-row">
                                                <input
                                                    id="lang_en_4"
                                                    v-model="form.lang_en"
                                                    type="radio"
                                                    value="4"
                                                >
                                                <label
                                                    for="lang_en_4"
                                                    class="radio-label"
                                                >Yes, I am business fluent</label>
                                            </div>
                                            <div class="radio-row">
                                                <input
                                                    id="lang_en_3"
                                                    v-model="form.lang_en"
                                                    type="radio"
                                                    value="3"
                                                >
                                                <label
                                                    for="lang_en_3"
                                                    class="radio-label"
                                                >Yes, I can watch movies without subtitles</label>
                                            </div>
                                            <div class="radio-row">
                                                <input
                                                    id="lang_en_2"
                                                    v-model="form.lang_en"
                                                    type="radio"
                                                    value="2"
                                                >
                                                <label
                                                    for="lang_en_2"
                                                    class="radio-label"
                                                >Yeah, but only know the basics</label>
                                            </div>
                                            <div class="radio-row">
                                                <input
                                                    id="lang_en_1"
                                                    v-model="form.lang_en"
                                                    type="radio"
                                                    value="1"
                                                >
                                                <label
                                                    for="lang_en_1"
                                                    class="radio-label"
                                                >No</label>
                                            </div>
                                            <span class="error-msg">{{ form.errors.lang_en }}</span>
                                        </div>
                                    </li>

                                    <li class="extend-form-row">
                                        <education-list :init-profile="true" />
                                    </li>

                                    <li class="form-row">
                                        <input
                                            class="form-button"
                                            :disabled="isDisabled"
                                            :class="{ 'opacity-25': isDisabled }"
                                            value="Discover the platform"
                                            type="submit"
                                        >
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>
<script>
    import { mapGetters } from "vuex";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    
    import EmailVerified from "@/components/EmailVerified";
    import JetInput from "@/Jetstream/InputField";
    import JetLabel from "@/Jetstream/InputLabel";
    import EducationList from "@/Pages/Profile/EducationList";
    import Logo from "@/components/LogoComponent";
    import { useVuelidate } from "@vuelidate/core";
    import {
        required,
    } from "@vuelidate/validators";

    export default {
        components: {
            EmailVerified,
            JetInput,
            JetLabel,
            EducationList,
            Logo
        },
        props: {
            theme: {
                type: String,
                default: ""
            }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                countries: {},
                form: {
                    processing: false,
                    errors: {},
                    first_name: null,
                    last_name: null,
                    city: null,
                    province: null,
                    country_id: null,
                    lang_nl: null,
                    lang_en: null,
                    educations: null
                },
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
            ...mapGetters(["authUser"]),
        },
        mounted() {
            this.form.first_name = this.authUser.first_name;
            this.form.last_name = this.authUser.last_name;
            this.form.city = this.authUser.city;
            this.form.province = this.authUser.province;
            this.form.country_id = this.authUser.country_id;
        },
        created() {
            this.fetchCountries();
        },
        validations: {
            form: {
                first_name: {required},
                last_name: {required},
                city: {required},
                province: {required},
                country_id: {required},
                lang_nl: {required},
                lang_en: {required}
            }
        },
            methods: {
                submitForm() {
                    if (this.$store.getters.authUser?.id) {
                        let promises = [];
                        let promise1 = apiClient.put("/users/" + this.$store.getters.authUser.id, this.form)
                        //     .then(response => {
                        //     console.log(response.data);
                        // })
                        // .catch(error => {
                        //     console.log(error.response);
                        // })
                        ;
                        const payload = {
                            language_id: 25, // nl Dutch
                            grade: this.form.lang_nl
                        };
                        let promise2 = apiClient.post("/users/" + this.$store.getters.authUser.id + "/languages", payload)
                            // .then(response => {
                            //     console.log(response.data);
                            // })
                            // .catch(error => {
                            //     console.log(error.response);
                            // })
                        ;
                        const payload_en = {
                            language_id: 26, // en English
                            grade: this.form.lang_en
                        };
                        let promise3 = apiClient.post("/users/" + this.$store.getters.authUser.id + "/languages", payload_en)
                            // .then(response => {
                            //     console.log(response.data);
                            // })
                            // .catch(error => {
                            //     console.log(error.response);
                            // })
                        ;

                        var store = this.$store;
                        var router = this.$router;
                        Promise.all([promise1, promise2, promise3])
                            .then(function(data) {
                                if (data[0] && data[0].data) {
                                    // update the application with the new data
                                    store.dispatch("setAuthUser", { user: data[0].data.data });
                                    router.push({name: "home"});
                                }
                        })
                        .catch(error => {
                            // eslint-disable-next-line no-console
                            console.log(error);
                        });
                        
                    } else {
                        // eslint-disable-next-line no-console
                        console.error("no user found");
                    }
                },
                fetchCountries() {
                    apiClient.get("/countries")
                        .then(response => {
                            this.countries = response.data;
                        });
                }
        }
    };
</script>
