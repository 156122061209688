<template>
        <div class="flex w-full items-center border border-[#efeff2] px-6 py-3 rounded-lg">
            <p class="mr-6 text-sm font-normal w-min mb-0">
                Category
            </p>
            <select
                class="form-input"
                @change="(e) => $emit('select-category', e.target.value)"
            >
                <option value="">
                    All
                </option>
                <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                >
                    {{ category.name }}
                </option>
            </select>
            <p class="mx-6 text-sm font-normal w-min mb-0 whitespace-nowrap">
                Content type
            </p>
            <select
                class="form-input"
                @change="(e) => $emit('select-type', e.target.value)"
            >
                <option value="">
                    All
                </option>
                <option
                    v-for="type in types"
                    :key="type.id"
                    :value="type.id"
                >
                    {{ type.name }}
                </option>
            </select>
        </div>
</template>

<script>
    export default {
        props: {
            categories: {
                type: Array,
                default: () => []
            },
            types: {
                type: Array,
                default: () => []
            },
        },
        emits: ["select-category", "select-type"]
    };
</script>
