<template>
        <div class="mb-10">
            <h4 class="text-lg font-bold">
                Outline
            </h4>
            <div
                class="mb-0 ck ck-content"
                v-html="outline"
            />
        </div>
</template>

<script>
export default {
    props: {
        outline: {
            type: String,
            default: ""
        }
    }
};
</script>
