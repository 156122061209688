<template>
        <jet-form-section>
            <template #title>
                Update password
            </template>

            <template #description>
                Ensure your account is using a long, random password to stay secure.
            </template>

            <template #form>
                <div
                    class="confirm-msg"
                    :class="{ 'show' : passwordUpdated}"
                >
                    <i><img
                        src="./../../images/icon-correct.svg"
                        alt="Correct"
                    ></i>Password updated successfully.
                </div>
                <ul class="form-wrapper">
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.current_password, 'input-filled' : !v$.form.current_password.$invalid }"
                    >
                        <div class="input-grp">
                            <jet-label
                                class="form-label"
                                for="current_password"
                                value="Current Password"
                            />
                            <jet-input
                                data-hj-suppress
                                id="current_password"
                                ref="current_password"
                                v-model="form.current_password"
                                type="password"
                                class="form-input"
                                autocomplete="current-password"
                            />
                            <span
                                v-for="error in form.errors.current_password"
                                :key="error"
                                class="error-msg"
                            >{{ error }}</span>
                        </div>
                    </li>

                    <li
                        class="form-row tooltip-show"
                        :class="{ 'input-error' : form.errors.password, 'input-filled' : !v$.form.password.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                class="form-label"
                                for="password"
                                value="New Password"
                            />
                            <jet-input
                                data-hj-suppress
                                id="password"
                                ref="password"
                                v-model="form.password"
                                type="password"
                                class="form-input"
                                autocomplete="new-password"
                                @focus="showPasswordTooltip = true"
                                @blur="showPasswordTooltip = false"
                            />
                            <span
                                v-for="error in form.errors.password"
                                :key="error"
                                class="error-msg"
                            >{{ error }}</span>
                            <div
                                v-show="showPasswordTooltip"
                                class="info-tooltip"
                            >
                                <ul class="password-hint">
                                    <li :class="{ 'checked': (!v$.form.password.minLength.$invalid && !v$.form.password.required.$invalid) }">
                                        min. 8 characters
                                    </li>
                                    <li :class="{ 'checked': (!v$.form.password.containsNumber.$invalid) }">
                                        min. 1  number
                                    </li>
                                    <li :class="{ 'checked': (!v$.form.password.containsUppercase.$invalid) }">
                                        min. 1 uppercase character
                                    </li>
                                    <li :class="{ 'checked': (!v$.form.password.containsLowercase.$invalid) }">
                                        min. 1 lowercase character
                                    </li>
                                    <li :class="{ 'checked': (!v$.form.password.containsSpecial.$invalid) }">
                                        min. 1 special character
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>

                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.password_confirmation, 'input-filled' : !v$.form.password_confirmation.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                class="form-label"
                                for="password_confirmation"
                                value="Confirm Password"
                            />
                            <jet-input
                                data-hj-suppress
                                id="password_confirmation"
                                v-model="form.password_confirmation"
                                type="password"
                                class="form-input"
                                autocomplete="new-password"
                            />
                            <span
                                v-for="error in form.errors.password_confirmation"
                                :key="error"
                                class="error-msg"
                            >{{ error }}</span>
                        </div>
                    </li>

                    <li class="form-row">
                        <primary-button
                            title="Save password"
                            :disabled="isDisabled"
                            @click.prevent="updatePassword"
                        />
                    </li>
                </ul>
            </template>
        </jet-form-section>
</template>

<script>
    import JetFormSection from "@/Jetstream/FormSection";
    import JetInput from "@/Jetstream/InputField";
    import JetLabel from "@/Jetstream/InputLabel";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import { useVuelidate } from "@vuelidate/core";
    import AuthService from "@/services/AuthService";
    import {
        required,
        minLength,
        email,
    } from "@vuelidate/validators";

    export default {
        components: {
            JetFormSection,
            JetInput,
            JetLabel,
            PrimaryButton
        },
        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                showPasswordTooltip: false,
                passwordUpdated: false,
                form: {
                    errors: {},
                    processing: false,
                    current_password: "",
                    password: "",
                    password_confirmation: "",
                },
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        validations: {
            form: {
                current_password: { required },
                password: { required,
                    valid: function(value) {
                        const containsUppercase = /[A-Z]/.test(value);
                        const containsLowercase = /[a-z]/.test(value);
                        const containsNumber = /[0-9]/.test(value);
                        const containsSpecial = /[#?!@$%^&*-]/.test(value);
                        return containsUppercase && containsLowercase && containsNumber && containsSpecial;
                    },
                    minLength: minLength(8),
                    containsUppercase: function(value) {
                        return /[A-Z]/.test(value);
                    },
                    containsLowercase: function(value) {
                        return /[a-z]/.test(value);
                    },
                    containsNumber: function(value) {
                        return /[0-9]/.test(value);
                    },
                    containsSpecial: function(value) {
                        return /[#?!@$%^&*-]/.test(value);
                    }
                },
                password_confirmation: { required,
                    sameAsPassword: function(value){
                        return this.form.password_confirmation === this.form.password;
                    }
                },
            }
        },
        methods: {
            updatePassword() {
                this.form.errors  = {};
                this.form.processing = true;
                const payload = {
                    current_password: this.form.current_password,
                    password: this.form.password,
                    password_confirmation: this.form.password_confirmation,
                };
                AuthService.updatePassword(payload)
                    .then((response) => {
                        this.passwordUpdated = true;
                    })
                    .catch((error) => {
                        this.form.errors = error.response.data.errors;
                    }).finally(() => {
                    this.form.current_password = "";
                    this.form.password = "";
                    this.form.password_confirmation = "";
                    this.form.processing = false;
                });
            },
        },
    };
</script>
