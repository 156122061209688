<template>
        <div class="section-row">
            <h2 class="icon-title float-none">
                <i><img
                    src="../../../images/icon-docs.svg"
                    alt="icon"
                ></i>My Journey notes
            </h2>

            <div class="mb-3">
							<h4>Journey 1 - Know yourself</h4>
							<ckeditor
								data-hj-suppress
								v-model="inputNotes.journey1"
								:editor="editor"
								:config="editorConfig"
								@focus="isDisabled=false"
                />
            </div>

						<div class="mb-3">
							<h4>Journey 2 - Know yourself</h4>
							<ckeditor
								data-hj-suppress
								v-model="inputNotes.journey2"
								:editor="editor"
								:config="editorConfig"
								@focus="isDisabled=false"
							/>
						</div>

						<div class="mb-3">
							<h4>Journey 3 - Understand the rules of the game</h4>
							<ckeditor
								data-hj-suppress
								v-model="inputNotes.journey3"
								:editor="editor"
								:config="editorConfig"
								@focus="isDisabled=false"
							/>
						</div>

						<div class="mb-3">
							<h4>Journey 4 - Understand the rules of the game</h4>
							<ckeditor
								data-hj-suppress
								v-model="inputNotes.journey4"
								:editor="editor"
								:config="editorConfig"
								@focus="isDisabled=false"
							/>
						</div>

						<div class="mb-3">
							<h4>Journey 5 - Understand the rules of the game</h4>
							<ckeditor
								data-hj-suppress
								v-model="inputNotes.journey5"
								:editor="editor"
								:config="editorConfig"
								@focus="isDisabled=false"
							/>
						</div>

						<div class="mb-3">
							<h4>Journey 6 - See opportunity</h4>
							<ckeditor
								data-hj-suppress
								v-model="inputNotes.journey6"
								:editor="editor"
								:config="editorConfig"
								@focus="isDisabled=false"
							/>
						</div>

						<div class="mb-3">
							<h4>Journey 7 - See opportunity</h4>
							<ckeditor
								data-hj-suppress
								v-model="inputNotes.journey7"
								:editor="editor"
								:config="editorConfig"
								@focus="isDisabled=false"
							/>
						</div>

            <div v-if="isProfessional">
                <div
                    class="w-40"
                >
                    <primary-button
											  :disabled="isDisabled"
                        title="Save notes"
                        @click="updateNotes"
                    />
                </div>
            </div>
        </div>
</template>

<script>
import { mapGetters } from "vuex";
    import SecondaryButton from "../../form/SecondaryButton";
    import PrimaryButton from "../../form/PrimaryButton";
    import TextField from "../../form/TextField";

export default {
        components: {
            SecondaryButton,
            PrimaryButton,
            TextField,
        },
        props: {
            notes: {
                type: Array,
                default: [],
            },
        },
        emits: ["updateNotes"],
        data() {
            return {
								isDisabled: true,
                inputNotes: this.notes,
            };
        },
        computed: {
            ...mapGetters(["isProfessional"])
        },
        watch: {
            notes(notes) {
                this.inputNotes = notes;
            },
        },
        methods: {
						updateNotes() {
                this.$emit("updateNotes", this.inputNotes);
								this.isDisabled = true;
            },
        },
    };
</script>
