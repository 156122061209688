<template>
        <button
            :disabled="disabled"
            class="flex w-9 min-w-9 h-9 justify-center items-center border rounded"
            :class="disabled && 'pointer-events-none'"
        >
            <img
                v-show="checked"
                src="../../images/icon-check.svg"
                alt="check"
            >
        </button>
</template>
<script>
    export default {
        props: {
            checked: Boolean,
            disabled: Boolean,
        },
    };
</script>