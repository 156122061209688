<template>
        <jet-authentication-card>
            <template #logo>
                <Logo />
            </template>

            <div class="login-form-wrapper">
                <form class="main-form">
                    <ul class="form-wrapper">
                        <li class="form-row">
                            <template v-if="! recovery">
                                Please confirm access to your account by entering the authentication code provided by your authenticator application.
                            </template>

                            <template v-else>
                                Please confirm access to your account by entering one of your emergency recovery codes.
                            </template>
                        </li>
                        <li class="form-row">
                        <!--<jet-validation-errors class="mb-4" />-->
                        </li>
                        <li
                            v-if="! recovery"
                            class="form-row"
                        >
                            <jet-label
                                for="code"
                                value="Code"
                            />
                            <jet-input
                                data-hj-suppress
                                id="code"
                                ref="code"
                                v-model="form.code"
                                type="text"
                                inputmode="numeric"
                                class="form-input"
                                autofocus
                                autocomplete="one-time-code"
                            />
                        </li>

                        <li
                            v-else
                            class="form-row"
                        >
                            <jet-label
                                for="recovery_code"
                                value="Recovery Code"
                            />
                            <jet-input
                                data-hj-suppress
                                id="recovery_code"
                                ref="recovery_code"
                                v-model="form.recovery_code"
                                type="text"
                                class="form-input"
                                autocomplete="one-time-code"
                            />
                        </li>
                        <li class="form-row">
                            <div
                                class="flex items-center justify-end mt-4"
                                style="justify-items: end;
    display: flex;
    text-align: center;"
                            >
                                <button
                                    type="button"
                                    class="text-sm text-gray-600 hover:text-gray-900 underline cursor-pointer"
                                    style="
                            background: transparent;
    cursor: pointer;
    color: grey;"
                                    @click.prevent="toggleRecovery"
                                >
                                    <template v-if="! recovery">
                                        Use a recovery code
                                    </template>

                                    <template v-else>
                                        Use an authentication code
                                    </template>
                                </button>
                                <div class="w-40 ml-3">
                                    <primary-button
                                        title="Log in"
                                        :disabled="form.processing"
                                        @click.prevent="submit"
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
        </jet-authentication-card>
</template>

<script>
    import AuthService from "@/services/AuthService";
    import JetAuthenticationCard from "@/Jetstream/AuthenticationCard";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import JetInput from "@/Jetstream/InputField";
    import JetLabel from "@/Jetstream/InputLabel";
    import Logo from "@/components/LogoComponent";
    //import JetValidationErrors from '@/Jetstream/ValidationErrors'

    export default {
        components: {
            JetAuthenticationCard,
            PrimaryButton,
            JetInput,
            JetLabel,
            Logo
            //JetValidationErrors,
        },

        data() {
            return {
                recovery: false,
                form: {
                    processing: false,
                    errors: {},
                    code: "",
                    recovery_code: "",
                }
            };
        },

        methods: {
            toggleRecovery() {
                this.recovery ^= true;

                this.$nextTick(() => {
                    if (this.recovery) {
                        this.$refs.recovery_code.focus();
                        this.form.code = "";
                    } else {
                        this.$refs.code.focus();
                        this.form.recovery_code = "";
                    }
                });
            },

            async submit() {
                try {
                    const payload = {
                        code: this.form.code,
                        recovery_code: this.form.recovery_code
                    };
                    const loginResult = await AuthService.twoFactorChallenge(payload);
                    if (loginResult) {
                        this.$store.dispatch("setGuest", { value: "isNotGuest" });

                        if (this.$route.query.redirect && !this.$route.query.redirect.includes("login")) {
                            this.$router.push(this.$route.query.redirect);
                        } else {
                            this.$router.push("/");
                        }
                    }
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log(error);
                }

            }
        }
    };
</script>
