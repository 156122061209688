<template>
        <jet-action-section>
            <template #title>
                Two-factor authentication
            </template>

            <template #description>
                Add additional security to your account using two factor authentication.
            </template>

            <template #content>
                <h3
                    v-if="twoFactorEnabled"
                    class="text-lg font-medium text-gray-900"
                >
                    You have enabled two factor authentication.
                </h3>

                <h3
                    v-else
                    class="text-lg font-medium text-gray-900"
                >
                    You have not enabled two factor authentication.
                </h3>

                <div class="mt-3 max-w-xl text-sm text-gray-600">
                    <p>
                        When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.
                    </p>
                </div>

                <div v-if="twoFactorEnabled">
                    <div v-if="qrCode">
                        <div class="mt-4">
                            <p>
                                <b>Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application.</b>
                            </p>
                        </div>
                        <div
                            data-hj-suppress
                            class="mt-4"
                            v-html="qrCode"
                        />
                    </div>

                    <div v-if="recoveryCodes.length > 0">
                        <div class="mt-4">
                            <p class="font-semibold">
                                Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.
                            </p>
                        </div>

                        <div class="mt-4 py-4 font-mono">
                            <div
                                data-hj-suppress
                                v-for="code in recoveryCodes"
                                :key="code"
                            >
                                {{ code }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-5">
                    <div v-if="! twoFactorEnabled">
                        <jet-confirms-password data-hj-suppress @confirmed="enableTwoFactorAuthentication">
                            <secondary-button
                                title="Enable"
                                :disabled="enabling"
                            />
                        </jet-confirms-password>
                    </div>

                    <div v-else>
                        <jet-confirms-password data-hj-suppress
                            class="mr-2"
                            @confirmed="regenerateRecoveryCodes"
                        >
                            <secondary-button
                                v-if="recoveryCodes.length > 0"
                                title="Regenerate Recovery Codes"
                            />
                        </jet-confirms-password>

                        <jet-confirms-password data-hj-suppress @confirmed="showRecoveryCodes">
                            <jet-secondary-button
                                v-if="recoveryCodes.length === 0"
                                class="mr-3"
                            >
                                Show Recovery Codes
                            </jet-secondary-button>
                        </jet-confirms-password>

                        <jet-confirms-password data-hj-suppress @confirmed="disableTwoFactorAuthentication">
                            <div class="w-40 inline-block">
                                <primary-button
                                    title="Disable"
                                    :disabled="disabling"
                                />
                            </div>
                        </jet-confirms-password>
                    </div>
                </div>
            </template>
        </jet-action-section>
</template>

<script>
    import AuthService from "@/services/AuthService";
    import JetActionSection from "@/Jetstream/ActionSection";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import JetConfirmsPassword from "@/Jetstream/ConfirmsPassword";
    import JetSecondaryButton from "@/Jetstream/SecondaryButton";

    export default {
        components: {
            JetActionSection,
            SecondaryButton,
            PrimaryButton,
            JetConfirmsPassword,
            JetSecondaryButton,
        },

        data() {
            return {
                enabling: false,
                disabling: false,

                qrCode: null,
                recoveryCodes: [],
            };
        },

        computed: {
            twoFactorEnabled() {
                return !this.enabling && this.$store.getters.authUser?.two_factor_enabled;
            }
        },

        methods: {
            enableTwoFactorAuthentication() {
                this.enabling = true;
                    AuthService.enableTwoFactorAuthentication({})
                        .then(response => {
                            Promise.all([
                                this.showQrCode(),
                                this.showRecoveryCodes(),
                                this.$store.dispatch("getAuthUser")
                            ]);
                    }).catch(error => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                    }).finally(() => {
                        this.enabling = false;
                    });
            },

            showQrCode() {
                AuthService.twoFactorQrCode({})
                    .then(response => {
                        this.qrCode = response.data.svg;
                    });
            },

            showRecoveryCodes() {
                AuthService.getTwoFactorRecoveryCodes({})
                        .then(response => {
                            this.recoveryCodes = response.data;
                        });
            },

            regenerateRecoveryCodes() {
                AuthService.regenerateRecoveryCodes({})
                        .then(response => {
                            this.showRecoveryCodes();
                        });
            },
            disableTwoFactorAuthentication() {
                this.disabling = true;
                AuthService.disableTwoFactorAuthentication({}).then(() => {
                    // update vuex user
                    this.$store.dispatch("getAuthUser").then(() => {
                        this.disabling = false;
                    });
                });
            },
        }
    };
</script>
