<template>
        <div class="data-edit-part">
            <form>
                <ul class="form-wrapper">
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.years_experience, 'input-filled' : !v$.form.years_experience.$invalid}"
                    >
                        <div class="input-grp">
                            <label class="form-label flex items-center">
                                I have
                                <input
                                    data-hj-suppress
                                    v-model="form.years_experience"
                                    class="form-input w-16 mx-1 px-3"
                                    step="1"
                                    type="number"
                                >
                                years of experience.
                            </label>
                            <span
                                v-show="form.errors.years_experience"
                                class="error-msg"
                            >{{ form.errors.years_experience }}</span>
                        </div>
                    </li>
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.motivation}"
                    >
                        <div class="input-grp">
                            <label class="form-label flex items-center">
                                My motivation to coach
                                <div class="ml-2">
                                    <information-tooltip
                                        content="Tell us about your main motivation to be a coach!"
                                        arrow="arrow-down"
                                    />
                                </div>
                            </label>
                            <ckeditor
                                data-hj-suppress
                                v-model="form.motivation"
                                :editor="editor"
                                :config="editorConfig"
                            />
                            <span
                                v-show="form.errors.motivation"
                                class="error-msg"
                            >{{ form.errors.motivation }}</span>
                        </div>
                    </li>
                    <li class="form-row">
                        <primary-button
                            title="Save coaching experience"
                            :disabled="isDisabled"
                            @click.prevent="submitForm"
                        />
                    </li>
                </ul>
            </form>
        </div>
</template>
<script>
    import {default as apiService, apiClient } from "@/services/ApiService";
    import { useVuelidate } from "@vuelidate/core";
    import InformationTooltip from "@/components/InformationTooltip";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import {
        required,
    } from "@vuelidate/validators";
    

    export default {
        components: {
            InformationTooltip,
            PrimaryButton,
        },
        props: {
            user: {
                type: Object,
                default: () => {},
            }
        },
        emits: ["userEdited"],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                form: {
                    processing: false,
                    errors: {},
                    years_experience: this.user.years_experience,
                    motivation: this.user.motivation,
                },
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        validations: {
            form: {
                years_experience: {required},
                motivation: {required},
            }
        },
        methods: {
            submitForm() {
                let store = this.$store;
                let formData = new FormData();
                formData.append("_method", "patch");
                formData.append("years_experience", this.form.years_experience);
                formData.append("motivation", this.form.motivation);
                apiClient({
                    method: "post",
                    url: "/users/" + this.$store.getters.authUser.id,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                    .then(response => {
                        store.dispatch("setAuthUser", { user: response.data.data });
                        this.$emit("userEdited");
                    }).catch(error => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                });
            },
        }
    };
</script>
