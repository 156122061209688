<template>
        <section class="login-main-part">
            <div class="sign-up-part sign-up-done-part login-wrapper">
                <div class="login-area">
                    <div class="login-box">
                        <div class="login-logo">
                            <Logo />
                        </div>
                        <div
                            v-if="showCheckEmailMessage"
                            class="login-form-wrapper"
                        >
                            <form class="main-form">
                                <ul class="form-wrapper">
                                    <li class="form-row html-row text-center">
                                        <h2
                                            v-if="authUser?.profile_percentage < 10 && $store.state.theme === 'youngheroes'"
                                            class="bottom-shape shape-center"
                                        >
                                            Welcome to the Young Heroes Community!
                                        </h2>
                                        <h2
                                            v-if="authUser?.profile_percentage < 10 && $store.state.theme === 'yfk'"
                                            class="bottom-shape shape-center"
                                        >
                                            Welcome to the YFK Community!
                                        </h2>
                                        <p>Please confirm your email address to continue.</p>
                                        <p>Go ahead, check your e-mail!  <span class="icon-emoji">😎</span></p>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
    import AuthService from "@/services/AuthService";
    import Logo from "@/components/LogoComponent";
    import { mapGetters } from "vuex";


    export default {
        components: {
            Logo
        },
        data() {
            return {
                showCheckEmailMessage: false
            };
        },
        computed: {
            ...mapGetters(["authUser"]),
        },
        mounted() {
            this.checkEmail();
        },
        methods: {
            checkEmail() {
                var self = this;
                if (this.$route.params.id && this.$route.params.hash) {
                    const payload = {
                        id: this.$route.params.id,
                        hash: this.$route.params.hash
                    };
                    AuthService.verifyVerification(payload, this.$route.query)
                        .then((response) => {
                            this.$store.dispatch("getAuthUser").then(() => {
                                this.$router.push({name: "home", query: response.data });
                            });
                    }).catch((error) => {
                        // could be 403 forbidden for invalid signature
                        // could be 401 unauthorized when clicking on the link without a session cookie
                        // eslint-disable-next-line no-console
                        console.log(error);
                    });
                } else if (this.authUser?.email_verified === true) {
                    this.$router.push({name: "home", query: {verified: 1} });
                } else {
                    this.showCheckEmailMessage = true;
                }
            }
        },

    };
</script>
