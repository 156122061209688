//import { createApp } from "vue/dist/vue.esm-bundler.js"
import { createApp, vue } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import store from "@/store";
import router from "@/router";
import { can, cannot } from "@/acl";
import { computed, watchEffect } from "@vue/runtime-core";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import App from "./App.vue";
import {isAllowed, isNotAllowed} from "./acl";
import {default as ApiService, apiClient } from "@/services/ApiService";
import Echo from "laravel-echo";

const { VUE_APP_GTM_ENABLED, VUE_APP_GTM_TAG, VUE_APP_GTM_DEBUG, VUE_APP_DOMAIN, VUE_APP_WS_KEY } = process.env;

const stored_theme = computed(() => store.state.theme);

window.Pusher = require("pusher-js");
window.Echo = new Echo({
    broadcaster: "pusher",
    key: VUE_APP_WS_KEY,
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                apiClient.post("broadcasting/auth", {
                    socket_id: socketId,
                    channel_name: channel.name
                })
                    .then(response => {
                        callback(false, response.data);

                    })
                    .catch(error => {
                        callback(true, error);
                    });
            }
        };
    },
    wsHost: ApiService.getWebsocketUrl(),
    wsPort: (ApiService.getApiUrl().indexOf("https") > -1) ? 443 : 6001,
    wsPath: "/ws",
    forceTLS: (ApiService.getApiUrl().indexOf("https") > -1),
    disableStats: true,
});

if (window.location.host === `${VUE_APP_DOMAIN}:82` || window.location.host.indexOf("yfk") > -1) {
    store.commit("SET_THEME", "yfk");
}

watchEffect(() => {
    import(`./themes/${stored_theme.value}/theme.scss`).then(() => {
        store.commit("SET_THEME_INITIALIZED", true);
    });
});

const app = createApp(App)
    .use(router)
    .use(store)
    .use(CKEditor)
    .use(createGtm({
        id: VUE_APP_GTM_TAG, 
        enabled: (stored_theme.value === "youngheroes" && VUE_APP_GTM_ENABLED === "true"),
        vueRouter: router,
        debug: (VUE_APP_GTM_DEBUG === "true"), 
        ignoredViews: ["notFound","notAllowed","create-password","verify-email-check","vacancy"],
        trackOnNextTick: true
        })
    )
;
app.config.globalProperties.$defaultTitle = (stored_theme.value === "yfk") ? "My YFK" : "My Young Heroes";

// acl functions to not load components if not authorized 
app.config.globalProperties.$can = isAllowed;
app.config.globalProperties.$cannot = isNotAllowed;
app.config.globalProperties.editor = ClassicEditor;
app.config.globalProperties.editorConfig = {
    toolbar: [ "heading", "|",  "bold", "italic", "link", "numberedList", "bulletedList", "|", "outdent", "indent", "|", "undo", "redo"],
};

// acl functions to not show html elements (they will be loaded and should not be used for components)
app.directive("can", can);
app.directive("cannot", cannot);

app.mount("#app");
