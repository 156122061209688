const learningContent = {
    eLearning: {
        category: true,
        contentType: true,
        duration: true,
        fileType: false,
        title: true,
        description: "What will you learn?",
        outline: true,
        link: "Start now",
        videoPlayer: false,
        image: true,
    },
    Video: {
        category: true,
        contentType: true,
        duration: true,
        fileType: false,
        title: true,
        description: "What will you learn?",
        outline: true,
        link: "Watch now",
        videoPlayer: false,
        image: true,
    },
    Podcast: {
        category: true,
        contentType: true,
        duration: true,
        fileType: false,
        title: true,
        description: "What will you learn?",
        outline: false,
        link: "Listen now",
        videoPlayer: true,
        image: false,
    },
    Article: {
        category: true,
        contentType: true,
        duration: true,
        fileType: true,
        title: true,
        description: "What will I learn?",
        outline: false,
        link: "Read now",
        videoPlayer: false,
        image: true,
    },
    Template: {
        category: true,
        contentType: true,
        duration: false,
        fileType: false,
        title: true,
        description: "How can you use it?",
        outline: false,
        link: "Download now",
        videoPlayer: false,
        image: false,
    },
};

export default learningContent;
