<template>

        <div class="flex flex-wrap gap-4 mt-7">
            <learning-tile
                v-for="learning in learnings?.data"
                :key="learning"
                :learning="learning"
                @fetch-learnings="fetchLearnings"
            />
        </div>

    <BasePagination
            v-if="learnings?.meta && learnings.meta.last_page > 1"
            :queryParams="queryParams"
            :meta="learnings.meta"
            :links="learnings.links"
            @changeRoute="changeRoute"
    />
</template>

<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import LearningTile from "./LearningTile.vue";
    import BasePagination from "@/components/BasePagination";
    
    export default {
        components: {
            LearningTile,
            BasePagination,
        },
        props: {
            category: {
                type: String,
                default: null
            },
            type: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                learnings: [],
                queryParams: [],
            };
        },
        watch: {
            category() {
                this.getFilteredLearnings();
            },
            type() {
                this.getFilteredLearnings();
            },
        },

        created() {
            this.getFilteredLearnings()
        },
        methods: {
            changeRoute(queryParams)
            {
                console.log("change route in learningTiles", queryParams);
                this.queryParams = Object.assign({}, queryParams);
                this.fetchLearnings();
            },
            fetchLearnings() {
                // scroll to top of page
                window.scrollTo(0,0);

                let query = Object.assign({}, this.queryParams || this.$route.query);
                 
                console.log("fetchLearnings", { params: query });
                return apiClient.get("learnings", { params: query } )
                    .then(response => {
                        this.learnings = response.data;
                    }).catch((e) => {
                        // eslint-disable-next-line no-console
                        console.log("error get learnings", e);
                        this.$router.push({ path: "/403"});
                    });
            },
            getFilteredLearnings() {

                let query = this.queryParams;

                if (this.category) {
                    query["filter[learning_category]"] = this.category;
                } else {
                    delete query["filter[learning_category]"];
                }
                if (this.type) {
                    query["filter[learning_type]"] = this.type;
                } else {
                    delete query["filter[learning_type]"];
                }
                if ("page" in query) {
                    // reset the page when filtering
                    query["page"] = 1;
                }

                console.log("filter learnings", query);
                this.changeRoute(query);
            }
        },
    };
</script>

<style>

</style>