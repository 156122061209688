<template>
        <div>
            <h2>Role Management</h2>
            <div class="right-col mb-4">
                <tertiary-button
                    v-if="showRoleForm"
                    v-can="'Create roles'"
                    title="Back to overview"
                    @click.prevent="activeRole=null;showRoleForm=false;"
                />
                <secondary-button
                    v-if="!showRoleForm"
                    v-can="'Create roles'"
                    title="Create role"
                    @click.prevent="activeRole=null;showRoleForm=true;"
                />
            </div>
    
            <div
                v-if="!showRoleForm"
                class="info-blocks"
            >
                <table>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th />
                    </tr>
                    <tr
                        v-for="role in roles"
                        :key="role.id"
                    >
                        <td data-hj-suppress>{{ role.id }}</td>
                        <td data-hj-suppress>{{ role.name }}</td>
                        <td data-hj-suppress>{{ role.type?.name }}</td>
                        <td class="p-0 pt-2">
                            <action-button
                                color="secondary"
                                icon="pencil"
                                @click.prevent="editRole(role)"
                            >
                                <span class="icon icon-pencil" />
                            </action-button>
                        </td>
                    </tr>
                </table>
            </div>
            <role-form
                v-if="showRoleForm"
                :role="activeRole"
                @role-updated="activeRole=null;showRoleForm=false;fetchRoles();"
            />
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import RoleForm from "@/Pages/Roles/RoleForm";
    import ActionButton from "@/components/form/button/ActionButton";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import TertiaryButton from "@/components/form/TertiaryButton";
    
    export default {
        components: {
            RoleForm,
            ActionButton,
            SecondaryButton,
            TertiaryButton,
        },
        data() {
            return {
                showRoleForm: false,
                activeRole: null,
                roles: [],
            };
        },
        mounted() {
            this.fetchRoles();
        },
        methods: {
            fetchRoles() {
                // scroll to top of page
                window.scrollTo(0,0);
                
                apiClient.get("/roles")
                    .then(response => {
                        this.roles = response.data.data;
                    });
            },
            editRole(role) {
                return apiClient.get("roles/"+role.id )
                    .then(response => {
                        // scroll to top of page
                        window.scrollTo(0,0);
                        this.showRoleForm = true;
                        this.activeRole = response.data.data;
                    });
            }
        }
    };
</script>