<template>
        <section class="login-main-part">
            <div class="sign-up-part sign-up-done-part login-wrapper">
                <div class="login-area">
                    <div class="login-box">
                        <div class="login-logo">
                            <slot name="logo" />
                        </div>

                        <slot />
                    </div>
                </div>
            </div>
        </section>
</template>
