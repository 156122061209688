<template>
        <div class="sign-up-part login-wrapper">
            <div class="login-area">
                <div class="login-box">
                    <div class="login-form-wrapper">
                        <form
                            class="main-form"
                            @submit.prevent="submit"
                        >
                            <ul class="form-wrapper">
                                <li class="form-row html-row text-center">
                                    <h2>Create password</h2>
                                    <p class="bottom-shape shape-center">
                                        Create a password for your account
                                    </p>
                                </li>
    
                                <li class="form-row tooltip-show">
                                    <div
                                        class="input-grp"
                                        :class="{ 'input-error' : form.errors.password, 'input-filled' : !v$.form.password.$invalid }"
                                    >
                                        <label class="form-label">Password *</label>
                                        <input
                                            data-hj-suppress
                                            id="password"
                                            v-model="form.password"
                                            type="password"
                                            class="form-input"
                                            @focus="showPasswordTooltip = true"
                                            @blur="showPasswordTooltip = false"
                                        >
                                        <span
                                            v-for="error in form.errors.password"
                                            :key="error"
                                            class="error-msg"
                                        >{{ error }}</span>
                                        <div
                                            v-show="showPasswordTooltip"
                                            class="info-tooltip"
                                        >
                                            <ul class="password-hint">
                                                <li :class="{ 'checked': (!v$.form.password.minLength.$invalid && !v$.form.password.required.$invalid) }">
                                                    min. 8 characters
                                                </li>
                                                <li :class="{ 'checked': (!v$.form.password.containsNumber.$invalid) }">
                                                    min. 1  number
                                                </li>
                                                <li :class="{ 'checked': (!v$.form.password.containsUppercase.$invalid) }">
                                                    min. 1 uppercase character
                                                </li>
                                                <li :class="{ 'checked': (!v$.form.password.containsLowercase.$invalid) }">
                                                    min. 1 lowercase character
                                                </li>
                                                <li :class="{ 'checked': (!v$.form.password.containsSpecial.$invalid) }">
                                                    min. 1 special character
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li
                                    class="form-row"
                                    :class="{ 'input-error' : form.errors.password_confirmation, 'input-filled' : !v$.form.password_confirmation.$invalid }"
                                >
                                    <div class="input-grp">
                                        <label class="form-label">Confirm password *</label>
                                        <input
                                            data-hj-suppress
                                            id="password_confirmation"
                                            v-model="form.password_confirmation"
                                            type="password"
                                            class="form-input"
                                        >
                                    </div>
                                </li>
                                <li class="form-row html-row">
                                    <p>
                                        We care about you. And your privacy.<br>
                                        Please read our <router-link
                                            :to="{ name: 'privacy-policy' }"
                                            target="_blank"
                                        >
                                            privacy policy
                                        </router-link> and the <router-link
                                            :to="{ name: 'terms-of-service' }"
                                            target="_blank"
                                        >
                                            terms of service
                                        </router-link>.
                                    </p>
                                </li>
                                <li
                                    class="form-row"
                                    :class="{ 'input-error' : form.errors.terms }"
                                >
                                    <div class="checkbox-grp">
                                        <div class="checkbox-row">
                                            <jet-checkbox
                                                id="terms"
                                                v-model="form.terms"
                                                name="terms"
                                                :checked="form.terms"
                                            />
                                            <jet-label
                                                class="checkbox-label"
                                                for="terms"
                                            >
                                                I agree with the privacy policy and terms of service *
                                            </jet-label>
                                        </div>
                                        <span
                                            v-for="error in form.errors.terms"
                                            :key="error"
                                            class="error-msg"
                                        >{{ error }}</span>
                                    </div>
                                </li>
                                <li class="form-row">
                                    <input
                                        class="form-button"
                                        :disabled="isDisabled"
                                        value="Save password"
                                        type="submit"
                                        :class="{ 'opacity-25': isDisabled }"
                                    >
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
    import AuthService from "@/services/AuthService";
    import JetCheckbox from "@/Jetstream/JetCheckbox";
    import JetLabel from "@/Jetstream/InputLabel";
    import { useVuelidate } from "@vuelidate/core";
    import {
        required,
        minLength,
        email,
    } from "@vuelidate/validators";

    export default {
        components: {
            JetCheckbox,
            JetLabel
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                showPasswordTooltip: false,
                form: {
                    processing: false,
                    errors: {},
                    password: null,
                    password_confirmation: null,
                    terms: false,
                }
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        validations: {
            form: {
                password: { required,
                    valid: function(value) {
                        const containsUppercase = /[A-Z]/.test(value);
                        const containsLowercase = /[a-z]/.test(value);
                        const containsNumber = /[0-9]/.test(value);
                        const containsSpecial = /[#?!@$%^&*-]/.test(value);
                        return containsUppercase && containsLowercase && containsNumber && containsSpecial;
                    },
                    minLength: minLength(8),
                    containsUppercase: function(value) {
                        return /[A-Z]/.test(value);
                    },
                    containsLowercase: function(value) {
                        return /[a-z]/.test(value);
                    },
                    containsNumber: function(value) {
                        return /[0-9]/.test(value);
                    },
                    containsSpecial: function(value) {
                        return /[#?!@$%^&*-]/.test(value);
                    }
                },
                password_confirmation: { required,
                    sameAsPassword: function(value){
                        return this.form.password_confirmation === this.form.password;
                    }
                },
                terms: {
                    checked: value => value === true
                }
            },
        },
        methods: {
            submit() {
                const payload = {
                    secret: this.$route.params.secret,
                    hash: this.$route.params.hash,
                    password: this.form.password,
                    password_confirmation: this.form.password_confirmation,
                    terms: this.form.terms
                };
                if (this.$route.params.secret && this.$route.params.hash) {
                    AuthService.createPassword(payload, this.$route.params, this.$route.query)
                        .then((response) => {
                            // TODO redirect to login page (so 2fa can also be set)
                            //this.$router.push({name: 'home', query: response.data});
                            this.$router.push({name: "login"});
                        })
                        .catch((error) => {
                            this.form.errors = error.response.data.errors;
                        }).finally(() => {
                        this.form.password = "";
                        this.form.password_confirmation = "";
                    });
                } else {
                    // eslint-disable-next-line no-console
                    console.log("invalid params");
                }
            }
        }
    };
</script>