<template>
        <section class="login-main-part">
            <div class="login-wrapper">
                <div class="login-area">
                    <div class="login-box">
                        <div
                            class="confirm-msg"
                            :class="{ 'show' : emailSend && successMessage}"
                        >
                            <i><img
                                src="./../../images/icon-correct.svg"
                                alt="Correct"
                            ></i>{{ successMessage }}
                        </div>
                        <div class="login-logo">
                            <Logo />
                        </div>

                        <div class="login-form-wrapper">
                            <form
                                class="main-form"
                                @submit.prevent="submit"
                            >
                                <ul class="form-wrapper">
                                    <li class="form-row html-row">
                                        <h2>Request new password</h2>
                                    </li>
                                    <li class="form-row">
                                        <div
                                            class="input-grp"
                                            :class="{ 'input-error' : form.errors.email, 'input-filled' : !v$.form.email.$invalid }"
                                        >
                                            <label class="form-label">Email address</label>
                                            <input
                                                data-hj-suppress
                                                id="email"
                                                v-model="form.email"
                                                type="email"
                                                class="form-input"
                                                autofocus
                                            >
                                            <span
                                                v-for="error in form.errors.email"
                                                :key="error"
                                                class="error-msg"
                                            >{{ error }}</span>
                                        </div>
                                    </li>
                                
                                    <li class="form-row">
                                        <input
                                            data-hj-suppress
                                            class="form-button"
                                            value="Reset password"
                                            type="submit"
                                            :disabled="isDisabled"
                                            :class="{ 'opacity-25': isDisabled }"
                                        >
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
    import AuthService from "@/services/AuthService";
    import Logo from "@/components/LogoComponent";
    import { useVuelidate } from "@vuelidate/core";
    import {
        required,
        email,
    } from "@vuelidate/validators";
    
    export default {
        components: {
            Logo
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                emailSend: true,
                successMessage: "",
                form: {
                    processing: false,
                    errors: {},
                    email: ""
                }
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        validations: {
            form: {
                email: {required, email},
            }
        },
        methods: {
            submit() {
                const payload = {
                    email: this.form.email,
                };
              AuthService.forgotPassword(payload)
                .then((response) => {
                    this.emailSend = true;
                    this.successMessage = response.data.message;
                })
                .catch((error) => {
                    this.form.errors = error.response.data.errors;
                }).finally(() => {
                    this.form.email = "";
                });
            }
        }
    };
</script>
