<template>
        <div
            class="certifications-row button-row group"
            :class="$route.params.id && 'pointer-events-none'"
        >
            <div v-if="!showEditCertification">
                <div class="flex items-center">
                    <div class="w-8 h-8 min-w-8 overflow-hidden flex justify-center items-center">
                        <img
                            src="../../images/icon-certification.svg"
                            alt="thumbnail"
                            class="w-7 h-7"
                        >
                    </div>
                    <div class="font-semibold ml-4" data-hj-suppress>
                        {{ certification.name }}
                    </div>
                    <float-right-component>
                        <action-button
                            color="secondary"
                            icon="download"
                            @click="$emit('download', certification.id)"
                        />
                        <action-button
                            color="primary"
                            icon="bin"
                            @click="deleteCertification"
                        />
                        <action-button
                            color="secondary"
                            icon="pencil"
                            @click="showEditCertification = !showEditCertification"
                        />
                    </float-right-component>
                </div>
            </div>

            <certification-form
                v-else
                :user="user"
                :certification="certification"
                @edited-certification="editCertification"
            />
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import CertificationForm from "@/Pages/Profile/CertificationForm";
    import ActionButton from "@/components/form/button/ActionButton";
    import FloatRightComponent from "@/components/FloatRightComponent";

    export default {
        components: {
            CertificationForm,
            ActionButton,
            FloatRightComponent
        },
        props: {
            certification: {
                type: Object,
                default: null
            }
        },
        emits: {
            "updateCertificationList": null,
            download: null,
        },
        data() {
            return {
                showEditCertification: false,
            };
        },
        methods: {
            deleteCertification() {
                if (this.$store.getters.authUser?.id) {
                    apiClient.delete("/files/" + this.certification.id)
                        .then(response => {
                            this.$emit("updateCertificationList");
                        }).catch((error) => {
                    });
                }
            },
            editCertification() {
                this.$emit("updateCertificationList",this.certification.id);
                this.showEditCertification = false;
            },
        }
    };
</script>
