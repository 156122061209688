<template>
        <div class="section-row">
            <h2 class="icon-title float-none">
                <i><img
                    src="../../../images/icon-goal.svg"
                    alt="icon"
                ></i>Goals
            </h2>
            <div class="mb-3">
                <text-field
                    data-hj-suppress
                    v-if="showEditGoal"
                    :value="inputGoal"
                    :disabled="!showEditGoal"
                    @input-text="v => inputGoal = v"
                />
                <p
                    v-else
                    class="w-full text-base p-4 border rounded-md bg-white min-h-40 whitespace-pre-line"
                >
                    {{ inputGoal }}
                </p>
            </div>
            <div v-if="isProfessional">
                <div
                    v-if="showEditGoal"
                    class="w-40"
                >
                    <primary-button
                       
                        title="Save goals"
                        @click="updateGoal"
                    />
                </div>
                <secondary-button
                    v-else
                    title="Edit goals"
                    @click="toggleEditGoal"
                />
            </div>
        </div>
</template>

<script>
import { mapGetters } from "vuex";
    import SecondaryButton from "../../form/SecondaryButton";
    import PrimaryButton from "../../form/PrimaryButton";
    import TextField from "../../form/TextField";

export default {
        components: {
            SecondaryButton,
            PrimaryButton,
            TextField,
        },
        props: {
            showEditGoal: Boolean,
            goal: {
                type: String,
                default: "",
            },
        },
        emits: ["toggleEditGoal", "updateGoal"],
        data() {
            return {
                inputGoal: this.goal,
            };
        },
        computed: {
            ...mapGetters(["isProfessional"])
        },
        watch: { 
            goal(goal) {
                this.inputGoal = goal;
            },
        },
        methods: {
            toggleEditGoal() {
                this.$emit("toggleEditGoal");
            },
            updateGoal() {
                this.$emit("updateGoal", this.inputGoal);
            },
        },
    };
</script>
