<template>
        <div class="data-edit-part">
            <form>
                <ul class="form-wrapper">
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.interests_csv}"
                    >
                        <div class="input-grp">
                            <label class="form-label">Interests (Seperate with comma)</label>
                            <textarea
                                data-hj-suppress
                                v-model="form.interests_csv"
                                class="form-input"
                            />
                            <span
                                v-show="form.errors.interests_csv"
                                class="error-msg"
                            >{{ form.errors.interests_csv }}</span>
                        </div>
                    </li>
                    <li class="form-row">
                        <primary-button
                            title="Save interests"
                            :disabled="isDisabled"
                            @click.prevent="submitForm"
                        />
                    </li>
                </ul>
            </form>
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import { useVuelidate } from "@vuelidate/core";
    import {
        required,
    } from "@vuelidate/validators";

    export default {
        components: {
            PrimaryButton
        },
        props: {
            user: {
                type: Object,
                default: () => {},
            }
        },
        emits: ["interestsEdited"],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                form: {
                    processing: false,
                    errors: {},
                    interests_csv: this.user.interests_csv
                },
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        validations: {
            form: {
                interests_csv: { required }
            }
        },
        methods: {
            submitForm() {
                const payload = {
                    "interests_csv": this.form.interests_csv
                };
                let store = this.$store;
                apiClient.patch("/users/" + this.$store.getters.authUser.id, payload)
                    .then(response => {
                        // eslint-disable-next-line no-console
                        console.log(response);
                        store.dispatch("setAuthUser", { user: response.data.data });
                        this.$emit("interestsEdited");
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                    });
            }
        }
    };
</script>
