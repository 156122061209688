<template>
        <div class="info-blocks">
            <slot name="right" />

            <jet-section-title>
                <template #image>
                    <slot name="image" />
                </template>
                <template #title>
                    <slot name="title" />
                </template>
                <template #description>
                    <slot name="description" />
                </template>
                <template #button>
                    <slot name="button" />
                </template>
            </jet-section-title>

            <div class="data-display-part">
                <form @submit.prevent="$emit('submitted')">
                    <slot name="form" />


                    <div v-if="hasActions">
                        <slot name="actions" />
                    </div>
                </form>
            </div>
        </div>
</template>

<script>
    import JetSectionTitle from "./SectionTitle";

    export default {

        components: {
            JetSectionTitle,
        },
        emits: ["submitted"],

        computed: {
            hasActions() {
                return !! this.$slots.actions;
            }
        }
    };
</script>
