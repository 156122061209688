<template>
        <div class="section-row documents">
            <jet-form-section>
                <template #image>
                    <img
                        src="../../../images/icon-docs.svg"
                        alt="icon"
                    >
                </template>
                <template #title>
                    Documents
                </template>

                <template #form>
                    <div class="col-span-12 sm:col-span-12">
                        <documents-list />
                    </div>
                </template>
            </jet-form-section>
        </div>
</template>

<script>
    import JetFormSection from "@/Jetstream/FormSection";
    import DocumentsList from "./DocumentsList";

export default {
        components: {
            JetFormSection,
            DocumentsList,
        },
    };
</script>
