<template>
        <div class="data-edit-part">
            <form>
                <ul class="form-wrapper">
                    <li class="form-row custom-file-upload" >
                        <span
                            v-if="!user.profile_photo.includes('ui-avatars')"
                            class="delete-btn"
                            @click.prevent="deletePhoto"
                        ><span>Remove</span></span>
                        <div
                            class="profile-area"
                            data-hj-suppress
                            @click.prevent="selectNewPhoto"
                        >
                            <!-- Current Profile Photo -->
                            <img
                                v-show="! photoPreview"
                                class="rounded-full"
                                :src="user.profile_photo"
                                :alt="user.name"
                            >
                            <!-- New Profile Photo Preview -->
                            <span
                                v-show="photoPreview"
                                class="rounded-full photoPreview"
                                :style="'background-image: url(\'' + photoPreview + '\');'"
                            />
                        </div>
                        <div class="input-grp">
                            <label class="form-label">Profile picture</label>
                            <input
                                ref="photo"
                                type="file"
                                class="hidden"
                                @change="updatePhotoPreview"
                            >

                            <div class="file-input">
                                <secondary-button
                                    icon="upload"
                                    title="Upload"
                                    @click.prevent="selectNewPhoto"
                                />
                                <jet-secondary-button
                                    v-if="user.profile_photo_path"
                                    type="button"
                                    hidden
                                    class="mt-2"
                                    @click.prevent="deletePhoto"
                                >
                                    Remove Photo
                                </jet-secondary-button>
                            </div>
                        </div>
                    </li>
                    <li class="form-row two-input">
                        <div
                            class="input-grp"
                            :class="{ 'input-error' : form.errors.first_name, 'input-filled' : !v$.form.first_name.$invalid}"
                        >
                            <label class="form-label">First name</label>
                            <input
                                data-hj-suppress
                                v-model="form.first_name"
                                class="form-input"
                                type="text"
                            >
                            <span
                                v-show="form.errors.first_name"
                                class="error-msg"
                            >{{ form.errors.first_name }}</span>
                        </div>
                        <div
                            class="input-grp"
                            :class="{ 'input-error' : form.errors.last_name, 'input-filled' : !v$.form.last_name.$invalid}"
                        >
                            <label class="form-label">Last name</label>
                            <input
                                data-hj-suppress
                                v-model="form.last_name"
                                class="form-input"
                                type="text"
                            >
                            <span
                                v-show="form.errors.last_name"
                                class="error-msg"
                            >{{ form.errors.last_name }}</span>
                        </div>
                    </li>
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.city, 'input-filled' : !v$.form.city.$invalid}"
                    >
                        <div class="input-grp">
                            <label class="form-label">City of residence</label>
                            <input
                                data-hj-suppress
                                v-model="form.city"
                                class="form-input"
                                type="text"
                            >
                            <span
                                v-show="form.errors.city"
                                class="error-msg"
                            >{{ form.errors.city }}</span>
                        </div>
                    </li>
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.province, 'input-filled' : !v$.form.province.$invalid}"
                    >
                        <div class="input-grp">
                            <label class="form-label">Province</label>
                            <input
                                data-hj-suppress
                                v-model="form.province"
                                class="form-input"
                                type="text"
                            >
                            <span
                                v-show="form.errors.province"
                                class="error-msg"
                            >{{ form.errors.province }}</span>
                        </div>
                    </li>
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.country_id}"
                    >
                        <div class="input-grp">
                            <label class="form-label">Country</label>

                            <select
                                data-hj-suppress
                                id="country_id"
                                v-model="form.country_id"
                                class="form-input"
                                required
                                autocomplete="country"
                            >
                                <option
                                    disabled
                                    value=""
                                >
                                    Please select
                                </option>
                                <option
                                    v-for="country in countries"
                                    :key="country.id"
                                    :value="country.id"
                                >
                                    {{ country.name }}
                                </option>
                            </select>

                            <span
                                v-show="form.errors.country_id"
                                class="error-msg"
                            >{{ form.errors.country_id }}</span>
                        </div>
                    </li>
                    <li
                        v-if="isCompletedCoach()"
                        class="form-row"
                    >
                        <div class="input-grp">
                            <label class="form-label">Date of birth</label>
                            <div class="three-input">
                                <div
                                    class="inner-input"
                                    :class="{ 'input-error' : form.errors.date_of_birth }"
                                >
                                    <label class="form-label">Day</label>
                                    <select
                                        data-hj-suppress
                                        v-model="form.day"
                                        class="form-input"
                                    >
                                        <option value="">
                                            Day
                                        </option>
                                        <option
                                            v-for="day in days"
                                            :key="day"
                                            :value="day"
                                        >
                                            {{ day }}
                                        </option>
                                    </select>
                                    <span class="error-msg">{{ form.errors.date_of_birth }}</span>
                                </div>
                                <div
                                    class="inner-input"
                                    :class="{ 'input-error' : form.errors.date_of_birth }"
                                >
                                    <label class="form-label">Month</label>
                                    <select
                                        data-hj-suppress
                                        v-model="form.month"
                                        class="form-input"
                                    >
                                        <option value="">
                                            Month
                                        </option>
                                        <option
                                            v-for="month in months"
                                            :key="month"
                                            :value="month"
                                        >
                                            {{ month }}
                                        </option>
                                    </select>
                                </div>
                                <div
                                    class="inner-input"
                                    :class="{ 'input-error' : form.errors.date_of_birth }"
                                >
                                    <label class="form-label">Year</label>
                                    <select
                                        data-hj-suppress
                                        v-model="form.year"
                                        class="form-input"
                                    >
                                        <option value="">
                                            Year
                                        </option>
                                        <option
                                            v-for="year in years"
                                            :key="year"
                                            :value="year"
                                        >
                                            {{ year }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="isCompletedCoach()"
                        class="form-row"
                        :class="{ 'input-error' : form.errors.street}"
                    >
                        <div class="input-grp">
                            <label class="form-label">Street</label>
                            <input
                                data-hj-suppress
                                v-model="form.street"
                                class="form-input"
                                type="text"
                            >
                            <span
                                v-show="form.errors.street"
                                class="error-msg"
                            >{{ form.errors.street }}</span>
                        </div>
                    </li>
                    <li
                        v-if="isCompletedCoach()"
                        class="form-row"
                        :class="{ 'input-error' : form.errors.house_number }"
                    >
                        <div class="input-grp">
                            <label class="form-label">House number</label>
                            <input
                                data-hj-suppress
                                v-model="form.house_number"
                                class="form-input"
                                type="text"
                            >
                            <span
                                v-show="form.errors.house_number"
                                class="error-msg"
                            >{{ form.errors.house_number }}</span>
                        </div>
                    </li>
                    <li
                        v-if="isCompletedCoach()"
                        class="form-row"
                    >
                        <div class="input-grp">
                            <label class="form-label">House number addition</label>
                            <input
                                data-hj-suppress
                                v-model="form.house_number_addition"
                                class="form-input"
                                type="text"
                            >
                            <span
                                v-show="form.errors.house_number_addition"
                                class="error-msg"
                            >{{ form.errors.house_number_addition }}</span>
                        </div>
                    </li>
                    <li
                        v-if="isCompletedCoach()"
                        class="form-row"
                        :class="{ 'input-error' : form.errors.postal_code }"
                    >
                        <div class="input-grp">
                            <label class="form-label">Postal code</label>
                            <input
                                data-hj-suppress
                                v-model="form.postal_code"
                                class="form-input"
                                type="text"
                            >
                            <span
                                v-show="form.errors.postal_code"
                                class="error-msg"
                            >{{ form.errors.postal_code }}</span>
                        </div>
                    </li>
                    <li
                        class="form-row"
                        :class="{ 'tooltip-show' : showBiographyTooltip, 'input-error' : form.errors.biography}"
                    >
                        <div class="input-grp">
                            <div>
                                <label class="form-label flex items-center">
                                    About me
                                    <div class="ml-2">
                                        <information-tooltip
                                            title="Tell your coachees who you are!"
                                            content="Write a text about yourself that showcases who you are and what you stand for. Try to aim for 300 words 😊"
                                            arrow="arrow-down"
                                        />
                                    </div>
                                </label>
                            </div>
                            <ckeditor
                                data-hj-suppress
                                v-model="form.biography"
                                :editor="editor"
                                :config="editorConfig"
                            />
                            <span
                                v-show="form.errors.biography"
                                class="error-msg"
                            >{{ form.errors.biography }}</span>
                        </div>
                    </li>
                    <li class="form-row">
                        <primary-button
                            :disabled="isDisabled"
                            title="Save personal information"
                            @click.prevent="submitForm"
                        />
                    </li>
                </ul>
            </form>
        </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import JetSecondaryButton from "@/Jetstream/SecondaryButton";
    import InformationTooltip from "@/components/InformationTooltip";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import { useVuelidate } from "@vuelidate/core";
    import {
        required, requiredIf,
    } from "@vuelidate/validators";

    export default {
        components: {
            JetSecondaryButton,
            InformationTooltip,
            SecondaryButton,
            PrimaryButton,
        },
        props: {
            user: {
                type: Object,
                default: () => {},
            }
        },
        emits: ["userEdited"],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                form: {
                    processing: false,
                    errors: {},
                    first_name: this.user.first_name,
                    last_name: this.user.last_name,
                    city: this.user.city,
                    province: this.user.province,
                    month: this.user.date_of_birth ? +this.user.date_of_birth.split("-")[1] : "",
                    year: this.user.date_of_birth ? +this.user.date_of_birth.split("-")[0] : "",
                    day: this.user.date_of_birth ? +this.user.date_of_birth.split("-")[2] : "",
                    street: this.user.street,
                    house_number: this.user.house_number,
                    house_number_addition: this.user.house_number_addition || "",
                    postal_code: this.user.postal_code,
                    country_id: this.user.country?.id,
                    biography: this.user.biography,
                    photo: null,
                    date_of_birth: this.user.date_of_birth,
                },
                photoPreview: null,
                countries: {},
                showBiographyTooltip: false,
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
                months() {
                return Array.from({length: 12 }, (value, index) => 1 + index);
            },
                days() {
                return Array.from({length: 31 }, (value, index) => 1 + index);
            },
            years() {
                const year = new Date().getFullYear();
                const start_year = year-100;
                return Array.from({length: year - start_year}, (value, index) => start_year + 1 + index);
            },
            ...mapGetters(["authUser", "isCoach"]),
        },
        mounted() {
            this.fetchCountries();
        },
        validations: {
            form: {
                first_name: {required},
                last_name: {required},
                city: {required},
                province: {required},
                country_id: {required},
                street: {required: requiredIf(function () {
                    return this.isCompletedCoach();
                })},
                house_number: {required: requiredIf(function () {
                    return this.isCompletedCoach();
                })},
                postal_code: {required: requiredIf(function () {
                    return this.isCompletedCoach();
                })}
            }
        },
        methods: {
            getFormData() {
                
                let fd = new FormData();
                fd.append("_method", "put");
                fd.append("first_name", this.form.first_name);
                fd.append("last_name", this.form.last_name);
                fd.append("city", this.form.city);
                fd.append("province", this.form.province);
                if(this.isCompletedCoach()){
                    fd.append("date_of_birth", `${this.form.year}-${this.form.month}-${this.form.day}`);
                    fd.append("street", this.form.street);
                    fd.append("house_number", this.form.house_number);
                    fd.append("house_number_addition", this.form.house_number_addition);
                    fd.append("postal_code", this.form.postal_code);
                }
                fd.append("country_id", this.form.country_id);
                fd.append("biography", this.form.biography);
                if (typeof this.form.photo !== "undefined") {
                    fd.append("photo", this.form.photo);
                }

                return fd;
            },
            submitForm() {
                if (this.$refs.photo) {
                    this.form.photo = this.$refs.photo.files[0];
                }

                if(this.isCompletedCoach() && !this.validateDate(+this.form.day, +this.form.month, this.form.year)) {
                    return this.form.errors.date_of_birth = "Please enter your date of birth.";
                }
                
                let store = this.$store;
                let formData = this.getFormData();
                
                apiClient({
                    method: "post",
                    url: "/users/" + this.$store.getters.authUser.id,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                    .then(response => {
                        store.dispatch("setAuthUser", { user: response.data.data });
                        this.$emit("userEdited");
                    }).catch(error => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                });
            },
            fetchCountries() {
                apiClient.get("/countries")
                    .then(response => {
                        this.countries = response.data;
                    });
            },
            selectNewPhoto() {
                this.$refs.photo.click();
            },

            updatePhotoPreview() {
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.photoPreview = e.target.result;
                };

                reader.readAsDataURL(this.$refs.photo.files[0]);
            },
            deletePhoto() {
                let store = this.$store;
                this.form.photo = "";
                let formData = this.getFormData();
                apiClient.post("/users/" + this.$store.getters.authUser.id, formData)
                    .then(response => {
                        store.dispatch("setAuthUser", { user: response.data.data });
                        this.clearPhotoFileInput();
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                    });
            },

            clearPhotoFileInput() {
                this.photoPreview = null;
                if (this.$refs.photo?.value) {
                    this.$refs.photo.value = null;
                }
            },

            validateDate(day, month, year) {
                if(!year) return false;
                if(day === 31) {
                    return [1, 3, 5, 7, 8, 10, 12].includes(month);
                }
                if(month === 2) {
                    return day < 29;
                }
                return true;
            },

            isCompletedCoach() {
                return this.isCoach && this.user.approval_status === 3 && this.user.profile_percentage >= 70;
            }
        }
    };
</script>
