<template>
        <div class="section-row tasks">
            <jet-form-section>
                <template #image>
                    <img
                        src="../../../images/icon-tasks.svg"
                        alt="icon"
                    >
                </template>
                <template #title>
                    Tasks
                </template>

                <template #form>
                    <div class="col-span-12 sm:col-span-12">
                        <tasks-list :open="open" />
                    </div>
                </template>
            </jet-form-section>
        </div>
</template>

<script>
    import JetFormSection from "@/Jetstream/FormSection";
    import TasksList from "./TasksList";

export default {
        components: {
            JetFormSection,
            TasksList,
        },
        props: {
            open: Boolean,
        }
    };
</script>
