<template>
        <div class="md:flex max-w-full">
            <div class="flex flex-col relative mr-10 flex-grow">
                <back-to-nav
                    title="Back to dashboard"
                    to-name="home"
                />
                <page-header
                    v-if="!isCoach"
                    title="My progress"
                />
                <page-header
                    data-hj-suppress
                    v-if="isCoach"
                    :title="trajectory.user ? 'Progress of ' + trajectory.user?.first_name : ''"
                />
                <dates-header
                    data-hj-suppress
                    :start-date="trajectory.start_date"
                    :end-date="trajectory.end_date"
                />
                <goal-section
                    data-hj-suppress
                    :goal="trajectory.goal"
                    :show-edit-goal="showEditGoal"
                    @update-goal="updateGoal"
                    @toggle-edit-goal="toggleEditGoal"
                />
                <tasks-section :trajectory_id="id || authUser?.activeTrajectory.id" />
                <documents-section :trajectory_id="id || authUser?.activeTrajectory.id" />

								<notes-section
									:notes="trajectory.notes"
									@update-notes="updateNotes"
								/>

            </div>
            <user-widget
                v-if="isCoach && trajectory.user"
                :user="trajectory.user"
                button-title="Send message"
                button-icon="chat"
                @button-click="$store.dispatch('setActiveChat', trajectory)"
            />
        </div>
</template>

<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import { mapGetters } from "vuex";
    import BackToNav from "@/components/nav/BackToNav";
    import PageHeader from "@/components/PageHeader";
    import DatesHeader from "@/components/DatesHeader";
    import GoalSection from "@/components/hub/goal/GoalSection";
    import TasksSection from "@/components/hub/task/TasksSection";
    import DocumentsSection from "@/components/hub/document/DocumentsSection";
		import NotesSection from "@/components/hub/notes/NotesSection";
    import UserWidget from "@/components/cards/UserWidget";

    export default {
        components: {
            BackToNav,
            PageHeader,
            DatesHeader,
            GoalSection,
            TasksSection,
            DocumentsSection,
						NotesSection,
            UserWidget,
        },
        props: {
            id: {
                type: Number,
                default: 0,
            }
        },
        data() {
            return {
                trajectory: {},
                showEditGoal: false,
            };
        },
        computed: {
            ...mapGetters(["authUser", "isCoach"]),
        },
        mounted() {
            this.fetchTrajectory();
        },
        methods: {
            fetchTrajectory() {
                const trajectoryId = this.id || this.authUser?.activeTrajectory.id;

                return apiClient.get(`/trajectories/${trajectoryId}`)
                    .then(response => {
                        this.trajectory = response.data.data;
                        this.goal = this.trajectory.goal || "";
                    });
            },
            toggleEditGoal() {
                this.showEditGoal = !this.showEditGoal;
            },
            updateGoal(goal) {
                const trajectoryId = this.id || this.authUser?.activeTrajectory.id;
                apiClient.patch(`/trajectories/${trajectoryId}`, { goal })
                    .then(response => {
                        this.toggleEditGoal();
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                    });
            },
						updateNotes(notes) {
							const trajectoryId = this.id || this.authUser?.activeTrajectory.id;
							apiClient.patch(`/trajectories/${trajectoryId}`, { notes })
								.then(response => {
									console.log(response);
								})
								.catch(error => {
									// eslint-disable-next-line no-console
									console.log(error);
								});
						}
        },
    };
</script>
