<template>
        <div class="">
            <div class="section-row">
                <div class="custom-row">
                    <email-verified v-if="$route.query.verified" />

                    <div
                        v-if="authUser && authUser.profile_percentage !== 100"
                        class="custom-col profile-card" data-hj-suppress
                    >
                        <div

                            class="common-card flex flex-col"
                        >
                            <div class="profile-card-title">
                                <div
                                    v-can="'View progress bar'"
                                    :class="['progress','progress-large','progress-'+(Math.round(authUser.profile_percentage / 10) * 10)]"
                                >
                                    <div class="progress-value">
                                        {{ authUser.profile_percentage }}%
                                    </div>
                                </div>
                                <div class="profile-card-msg">
                                    <h4 v-if="theme === 'youngheroes'">
                                        Hey {{ authUser.first_name }},<br>
                                        welcome to the Young Heroes community! <span class="icon-emoji">🥳</span>
                                    </h4>
                                    <h4 v-if="theme === 'yfk'">
                                        Hey {{ authUser.first_name }},<br>
                                        welcome to the YFK community! <span class="icon-emoji">🥳</span>
                                    </h4>
                                    <p v-can="'View progress bar'">
                                        Your profile is now on {{ authUser.profile_percentage }}%.
                                    </p>
                                    <div v-if="isCoach">
                                        <div
                                            v-if="authUser.approval_status === 3"
                                            v-can="'View progress bar'"
                                        >
                                            <p
                                                v-if="authUser.profile_percentage === 100"
                                                class="inline-block py-2 px-3 bg-success rounded-md"
                                            >
                                                Fantastic! All done. You are all set!
                                            </p>
                                            <p
                                                v-else
                                                class="inline-block py-2 px-3 bg-warning rounded-md"
                                            >
                                                Please make sure to add all missing details to your 
                                                <router-link :to="{ name: 'profile' }">
                                                    <span class="font-semibold hover:underline">profile</span>
                                                </router-link>.
                                            </p>
                                        </div>
                                        <div
                                            v-else
                                            v-can="'View progress bar'"
                                        >
                                            <p
                                                v-if="authUser.profile_percentage === 70"
                                                class="inline-block py-2 px-3 bg-success rounded-md"
                                            >
                                                Fantastic! All done. The Young Heroes team will be in touch shortly to discuss the next steps.
                                            </p>
                                            <p
                                                v-else
                                                class="inline-block py-2 px-3 bg-warning rounded-md"
                                            >
                                                Please make sure to add all details to your
                                                <router-link :to="{ name: 'profile' }">
                                                    <span class="font-semibold hover:underline">profile</span>
                                                </router-link>.
                                                Upon completing all fields, the Young Heroes team will be notified and get in touch with you.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-auto">
                                <secondary-button
                                    title="Edit profile"
                                    icon="pencil"
                                    @click="() => $router.push({ name: 'profile' })"
                                />
                            </div>
                        <!-- <p class="bottom-text">Are you going to be the next superhero? <a href="javascript:void(0)">read more</a></p> -->
                        </div>
                    </div>

                    <div
                        v-if="theme === 'youngheroes' && authUser && !(isProfessional && authUser.profile_percentage === 100)"
                        v-can="'View video'"
                        class="custom-col video-card"
                    >
                        <div class="common-card">
                            <div class="video-wrapper">
                                <iframe
                                    width="442"
                                    height="285"
                                    src="https://www.youtube-nocookie.com/embed/ps3OAvAmzvM?controls=0"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                />
                            </div>
                            <p>Check in this video how the platform works</p>
                        </div>
                    </div>

                <!--<div class="custom-col event-card">
                    <div class="common-card">
                        <h4><i><img src="@/images/icon-calender.svg" alt="icon"></i> Upcoming events</h4>
                        <div class="event-list">
                            <div class="event-title">
                                <span>14 mei 2021</span>
                                <h5>Borrel XL Leeuwarden</h5>
                            </div>
                            <div class="no-event event-title">
                                <span>No events yet</span>
                            </div>
                        </div>
                    </div>
                </div>-->
                </div>
            </div>

            <!-- My heroes -->
            <div
                v-if="isCoach"
                class="section-row"
            >
                <div class="title-bar">
                    <h2 class="icon-title">
                        <i><img
                            src="@/images/icon-hero.svg"
                            alt="icon"
                        ></i>My Heroes
                    </h2>
                </div>
                <div class="hero-main-list">
                    <hero-cards />
                </div>
            </div>

            <!-- Coach Section -->
            <div
                v-if="isProfessional && !authUser.activeTrajectory"
                class="section-row"
            >
                <div class="title-bar">
                    <h2 class="icon-title">
                        <i><img
                            src="@/images/icon-coach.svg"
                            alt="icon"
                        ></i>Coaches
                    </h2>
                    <router-link
                        :to="{ name: 'coaches' }"
                        class="text-link icon-link"
                    >
                        <i><img
                            src="@/images/icon-box.svg"
                            alt="icon"
                        ></i><span>Show all coaches</span>
                    </router-link>
                </div>
                <div class="coach-main-list">
                    <div class="coach-row">
                        <div class="coach-col">
                            <div class="coach-box">
                                <router-link
                                    :to="{ name: 'coaches' }"
                                    class="link-cover"
                                />
                                <div class="coach-image">
                                    <span><img
                                        src="@/images/people/rene.png"
                                        alt="image"
                                    ></span>
                                </div>
                                <div class="coach-info-box">
                                    <h3 class="bottom-shape shape-center">
                                        René
                                    </h3>
                                    <h4>MARKETING & CHANGE MANAGEMENT, DIGITAL TRANSFORMATION</h4>
                                    <p>René is strategic, conceptual and appreciates a good read. He knows how to build your personal brand and helps teams to use their strengths.</p>
                                </div>
                            </div>
                        </div>
                        <div class="coach-col">
                            <div class="coach-box">
                                <router-link
                                    :to="{ name: 'coaches' }"
                                    class="link-cover"
                                />
                                <div class="coach-image">
                                    <span><img
                                        src="@/images/people/wiebe.png"
                                        alt="image"
                                    ></span>
                                </div>
                                <div class="coach-info-box">
                                    <h3 class="bottom-shape shape-center">
                                        Wiebe
                                    </h3>
                                    <h4>CHANGE MANAGEMENT</h4>
                                    <p>Wiebe is a networker at heart. He is proactive and loves the challenge of working in interdisciplinary teams, enabling you to drive your team forward.</p>
                                </div>
                            </div>
                        </div>
                        <div class="coach-col">
                            <div class="coach-box">
                                <router-link
                                    :to="{ name: 'coaches' }"
                                    class="link-cover"
                                />
                                <div class="coach-image">
                                    <span><img
                                        src="@/images/people/gerda.png"
                                        alt="image"
                                    ></span>
                                </div>
                                <div class="coach-info-box">
                                    <h3 class="bottom-shape shape-center">
                                        Gerda
                                    </h3>
                                    <h4>MARKETING & COMMUNICATION</h4>
                                    <p>Gerda is a communications force. She is entrepreneurial, innovative and loves to empower you through building your self-awareness and confidence.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Tasks section -->
            <tasks-section
                v-if="isProfessional && authUser.activeTrajectory"
                open
            />

            <!-- Stay Connected section -->
            <!-- <div class="section-row">
                <div
                    v-can="'View poll'"
                    class="opinion-section"
                >
                    <dashboard-poll />
                </div>
            </div> -->

            <!-- Part 2 Stay Connected section
        <div class="section-row">
            <div class="insta-section">
                <div class="title-bar">
                    <h2 class="icon-title"><i><img src="@/images/icon-insta-dark.svg" alt="icon"></i>Stay Connected</h2>
                    <a href="javascript:void(0)" class="text-link"><span>Follow us @youngheroes</span></a>
                </div>
                <div class="insta-card-list">
                    <div class="insta-card">
                        <span><img src="@/images/card-insta-img.jpg" alt="image"></span>
                        <p>Workday start with sports and fruits<br>#lookatme #healthybabe</p>
                    </div>
                    <div class="insta-card">
                        <span><img src="@/images/card-insta-img.jpg" alt="image"></span>
                        <p>Workday start with sports and fruits<br>#lookatme #healthybabe</p>
                    </div>
                </div>
            </div>
            <div class="opinion-section">
                <div class="title-bar">
                    <h2 class="icon-title"><i><img src="@/images/icon-bar.svg" alt="icon"></i>Your opinion please</h2>
                </div>
                <div class="opinion-card common-card">
                    <h3>What do you think about ?</h3>
                    <div class="vote-progress">
                        <div class="vote-progress-row">
                            <p><strong>45%</strong> Wow exciting</p>
                            <div class="progress-line"><span style="width:45%;" class="progress-inner-bar"></span></div>
                        </div>
                        <div class="vote-progress-row">
                            <p><strong>24%</strong> Amazing</p>
                            <div class="progress-line"><span style="width:24%;" class="progress-inner-bar"></span></div>
                        </div>
                        <div class="vote-progress-row">
                            <p><strong>31%</strong> Boring</p>
                            <div class="progress-line"><span style="width:31%;" class="progress-inner-bar"></span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        -->
        </div>
</template>

<script>
    import EmailVerified from "@/components/EmailVerified";
    import TasksSection from "@/components/hub/task/TasksSection";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import DashboardPoll from "@/Pages/DashboardPoll";
    import HeroCards from "@/Pages/HeroCards";
    import { mapGetters } from "vuex";

export default {
        displayName: "Dashboard",
        components: {
            DashboardPoll,
            EmailVerified,
            TasksSection,
            HeroCards,
            SecondaryButton
        },
        computed: {
            ...mapGetters(["authUser", "theme", "isCoach", "isProfessional"]),
        },
    };
</script>
