<template>
        <div class="pt-4 container">
            <div class="common-card">
                <h2>
                    Terms of Service
                </h2>
                <p><strong>GENERAL TERMS AND CONDITIONS ‘YOUNG HEROES’</strong></p>

                <p>These general terms and conditions (version 1 November 2021) are applicable to all offers and agreements with regard to the services of Young Heroes Europe B.V., registered with the Chamber of Commerce under number 82820171, located at the address Lange Marktstraat 1015 in (8911 AD) Leeuwarden (hereinafter: “<strong>Supplier</strong>”).&nbsp;</p>
                <p><strong>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; General</strong></p>
                <ol><li>Supplier provides Services on the basis of an assignment that consists of recruiting and selecting graduates and young professionals, linking these talents to Customers and Coaches. The Supplier does this partly by providing access to an internet platform (hereinafter: “Platform”).</li><li>The following capitalized terms have the following meaning in the terms and conditions and in the Agreement:</li></ol><ul><li><strong>Agreement</strong>: the agreement between the Customer and the Supplier to provide one or more Services.</li><li><strong>Coach</strong>: someone who is assigned to a Young Hero by Supplier in order to coach, train and otherwise assist the Young Hero both professionally and personally.</li><li><strong>Customer</strong>: the contractual counterparty of the Supplier, who purchases Services from the Supplier.</li><li><strong>Platform</strong>: the ‘Young Heroes’ web application through which Coaches establish contact with a Young Hero and a Young Hero can find and apply for a vacancy.</li><li><strong>Services</strong>: any service that the Supplier provides to the Customer, among which the making available of the Platform.</li><li><strong>Written&nbsp;</strong>or <strong>in Writing</strong>: this term also includes e-mail, unless expressly provided otherwise.</li><li><strong>Young Hero</strong>: a graduate or young professional who is looking for a job and/or for coaching and who is sometimes made available by a Customer.</li></ul><p>1.3 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Supplier reserves the right to change or supplement these general terms and conditions. Continued use of the Platform by the Customer, Young Hero and Coach after the general terms and conditions have been amended, shall be deemed to be acceptance of the new general terms and conditions. The parties can only deviate from these general terms and conditions in Writing.</p>
                <p>1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; These general terms and conditions also apply to the quotations of the Supplier. Any conditions of the Customer do not apply and are expressly rejected by the Supplier.</p>
                <p>&nbsp;</p>
                <p><strong>2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Services</strong></p>
                <p>2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Supplier will make best efforts to perform its Services with care. This is always done on the basis of a best efforts obligation. Because the Supplier’s Services depend to a large extent on the autonomous behaviour of people, the operation of networks and equipment, and other matters beyond its control, the Supplier can never guarantee an error-free delivery of its Services without any interruption. Only insofar as parts of this Agreement are linked in Writing to an expressly promised and sufficiently determinable and concrete result, only these specific parts apply as an obligation of result.</p>
                <p>2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;The Customer shall provide the Supplier with all the required information on a good time and shall also provide the Supplier with all assistance that can reasonably be required to enable the Supplier to fulfil the Service and/or the Agreement properly, in observance of the requirements of best practices and the applicable laws and regulations.</p>
                <p>2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information provided by the Supplier in relation to the Services, other than in an order (confirmation), other agreement of invoice, such as informative presentations, reports, suggestions and tips, are intended solely for the purpose of informing the Customer. No rights may be derived from this, other than those provided for in these terms and conditions.</p>
                <p>2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;The Services are offered for the duration specified in the service agreement or, failing that, until completion of the relevant Services or for an indefinite period of time if no completion can take place.</p>
                <p>&nbsp;</p>
                <p><strong>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Offers</strong></p>
                <p>3.1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All quotations from the Supplier are without obligation and indicative, unless expressly agreed otherwise in Writing.</p>
                <p>3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer guarantees the correctness and completeness of the data on the basis of which the Supplier has issued the quotation.</p>
                <p>3.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All offers or quotations with regard to the Services of the Supplier are without obligation and revocable until the moment that the Supplier confirms electronically or in Writing that the Agreement with the Customer has been concluded or has commenced the execution of the Agreement.</p>
                <p>&nbsp;</p>
                <p><strong>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Platform</strong></p>
                <p>4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Supplier will grant the Young Hero and the Coach access to the Platform by providing them with an account. The suppliance by Supplier of the Platform will be carried out on the basis of Software-as-a-Service (SaaS). The Platform serves as a meeting place for Young Heroes and Coaches. The Supplier can, but is not obliged to, grant the Customer access to the Platform.</p>
                <p>4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access to the Platform is strictly personal. The user of the Platform is not allowed to grand third party’s access to its account. The user shall keep its login credentials confidential at all times.</p>
                <p>4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The user of the Platform is responsible for making regular back-ups and for adequate information security of its data. The Supplier is in no way liable for costs or (reproduction of) lost or corrupted data, nor for any resulting damage or lost profits. The Supplier makes every effort to protect the data as much as reasonably possible against loss, theft, unauthorized access and alteration by third parties when using the Services. The Supplier is also not responsible for the content and correctness of the data that the user adds to the Platform.</p>
                <p>4.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The user of the Platform shall in no way cause hindrance or damage to the Supplier or its users when using the Platform. The user must refrain from performing actions that can be assumed to cause damage to the Platform. The Supplier has the right at all times, without stating reasons, to suspend, limit or block the user’s access to the Services and/or the Platform for an indefinite period, if there is a suspicion of misuse or other improper use, without that this leads to any claim for compensation.</p>
                <p>4.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Supplier can temporarily shut down its Platform in whole or in part for preventive, corrective or adaptive maintenance or other forms of service without being liable for compensation as a result. The Supplier will not allow the period of inactivity to last longer than necessary and it will take this period place outside of office hours as much as possible.</p>
                <p>4.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The user of the Platform is responsible for keeping the login details of itself and its employees regarding the Platform confidential from authorized persons.&nbsp;</p>
                <p>4.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Supplier is at all times authorized to change or temporarily block the user’s access or identification codes from the Platform in case of suspected abuse.</p>
                <p>4.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Supplier is entitled to conclude terms of services with end users of its Platform.</p>
                <p>&nbsp;</p>
                <p><strong>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Recruitment, selection and placement of Young Heroes</strong></p>
                <p>5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If the Services of Supplier entail that Supplier does the recruitment, selection and placement of Young Heroes, this process shall be performed by the Supplier in consultation with the Customer. The aim is to find a suitable Young Hero for a particular job position and/or to coach a Young Hero. If the Customer is looking for Young Hero, the Supplier shall propose a Young Hero that it deems suitable to the Customer. The Customer has the right to reject a Young Hero, in which case the Customer will state the reasons for rejection.</p>
                <p>5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer shall provide the Supplier with all relevant information in order for the Supplier to fulfil its Services properly, such as a job description, the required qualifications, the working times and location(s), an indication of the salary and other relevant information regarding the job position.</p>
                <p>5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Supplier shall make best efforts to propose a suitable Young Hero within a reasonable amount of time. If the Supplier fails to find a suitable Young Hero within a reasonable amount of time, it shall not be liable for any damages or costs.</p>
                <p>5.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Once the Customer accepts a Young Hero proposed by the Supplier, the Supplier can match the Young Hero with a Coach depending on the service Agreement.&nbsp;</p>
                <p>5.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Supplier has the right at any times to substitute Coaches, for example in case of a mismatch between the Young Hero and the Coach.</p>
                <p>&nbsp;</p>
                <p><strong>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Compensation and payment &nbsp;</strong></p>
                <p>6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All prices are in the currency indicated and exclusive of VAT and other government-imposed levies, unless expressly stated otherwise. The Supplier does not accept payments in currencies other than that indicated. In the absence of a specified currency, all prices are in Euros.</p>
                <p>6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Payment of the invoice must be made within fourteen (14) days after the invoice date. If the payment term is exceeded, the Customer is in default without any reminder or notice of default and the Supplier is entitles to statutory commercial interest. Of, after a Written reminder, the Customer has not paid the amounts due and interest owed within three (3) working days, then the Supplier is entitled to suspend its Services and to deny the Customer access to the Platform until the moment of full payment and expressly without the Supplier being liable for any damage resulting therefrom.&nbsp;</p>
                <p>6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer is not entitled to suspend any payment, nor to set off any amounts owed. The compensation is also due if the Customer does not use the Services.</p>
                <p>6.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Supplier is entitled to raise its fees annually. A fee raise shall be announced at least two (2) month in advance through personal notification or through the Supplier’s website.</p>
                <p>6.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Supplier is entitled to sell, transfer or pledge its claims for payment of fees to a third party.</p>
                <p>&nbsp;</p>
                <p><strong>7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Duration and termination of the Agreement</strong></p>
                <p>7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Agreement is entered into for a period of twelve (12) months, unless the parties expressly agreed otherwise in Writing.</p>
                <p>7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Each of the parties is entitled to terminate this Agreement (in Dutch: “opzeggen”) by the end of the term with due observance of a notice period of one (1) month. If the parties do not terminate the agreement in due time, the agreement shall be prolonged for the same term as it was originally entered into. Interim termination is not possible. Termination must take place in Written.&nbsp;</p>
                <p>7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Both parties may terminate (in Dutch: “ontbinden” within the meaning of Article 6:265 Dutch Civil Code) the Agreement, in whole or in part, by registered letter with immediate effect in the following exceptional cases:</p>
                <p>a. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the other party has been declared bankrupt, has been granted a suspension of payments, whether or not provisionally, another similar arrangement has become applicable to the other party or the other party otherwise has fully or partially lost its ability to dispose freely of its assets, regardless of whether that condition is irrevocable;&nbsp;</p>
                <p>b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the other party has offered its creditors a composition outside bankruptcy, suspension of payments or another similar arrangement; or</p>
                <p>c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the decisive control over a party’s business is directly or indirectly changed.&nbsp;</p>
                <p>7.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Due to termination of the Agreement, the Supplier is never obliged to refund fees already received or to compensate any damage.</p>
                <p>&nbsp;</p>
                <p><strong>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Intellectual property</strong></p>
                <p>8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any copyrights and other intellectual property rights on offers, quotations, documentation, designs, concepts, the Platform with all its contents and the Services provided to the Customer, the Young Hero and/or the Coach, including any data and/or software supplied to them, remain at all times with the Supplier or its licensors. The Customer, Young Heroes and Coaches are not entitled, except with the prior Written permission by the Supplier, to reproduce or disclose any of the aforementioned materials, other than that is implied in the Agreement. The Customer’s, Young Heroes’ and Coaches’ license regarding the Platform and the Services is non-exclusive, non-transferable and non-sublicensable. The Customer, Young Hero and Coach remain entitled to the intellectual property with regard to the data that they enter into the Platform.</p>
                <p>8.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer, Young Hero and Coach shall not remove any names, logos or other references to the Supplier from the Platform, unless expressly agreed otherwise in Writing.</p>
                <p>8.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For portfolio purposes, the Supplier has the right to make use of the Customer’s tradenames and trademarks, whether registered or not, free of charge.</p>
                <p>&nbsp;</p>
                <p><strong>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Privacy</strong></p>
                <p>9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Personal data concerning Young Heroes and Coaches are exchanged on a regular basis. The Customer and the Supplier are required to protect the confidentiality of these data in compliance with the General Data Protection Regulation (GDPR) and related law and regulations. Both the Supplier and Young Heroes qualify as controller unless the parties have agreed expressly that one of the parties applies as the processor towards the other party. The parties will conclude a data processing agreement if this is the case. The Customer shall not require any data from the Supplier that the Supplier is not permitted to provide pursuant to the applicable laws and regulations. The Customer is responsible for the further processing of the data provided to it by the Supplier.</p>
                <p>9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer is responsible for ensuring that personal data are provided to the Supplier only if and in as far as the Customer has the right to do so and has a legally valid basis for doing so, such as the required consent from the persons in question.</p>
                <p>9.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer indemnifies the Supplier against all claims from Young Heroes, Coaches, employees of the Customer or other third parties in respect of the Supplier in connection with a violation of the provisions of this Article by the Customer and will reimburse the related costs incurred by the Supplier.</p>
                <p>&nbsp;</p>
                <p><strong>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Liability</strong></p>
                <p>10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If the Supplier should be liable, this liability is limited to what is regulated in this provision.</p>
                <p>10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Supplier’s liability for direct damage as a result of an attributable shortcoming in the fulfilment by the Supplier of its obligations arising from the Agreement, expressly including any unlawful act by (personnel of) the Supplier or third parties engaged by it, is in any event per event or a series of related events limited to the amount that the Customer owes the Supplier on an annual basis. Under no circumstances, however, will the Supplier’s liability for direct damage exceed the amount that the Supplier’s insurer is prepared to pay out in a particular base, plus the amount of the excess which under the policy conditions is not borne by the insurer.</p>
                <p>10.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All liability for indirect damage, including consequential damage, lost profits, lost savings, claims from third parties and business interruption is excluded.</p>
                <p>10.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The liability limitations and exclusions included in these general terms and conditions also apply to personnel of the Supplier and any third parties engaged by it, but will lapse if and insofar as the damage caused is the result of intent or wilful recklessness on the part of the Supplier’s management.</p>
                <p>10.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Conditions for the occurrence of any liability are always that both the Supplier is in default and that the Customer has reported the damage in Writing to the Supplier as soon as possible, but at the latest within one (1) year after it has arisen. The basic principle is always that the Supplier is given a reasonable term by means of a detailed notice of default to enable correct performance.</p>
                <p>&nbsp;</p>
                <p><strong>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Force majeure</strong></p>
                <p>11.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Neither party is obliged to fulfil any obligation under this Agreement if force majeure prevents that party from doing so. Force majeure on the part of the Supplier includes, among other things: a) force majeure of suppliers, b) shortcomings in the fulfilment of suppliers, c) government measures, d) failures of power supply or of the internet or other communications e) natural disasters or f) war.</p>
                <p>11.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Either party may terminate this agreement in Writing of the force majeure event lasts longer than sixty (60) days without being obliged to pay any compensation. What has already been delivered under this Agreement shall be settled proportionally.</p>
                <p>&nbsp;</p>
                <p><strong>12. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Confidentiality</strong></p>
                <p>12.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Both parties are obliged to maintain the confidentiality of all confidential information that they have obtained from each other or from another source in the context of the Agreement. Information is either considered confidential if this has been communicated by the other party or if this results from the nature of the information.</p>
                <p>12.2 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Supplier shall impose a general confidentiality obligation on the Young Heroes and/or the Coaches.</p>
                <p>12.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If, on the basis of a statutory provision or a court decision, the Supplier is obliged by law or the competent court, and the Supplier cannot rely on a legal or competent court recognised or permitted right of non-disclosure, the Supplier is not obliged to pay compensation or indemnification and the Customer is not entitled to terminate the Agreement on the basis of any damage caused by this.</p>
                <p>&nbsp;</p>
                <p><strong>13. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Other provisions&nbsp;</strong></p>
                <p>13.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;If any provision of these terms and conditions cannot be relied upon or this provision proves to be void, voidable or otherwise invalid for any reason, this provision will be deemed to have been adjusted, without any further action by the parties being required, in a way that corresponds as much as possible regarding its content and purport with the original purpose and the original content, and renders the provision valid and enables it to be relied upon.&nbsp;</p>
                <p>13.2 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Agreement and these terms and conditions shall be governed exclusively by Dutch law.</p>
                <p>13.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Disputes arising as a result of the Agreement concluded between the parties and/or as a result of further Agreements resulting therefrom shall be settled by the competent court in the district in which the Supplier is established.</p>
                <p>&nbsp;</p>
            </div>
        </div>
</template>

<script>

</script>
