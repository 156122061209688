import { createWebHistory, createRouter } from "vue-router";
import * as Vue from "vue";
import store from "@/store";
import { computed } from "@vue/runtime-core";
import auth from "@/middleware/auth";
import verified from "@/middleware/verified";
import admin from "@/middleware/admin";
import coach from "@/middleware/coach";
import middlewarePipeline from "@/router/middlewarePipeline";

// 1. Define route components.
import Dashboard from "@/Pages/DashboardMain";
import Login from "@/Pages/Auth/LoginUser";
import Register from "@/Pages/Auth/RegisterUser";
import ForgotPassword from "@/Pages/Auth/ForgotPassword";
import CreatePassword from "@/Pages/Auth/CreatePassword";
import ResetPassword from "@/Pages/Auth/ResetPassword";

import Profile from "@/Pages/Profile/ShowProfile";
import ProfileInit from "@/Pages/Profile/InitProfile";

import Account from "@/Pages/Account/ShowAccount";

import Info from "@/Pages/InfoPage";
import Hub from "@/Pages/HubPage";
import Coaches from "@/Pages/CoachesRow";
import Learnings from "@/Pages/LearningsPage";
import LearningsCatalogue from "@/Pages/Learnings/LearningsCatalogue";
import LearningDetails from "@/Pages/Learnings/LearningDetails";
import ManageLearning from "@/Pages/Learnings/ManageLearning";
import TermsOfService from "@/Pages/TermsOfService";
import PrivacyPolicy from "@/Pages/PrivacyPolicy";
import VerifyEmail from "@/Pages/Auth/VerifyEmail";

import Users from "@/Pages/Users/UsersIndex";
import UsersInvite from "@/Pages/Users/UserInvite";
import {default as UserForm } from "@/Pages/Users/UserForm";
import Roles from "@/Pages/Roles/RolesIndex";
import Vacancies from "@/Pages/VacanciesPage";
import Vacancy from "@/Pages/VacancyPage";


// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [

    // conditional homepage. go to profile init page or to the dashboard
    { name: "home", path: "/", component: Dashboard , beforeEnter: (to, from) => {
            let authUser = store.getters["authUser"];
            let roleType = store.getters["roleType"];
            if (authUser && roleType === "professional" && authUser.profile_percentage < 10) {
                router.push({name: "profile-init", query: to.query });
            }
            return true;
        }, meta: { middleware: [auth, verified], title: "My Dashboard", gtm: "Dashboard" }},
    
    { name: "login", path: "/login", component: Login, beforeEnter: (to, from) => {
            let authUser = store.getters["authUser"];
            if (authUser) {
                router.push({name: "home"});
            }
    }, meta: {title: "Login", gtm: "Login"} },
    
    { name: "register", path: "/register", component: Register, beforeEnter: (to, from) => {
            let authUser = store.getters["authUser"];
            if (authUser) {
                router.push({name: "home"});
            }
        }, meta: {title: "Register", gtm: "Register"} },
    
    { name: "forgot-password", path: "/forgot-password", component: ForgotPassword, beforeEnter: (to, from) => {
            let authUser = store.getters["authUser"];
            if (authUser) {
                router.push({name: "home"});
            }
        }, meta: {title: "Forgot password", gtm: "Forgot password"} },
    
    { name: "create-password", path: "/create-password/:secret?/:hash?", component: CreatePassword, meta: {title: "Reset password"} },
    { name: "reset-password", path: "/reset-password", component: ResetPassword, meta: {title: "Reset password", gtm: "Reset password"} },
    { name: "verify-email", path: "/verify-email", component: VerifyEmail, meta: { middleware: [auth], title: "Verify email", gtm: "Verify email"} },
    { name: "verify-email-check", path: "/verify-email/:id/:hash", component: VerifyEmail, meta: { middleware: [auth], title: "Verify email" } },

    { name: "terms-of-service", path: "/terms-of-service", component: TermsOfService, meta: {title: "Terms of Service", gtm: "Terms of Service"} },
    { name: "privacy-policy", path: "/privacy-policy", component: PrivacyPolicy, meta: {title: "Privacy Policy", gtm: "Privacy Policy"} },
    
    { name: "profile", path: "/profile", component: Profile, meta: { middleware: [auth, verified], title: "My profile", gtm: "My profile" }},
    { name: "profile-coach", path: "/profile/:id", component: Profile, meta: { middleware: [auth, verified], title: "Coach profile", gtm: "Coach profile" }},
    { name: "profile-init", path: "/profile/init", component: ProfileInit, meta: { middleware: [auth, verified], title: "Build profile", gtm: "Build profile" }},
    { name: "account", path: "/account", component: Account, meta: { middleware: [auth, verified], title: "My account", gtm: "My account" }},

    { name: "info", path: "/info", component: Info, meta: { middleware: [auth, verified], title: "Info", gtm: "Info" } },
    { name: "hub", path: "/hub", component: Hub, meta: { middleware: [auth, verified], title: "My Hub", gtm: "My Hub" } },
    { name: "hub-coach", path: "/hub/:id", component: Hub,  props: true, meta: { middleware: [auth, verified], title: "Coach Hub", gtm: "Coach Hub" } },
    { name: "coaches", path: "/coaches", component: Coaches, meta: { middleware: [auth, verified], title: "Coaches", gtm: "Coaches" }},
    { name: "learnings", path: "/learnings", component: Learnings, meta: { middleware: [auth, verified], permissions:"View My Learnings", title: "My Learnings", gtm: "My Learnings" } },
    { name: "learning-details", path: "/learning/:id", component: LearningDetails, meta: { middleware: [auth, verified], title: "My Learning details", gtm: "My Learning details" } },
    { name: "learning", path: "/learning", component: LearningsCatalogue, meta: { middleware: [auth, verified], title: "My Learnings", gtm: "My Learnings" } },
    { name: "add-learning", path: "/addlearning", component: ManageLearning, meta: { middleware: [auth, verified], title: "Add Learning", gtm: "Add Learning" } },
    { name: "edit-learning", path: "/learning/:id/edit", props: true, component: ManageLearning, meta: { middleware: [auth, verified], title: "Edit Learning", gtm: "Edit Learning" } },
    
    // admin pages
    { name:  "users", path: "/users", component: Users, meta: { middleware: [auth, verified], permissions: "Edit users" } },
    { name:  "users-edit", path: "/users/:id/edit", component: UserForm, props: true, meta: { middleware: [auth, verified], permissions: "Edit users" } },
    { name:  "view-profile", path: "/profile/:id", component: Profile, props: true, meta: { middleware: [auth, verified]} },
    { name:  "users-invite", path: "/users/invite", component: UsersInvite, meta: { middleware: [auth, verified], permissions: "Invite professional|Invite coach|Invite company user" } },

    { name:  "roles", path: "/roles", component: Roles, meta: { middleware: [auth, verified], permissions: "Edit roles" } },
    
    { name:  "vacancies", path: "/vacancies", component: Vacancies, meta: { middleware: [auth, verified], permissions:"Add vacancies", title: "Vacancies", gtm: "Vacancies" } },
    { name:  "vacancy", path: "/vacancies/:vacancy", component: Vacancy, meta: { middleware: [auth, verified], permissions:"View vacancies" } },

    { name: "notAllowed", path: "/403", component: () => import("@/Pages/NotAllowed") },
    {
        name: "notFound",
        path: "/:catchAll(.*)",
        component: () =>
            import(/* webpackChunkName: "not-found" */ "@/Pages/NotFound"),
    },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
});

// check if the user is authenticated
router.beforeEach((to, from, next) => {
    const middleware = to.meta.middleware;
    const context = { to, from, next, store };

    // scroll to the top of the page
    window.scrollTo(0, 0);

    if (!middleware) {
        return next();
    }

    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

export default router;
