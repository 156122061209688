<template>
        <modal
            :show="show"
            :max-width="maxWidth"
            :closeable="closeable"
            @close="close"
        >
            <div class="px-6 py-4">
                <div class="text-lg">
                    <slot name="title" />
                </div>

                <div class="mt-4">
                    <slot name="content" />
                </div>
            </div>

            <div class="px-6 py-4 text-right float-left w-full">
                <slot name="footer" />
            </div>
        </modal>
</template>

<script>
    import Modal from "./DefaultModal";

    export default {

        components: {
            Modal,
        },

        props: {
            show: {
                type: Boolean,
                default: false
            },
            maxWidth: {
                type: String,
                default: "2xl"
            },
            closeable: {
                type: Boolean,
                default: true
            },
        },
        emits: ["close"],

        methods: {
            close() {
                this.$emit("close");
            },
        }
    };
</script>
