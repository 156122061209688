<template>
        <div
            class="flex-grow rounded-lg border border-[#efeff2] hover:border-secondary overflow-hidden learning-tile-size"
        >
            <router-link
                v-if="!showDeleteLearning"
                :to="{ path: `/learning/${learning.id}` }"
                class="w-full h-full flex flex-col p-7 bg-white transition-colors duration-300 group"
            >
                <div>
                    <div class="flex w-full justify-between">
                        <h2 class="h-6 px-2 mb-2 flex items-center text-sm rounded w-min text-white bg-secondary uppercase">
                            {{ learning.learning_category.name }}
                        </h2>
                        <div
                            v-if="isAdmin"
                            class="md:hidden flex md:group-hover:flex"
                        >
                            <action-button
                                color="secondary"
                                icon="pencil"
                                @click.prevent="editLearning(learning.id)"
                            />
                            <action-button
                                color="primary"
                                icon="bin"
                                @click.prevent="showDeleteLearning = true"
                            />
                        </div>
                    </div>
                    <h3 class="text-left text-base mb-3">
                        {{ learning.title }}
                    </h3>
                    <learning-banner
                        :learning="learning"
                    />
                    <div class="ck ck-content" v-html="learning.summary.length > 180 ? `${learning.summary.slice(0, 180)}...` : learning.summary" />
                </div>
            </router-link>
            <confirmation-message
                v-else
                item="Learning" 
                @on-accept="deleteLearning(learning.id)"
                @on-decline="showDeleteLearning = false"
            />
        </div>
</template>

<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import { mapGetters } from "vuex";
    import LearningBanner from "./LearningBanner";
    import ActionButton from "@/components/form/button/ActionButton";
    import ConfirmationMessage from "@/components/form/ConfirmationMessage";

    export default {
        components: {
            LearningBanner,
            ActionButton,
            ConfirmationMessage
        },
        props: {
            learning: {
                type: Object,
                default: () => {},
            },
        },
        emits: ["fetch-learnings"],
        data() {
            return {
                showDeleteLearning: false,
                show: false
                };
            },
                computed: {
        ...mapGetters(["isAdmin"]),
    },
        methods: {
            editLearning(id) {
                this.$router.push({ name: "edit-learning", params: { id } });
            },
            deleteLearning(id) {
                return apiClient.delete("learnings/"+id )
                    .then(response => {
                        console.log(response);
                        this.$emit("fetch-learnings");
                    });
            },
        }
    };
</script>
