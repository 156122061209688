<template>
        <button
            class="flex items-center mr-2 pb-0.5 md:mr-5 rounded font-semibold transition-colors duration-300 justify-center group-scoped"
            :class="color === 'primary' ? 'hover:bg-primary' : 'hover:bg-secondary'"
            style="min-height: 30px; min-width: 30px;"
        >
            <span :class="`icon m-0 scoped--bg-main transition-colors duration-300 icon-${icon} ${color === 'primary' ? 'bg-primary' : 'bg-secondary'}`" />
            <span
                v-if="text"
                class="hidden m-0 md:flex mr-1 md:mr-2"
                :class="color === 'primary' ? 'text-primary' : 'text-secondary'"
            >{{ text }}</span>
        </button>
</template>

<script>
    export default {
        props:{
            icon: {
                type: String,
                default: "",
            },
            color: {
                type: String,
                default: "primary",
            },
            text: {
                type: String,
                default: "",
            },
        },
    };
</script>