<template>
        <div>
            <div class="right-col mb-4">
                <button
                    v-if="activeUser"
                    class="edit-btn"
                    @click.prevent="activeUser=null"
                >
                    Back to overview
                </button>
                <secondary-button
                    v-can="'Invite professional|Invite coach|Invite company user'"
                    title="Invite user"
                    @click="$router.push({name: 'users-invite'})"
                />
            </div>
            <h2
                v-if="!activeUser"
                class="w-auto"
            >
                Users
            </h2>
            <h2
                v-if="activeUser"
                class="w-auto"
                data-hj-suppress
            >
                Edit {{ activeUser.name }}
            </h2>

            <user-form
                v-if="activeUser"
                :id="activeUser"
                @user-updated="fetchUsers()"
            />

            <section
                v-if="!activeUser"
                class="vacancy-highlights"
            >
                <div class="block pr-4">
                    <div class="title">
                        Search
                    </div>
                    <input
                        data-hj-suppress
                        v-model="filter"
                        type="text"
                        class="form-input"
                        @keyup.enter="doSearch"
                    >
                </div>
            </section>
    
            <div
                v-if="!activeUser"
                class="info-blocks"
            >
                <table>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th v-can="'View user roles'">
                            Role
                        </th>
                        <th v-can="'View user roles'">
                            Type
                        </th>
                        <th />
                    </tr>
                    <tr
                        v-for="user in users.data"
                        :key="user"
                    >
                        <td data-hj-suppress>{{ user.id }}</td>
                        <td data-hj-suppress>
                            <router-link :to="{ name: 'view-profile', params: {id: user.id } }">
                                {{ user.name }}
                            </router-link>
                        </td>
                        <td data-hj-suppress>{{ user.email }}</td>
                        <td v-can="'View user roles'" data-hj-suppress>
                            {{ (user.roles && user.roles.length > 0) ? user.roles[0].name : '' }}
                        </td>
                        <td v-can="'View user roles'" data-hj-suppress>
                            {{ (user.roles && user.roles.length > 0) ? user.roles[0].type.name : '' }}
                        </td>
                        <td class="p-0 pt-2">
                            <action-button
                                color="secondary"
                                icon="pencil"
                                @click="$router.push({ name: 'users-edit', params: {id: user.id } })"
                            >
                                <span class="icon icon-pencil" />
                            </action-button>
                        </td>
                    </tr>
                </table>

                <BasePagination
                    v-if="users?.meta && users.meta.last_page > 1"
                    :queryParams="queryParams"
                    :meta="users.meta"
                    :links="users.links"
                    @changeRoute="changeRoute"
                />
            </div>
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import BasePagination from "@/components/BasePagination";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import ActionButton from "@/components/form/button/ActionButton";
    import UserForm from "@/Pages/Users/UserForm";
    
    export default {
        components: {
            BasePagination,
            UserForm,
            SecondaryButton,
            ActionButton,
        },

        beforeRouteEnter(to, from, next) {
            const currentPage = parseInt(to.query.page) || 1;
//            this.fetchUsers(to.query);
            // store.dispatch("user/getUsers", currentPage).then(() => {
                to.params.page = currentPage;
                next();
            // });
        },
        data() {
            return {
                users: {},
                queryParams: [],
                activeUser: null,
                filter: null
            };
        },
        watch:{
            $route (to, from){
                this.fetchUsers(to.query);
            }
        },
        created() {
            this.fetchUsers();
        },
        methods: {
            changeRoute(queryParams)
            {
                this.queryParams = Object.assign({}, queryParams);
                this.fetchUsers();
            },
            doSearch() {
                // let filters = {"filter[name]": this.filter };
                // this.fetchUsers(filters);

                let query = this.queryParams;

                if (this.filter) {
                    query["filter[name]"] = this.filter;
                } else {
                    delete query["filter[name]"];
                }

                if ("page" in query) {
                    // reset the page when filtering
                    query["page"] = 1;
                }

                console.log("filter users", query);
                this.changeRoute(query);


            },
            fetchUsers(queryParams) {
                // scroll to top of page
                window.scrollTo(0,0);

                let query = Object.assign({}, this.queryParams || this.$route.query);

                return apiClient.get("users", { params: query } )
                    .then(response => {
                        this.users = response.data;
                    });
            },
        },
    };
</script>