<template>
        <div
            data-hj-suppress
            class="w-60 bg-white shadow-block mt-16 rounded-lg pb-7 px-4 flex flex-col items-center"
            style="height: fit-content; min-width: 240px"
        >
            <router-link
                :to="{ name: 'view-profile', params: { id: user.id } }"
                class="mb-6 w-32 h-16 relative flex justify-center items-center m-auto"
            >
                <img
                    :src="user.profile_photo"
                    class="w-full h-32 m-auto block absolute bottom-0 left-0 rounded-full object-cover"
                    style="object-position: top;"
                >
            </router-link>
            <router-link
                :to="{ name: 'view-profile', params: { id: user.id } }"
                class="bottom-shape shape-center text-center mb-2 text-lg leading-6 font-bold"
            >
                {{ `${user.first_name} ${user.last_name}` }}
            </router-link>
            <h4 class="text-center text-secondary mb-2">
                {{ user.company_location?.company.name || "" }}
            </h4>
            <div
                v-if="bio"
                class="text-center"
                v-html="user.biography.length > 175 ? `${getCappedBio()}...` : user.biography"
            />
            <div class="mt-2">
                <primary-button
                    :title="buttonTitle"
                    :icon="buttonIcon"
                    @click="$emit('button-click')"
                />
            </div>
        </div>
</template>

<script>
    import PrimaryButton from "@/components/form/PrimaryButton";

export default {
    components: {
        PrimaryButton
    },
    props: {
        user: {
            type: Object,
            default: () => {}
        },
        buttonTitle: {
            type: String,
            default: () => ""
        },
        buttonIcon: {
            type: String,
            default: () => ""
        },
        bio: {
            type: Boolean,
            default: () => false
        },
    },
    emits: ["button-click"],
    methods: {
        getCappedBio() {
            const splitText = this.user.biography.slice(0, 175).split(" ");
            splitText.pop();
            return splitText.join(" ");
        }
    }
};
</script>