<template>
        <jet-action-section>
            <template #title>
                Delete account
            </template>

            <template #description>
                Permanently delete your account.
            </template>

            <template #content>
                <div class="max-w-xl text-sm text-gray-600">
                    Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.
                </div>

                <div class="mt-5">
                    <secondary-button
                        title="Delete account"
                        icon="bin"
                        @click.prevent="confirmUserDeletion"
                    />
                </div>

                <!-- Delete Account Confirmation Modal -->
                <jet-dialog-modal
                    :show="confirmingUserDeletion"
                    @close="closeModal"
                >
                    <template #title>
                        Delete Account
                    </template>

                    <template #content>
                        Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.

               

                        <div
                            class="mt-4"
                            :class="{ 'input-error' : form.errors?.password }"
                        >
                            <!-- 'input-filled' : !v$.form.password.$invalid -->
                            <input
                                data-hj-suppress
                                ref="password"
                                v-model="form.password"
                                type="password"
                                class="form-input"
                                placeholder="Password"
                                @keyup.enter="deleteUser"
                            >

                            <span
                                v-for="error in form.errors?.password"
                                :key="error"
                                class="error-msg"
                            >{{ error }}</span>
                        </div>

                        <div
                            v-show="form.message && !form.errors?.password"
                            class="my-4 form-row input-error"
                        >
                            <span class="error-msg">{{ form.message }}</span>
                        </div>
                    </template>

                    <template #footer>
                        <div class="flex justify-between w-full">
                            <jet-secondary-button
                                @click="closeModal"
                            >
                                Cancel
                            </jet-secondary-button>

                            <secondary-button
                                title="Delete account"
                                icon="bin"
                                :disabled="isDisabled"
                                @click.prevent="deleteUser"
                            />
                        </div>
                    </template>
                </jet-dialog-modal>
            </template>
        </jet-action-section>
</template>

<script>
    import JetActionSection from "@/Jetstream/ActionSection";
    import JetDialogModal from "@/Jetstream/DialogModal";
    import JetSecondaryButton from "@/Jetstream/SecondaryButton";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import AuthService from "../../services/AuthService";
    import { useVuelidate } from "@vuelidate/core";
    import {
        required,
    } from "@vuelidate/validators";
    export default {
        components: {
            JetActionSection,
            JetDialogModal,
            JetSecondaryButton,
            SecondaryButton,
        },
        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                confirmingUserDeletion: false,
                form: {
                    errors: {},
                    message: "",
                    processing: false,
                    password: "",
                    
                }
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        validations: {
            form: {
                password: { required },
            }
        },
        methods: {
            confirmUserDeletion() {
                this.confirmingUserDeletion = true;

                setTimeout(() => this.$refs.password.focus(), 250);
            },

            deleteUser() {

                this.form.errors = {};
                this.form.processing = true;
                const payload = {password: this.form.password};
                AuthService.deleteUser(payload)
                    .then((response) => {
                        this.closeModal();
                        this.$store.dispatch("logout");
                    })
                    .catch((error) => {
                        this.form.errors = error.response.data.errors;
                        this.form.message = error.response.data.message;
                        this.$refs.password.focus();
                    }).finally(() => {
                    this.form.password = "";
                    this.form.processing = false;
                });
            },

            closeModal() {
                this.confirmingUserDeletion = false;
                
                this.form.password = "";
                this.form.processing = false;
                this.form.message = "";
                this.form.errors = {};
            },
        },
    };
</script>
