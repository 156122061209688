import axios from "axios";
import store from "@/store";
import {default as ApiService, apiClient } from "@/services/ApiService";
import Cookies from "js-cookie";

export const authClient = axios.create({
    baseURL: ApiService.getApiUrl(),
    withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (
            error.response &&
            [401, 419].includes(error.response.status) &&
            store.getters["authUser"]
        ) {
            store.dispatch("logout");
        }
        return Promise.reject(error);
    }
);

export default {
    async login(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/login", payload);
    },
    async logout() {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/logout");
    },
    async forgotPassword(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/forgot-password", payload);
    },
    async getAuthUser() {
        const userId = Cookies.get("youngheroes_user_id_session");
        // TODo this info will not be stored in vuex. therefor use const authUser = await this.$store.dispatch("getAuthUser");
        if (!userId || userId === "undefined") {
            const response = await authClient.get("/api/users/auth");
            Cookies.set("youngheroes_user_id_session", response.data.data.id);
            return response;
        }
        return authClient.get("/api/users/" + userId);
    },
    getUserPermissions() {
        return authClient.get("/api/users/auth/permissions"); 
    },
    getUserSessions() {
        return authClient.get("/user/sessions");
    },
    async deleteOtherSessions(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.delete("/user/other-browser-sessions", {data: payload});
    },
    async deleteUser(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.delete("/user", {data: payload});
    },
    async resetPassword(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/reset-password", payload);
    },
    async updatePassword(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.put("/user/password", payload);
    },
    getPasswordConfirmationStatus() {
        return authClient.get("/user/confirmed-password-status");
    },
    async confirmPassword(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("user/confirm-password", payload);
    },
    async updateEmail(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.put("/user/profile-information", payload);
    },
    async registerUser(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/register", payload);
    },

    async sendVerification(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/email/verification-notification", payload);
    },
    verifyVerification(params, query) {
        return apiClient.get("/email/verify/" + params.id + "/" + params.hash, {params: query});
    },
    async createPassword(payload, params, query) {
        await authClient.get("/sanctum/csrf-cookie");
        return apiClient.post("/users/password/" + params.secret + "/" + params.hash, payload,  {params: query});
    },
    async enableTwoFactorAuthentication(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/user/two-factor-authentication", payload);
    },
    async disableTwoFactorAuthentication(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.delete("/user/two-factor-authentication", payload);
    },
    twoFactorQrCode() {
        return authClient.get("/user/two-factor-qr-code");
    },
    getTwoFactorRecoveryCodes() {
        return authClient.get("/user/two-factor-recovery-codes");
    },
    async regenerateRecoveryCodes(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/user/two-factor-authentication", payload);
    },
    async twoFactorChallenge(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/two-factor-challenge", payload);
    }
};