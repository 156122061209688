<template>
        <div style="min-height: 400px; ">
            <ul class="form-wrapper">
                <li
                    class="form-row"
                    :class="{ 'input-error' : form.errors.language_id}"
                >
                    <div class="input-grp">
                        <label
                            class="form-label mt-4"
                            for="language_id"
                        >Language *</label>
                        <select
                            data-hj-suppress
                            id="language_id"
                            v-model="form.language_id"
                            class="form-input"
                            required
                        >
                            <option
                                disabled
                                value=""
                            >
                                Please select
                            </option>
                            <option
                                v-for="lang in languages"
                                :key="lang.id"
                                :value="lang.id"
                            >
                                {{ lang.name }}
                            </option>
                        </select>
                        <span
                            v-show="form.errors.language_id"
                            class="error-msg"
                        >{{ form.errors.language_id }}</span>
                    </div>
                </li>

                <li
                    class="form-row"
                    :class="{ 'input-error' : form.errors.grade}"
                >
                    <div class="input-grp">
                        <div class="radio-grp" data-hj-suppress>
                            <div class="radio-row">
                                <input
                                    id="grade_5"
                                    v-model="form.grade"
                                    type="radio"
                                    value="5"
                                >
                                <label
                                    for="grade_5"
                                    class="radio-label"
                                >Yes, it's my mother tongue</label>
                            </div>
                            <div class="radio-row">
                                <input
                                    id="grade_4"
                                    v-model="form.grade"
                                    type="radio"
                                    value="4"
                                >
                                <label
                                    for="grade_4"
                                    class="radio-label"
                                >Yes, I am business fluent</label>
                            </div>
                            <div class="radio-row">
                                <input
                                    id="grade_3"
                                    v-model="form.grade"
                                    type="radio"
                                    value="3"
                                >
                                <label
                                    for="grade_3"
                                    class="radio-label"
                                >Yes, I can watch movies without subtitles</label>
                            </div>
                            <div class="radio-row">
                                <input
                                    id="grade_2"
                                    v-model="form.grade"
                                    type="radio"
                                    value="2"
                                >
                                <label
                                    for="grade_2"
                                    class="radio-label"
                                >Yeah, but only know the basics</label>
                            </div>
                            <!--<div class="radio-row">-->
                            <!--<input type="radio" v-model="form.grade" id="grade_1" value="1" />-->
                            <!--<label for="grade_1" class="radio-label">No</label>-->
                            <!--</div>-->
                            <span class="error-msg">This field is required.</span>
                        </div>
                    </div>
                </li>
                <li class="form-row">
                    <primary-button
                        :title="language ? 'Save language' : 'Save and add language'"
                        :disabled="isDisabled"
                        @click.prevent="submitForm"
                    />
                </li>
            </ul>
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import { useVuelidate } from "@vuelidate/core";
    import {
        required,
    } from "@vuelidate/validators";

    export default {
        components: {
            PrimaryButton
        },
        props: {
            language: {
                type: Object,
                default: () => {},
            }
        },
        emits: ["editedLanguage"],
        setup: () => ({ v$: useVuelidate({$scope: false}) }),
        data() {
            return {
                form: {
                    processing: false,
                    errors: {},
                    language_id: (this.language)?this.language.language.id:null,
                    grade: (this.language)?(this.language.percentage / 20):null
                },
                languages: {}
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        mounted() {
            this.fetchLanguages();
        },
        validations: {
            form: {
                language_id: {required},
                grade: {required},
            }
        },
        methods: {
            submitForm() {
                if(this.language){
                    var url = "/users/" + this.$store.getters.authUser.id + "/languages/"+this.language.id;
                    var method = "put";

                }else{
                    var url = "/users/" + this.$store.getters.authUser.id + "/languages";
                    var method = "post";
                }

                apiClient({
                    method: method,
                    url: url,
                    data: {
                        id: this.language?.id,
                        language_id: this.form.language_id,
                        grade: this.form.grade,
                    }
                }).then(response => {
                    this.$emit("editedLanguage");

                    // only clean form when adding a new record
                    if (!this.language) {
                        this.form.language_id = null;
                        this.form.grade = null;
                    }

                    this.form.errors = {};

                }).catch((error) => {
                    if (error.response) {
                        this.form.errors = error.response.data.errors;
                    }
                });
            },
            fetchLanguages() {
                apiClient.get("/languages") // TODo this causes multiple API calls
                    .then(response => {
                        this.languages = response.data.data;
                    });
            }
        }
    };
</script>
