<template>
        <div class="info-top-part">
            <h2 class="bottom-shape">
                <i><slot name="image" /></i>

                <span><slot name="title" /></span>
            </h2>

            <p class="mt-1 text-sm text-gray-600">
                <slot name="description" />
            </p>


            <div class="px-4 sm:px-0">
                <slot name="aside" />
            </div>
            <slot name="button" />
        </div>
</template>
