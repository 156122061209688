<template>
        <div class="data-display-part">
            <div class="work-info education-info">
                <experience-item
                    v-for="item in experiences"
                    :key="item.id"
                    :experience="item"
                    :can-edit-experience="canAddExperience"
                    @update-experience-list="fetchUserExperiences(); fetchUserProfilePercentage();"
                />
            </div>
            <div
                v-if="canAddExperience"
                class="add-experience common-add-btn extend-btn-info"
                @click="showAddExperience=!showAddExperience"
            >
                <h4>Add experience</h4>
            </div>
            <experience-form
                v-show="showAddExperience"
                @edited-experience="fetchUserExperiences(); fetchUserProfilePercentage();"
            />
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import ExperienceItem from "@/Pages/Profile/ExperienceItem";
    import ExperienceForm from "@/Pages/Profile/ExperienceForm";

    export default {
        components: {
            ExperienceItem,
            ExperienceForm
        },
        //props: ['profileOwner','employmentTypes','countries','user'],
        props: {
            canAddExperience: {
                type: Boolean,
            },
        },
        data() {
            return {
                showAddExperience: false,
                experiences: null
            };
        },
        created() {
            this.fetchUserExperiences();
        },
        methods: {
            fetchUserExperiences() {
                const id = this.$route.params.id || this.$store.getters.authUser?.id;
                if (this.$store.getters.authUser?.id) {
                    apiClient.get("/users/" + id + "/experiences")
                        .then(response => {
                            this.experiences = response.data;
                            this.showAddExperience = false;
                        });
                }
            },
            fetchUserProfilePercentage() {
                this.$store.dispatch("getAuthUser");
            }
        }
    };
</script>
