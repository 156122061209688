import { isAllowed } from "@/acl";
import router from "../router";

export default function auth({ to, next, store }) {
    const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
    if (!store.getters["authUser"]) {
        store.dispatch("setAppNotInitialized").then(() => {
            store.dispatch("getAuthUser").then(() => {
                if (!store.getters["authUser"]) {
                    router.push(loginQuery);
                }
                else {
                    let routePermissions = to.meta.hasOwnProperty("permissions") ? to.meta.permissions : null;
                    if (routePermissions) {
                        if (isAllowed(routePermissions) === true) {
                            next();
                        } else {
                            router.push({ path: "/403"});
                        }
                    } else {
                        // this page has no specific permissions. continue.
                        next();
                    }
                }
            });
        });
    } else {
        let routePermissions = to.meta.hasOwnProperty("permissions") ? to.meta.permissions : null;
        if (routePermissions) {
            if (isAllowed(routePermissions) === true) {
                next();
            } else {
                router.push({ path: "/403"});
            }
        } else {
            // this page has no specific permissions. continue.
            next();
        }
    }
}