<template>
        <div class="mb-10">
            <h4 class="text-lg font-bold">
                {{ title }}
            </h4>
            <div class="ck ck-content" v-html="summary" />
        </div>
</template>

<script>
export default {
    props: {
        summary: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
    }
};
</script>
