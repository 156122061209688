<template>
        <button
            :disabled="disabled"
            class="bg-transparent border-secondary group border-2 hover:border-secondary-hover hover:bg-secondary-hover transition-colors duration-300 h-12 max-h-full px-3 md:px-5 rounded-full font-semibold"
        >
            <span
                v-if="icon"
                class="mr-1 md:mr-2"
                :class="'icon group-hover:bg-main transition-colors duration-300 icon-'+icon"
            />
            {{ title }}
        </button>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
                default: "",
            },
            icon: {
                type: String,
                default: "",
            },
            disabled: Boolean,
        },
    };
</script>