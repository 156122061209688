<template>
        <div
            class="flex flex-col justify-center items-center flex-grow bg-hover h-full w-full"
        >
            <div class="flex h-min items-center mb-4">
                <img
                    src="../../images/icon-bin.svg"
                    alt="remove"
                    class="w-4 h-4 ml-7 mr-5"
                >
                <p
                    class="text-xs md:text-base font-bold inline-block mb-0 h-min w-[900px]"
                    style="max-width: 80%"
                >
                    Are you sure you want to delete this {{ item }}
                </p>
            </div>
            <div class="h-12 justify-center flex">
                <secondary-button
                    title="YES"
                    @click="$emit('on-accept')"
                />
                <div class="mr-5 w-16 ml-2">
                    <primary-button
                        title="NO"
                        @click="$emit('on-decline')"
                    />
                </div>
            </div>
        </div>
</template>

<script>
    import SecondaryButton from "@/components/form/SecondaryButton";
    import PrimaryButton from "@/components/form/PrimaryButton";
export default {
    components: {
        SecondaryButton,
        PrimaryButton,
    },
    props: { item: {
        type: String,
        default: "",
    } },
    emits: ["on-accept", "on-decline"],
};
</script>

<style>

</style>