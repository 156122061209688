<template>
        <div>
            <div class="">
                <div class="">
                    <language-item
                        v-for="item in userLanguages"
                        :key="item.id"
                        :user-language="item"
                        :can-edit-language="canAddLanguage"
                        @update-language-list="fetchUserLanguages(); fetchUserProfilePercentage();"
                    />
                </div>
                <div
                    v-if="canAddLanguage"
                    class="add-language common-add-btn extend-btn-info"
                    @click="showAddLanguage=!showAddLanguage"
                >
                    <h4>Add language</h4>
                </div>
                <language-form
                    v-show="showAddLanguage"
                    @edited-language="fetchUserLanguages(); fetchUserProfilePercentage();"
                />
            </div>
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import LanguageItem from "@/Pages/Profile/LanguageItem";
    import LanguageForm from "@/Pages/Profile/LanguageForm";
    import AuthService from "../../services/AuthService";

    export default {
        components: {
            LanguageItem,
            LanguageForm
        },
        //props: ['profileOwner','user','languageCodes'],
        props: {
            canAddLanguage: {
                type: Boolean,
            },
        },
        data() {
            return {
                showAddLanguage: false,
                userLanguages: null,
            };
        },
        created() {
            this.fetchUserLanguages();
        },
        methods: {
            fetchUserLanguages() {
                const id = this.$route.params.id || this.$store.getters.authUser?.id;
                if (this.$store.getters.authUser?.id) {
                    apiClient.get("/users/" + id + "/languages")
                        .then(response => {
                            this.userLanguages = response.data.data;
                            this.showAddLanguage = false;
                        });
                }
            },
            fetchUserProfilePercentage() {
                this.$store.dispatch("getAuthUser");
            }
        }
    };
</script>
