<template>
        <div class="data-display-part">
            <div class="work-info education-info">
                <skill-item
                    v-for="item in skills"
                    :key="item.id"
                    :user="user"
                    :skill="item"
                    :can-edit-skill="canAddSkill"
                    @update-skill-list="fetchUserSkills(); fetchUserProfilePercentage();"
                />
            </div>
            <div
                v-if="canAddSkill"
                class="add-skill common-add-btn extend-btn-info"
                @click="showAddSkill=!showAddSkill"
            >
                <h4>{{ isCoach ? "Add coaching topic" : "Add skill" }}</h4>
            </div>
            <skill-form
                v-show="showAddSkill"
                :user="user"
                @edited-skill="fetchUserSkills(); fetchUserProfilePercentage();"
            />
        </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import SkillItem from "@/Pages/Profile/SkillItem";
    import SkillForm from "@/Pages/Profile/SkillForm";

    export default {
        components: {
            SkillItem,
            SkillForm
        },
        props: {
            user: {
                type: Object,
                default: () => {},
            },
            skill: {
                type: Object,
                default: () => {},
            },
            canAddSkill: {
                type: Boolean,
            },
        },
        data() {
            return {
                showAddSkill: false,
                skills: null
            };
        },
        computed: {
            ...mapGetters(["isCoach"]),
        },
        created() {
            this.fetchUserSkills();
        },
        methods: {
            fetchUserSkills() {
                const id = this.$route.params.id || this.$store.getters.authUser?.id;
                if (this.$store.getters.authUser?.id) {
                    apiClient.get("/users/" + id + "/skills")
                        .then(response => {
                            this.skills = response.data;
                            this.showAddSkill = false;
                        });
                }
            },
            fetchUserProfilePercentage() {
                this.$store.dispatch("getAuthUser");
            }
        }
    };
</script>
