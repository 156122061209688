/* eslint-disable no-console */
import router from "@/router";
import Vuex from "vuex";
import AuthService from "@/services/AuthService";
import Cookies from "js-cookie";

const getError = (error) => {
    
    const errorMessage = "API Error, please try again.";

    if (error.name === "Fetch User") {
        return error.message;
    }

    if (!error.response) {
        //console.error(`API ${error.config.url} not found`);
        return errorMessage;
    }
    if (process.env.NODE_ENV === "development") {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
    }
    if (error.response.data && error.response.data.errors) {
        return error.response.data.errors;
    }

    return errorMessage;
};

const store = new Vuex.Store({
    state: {
        theme: process.env.VUE_APP_THEME,
        user: null,
        loading: false,
        error: null,
        permissions: {},
        notifications: [],
        appInitialized: true,
        themeInitialized: false,
        setActiveChat: null
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_PERMISSIONS(state, permissions) {
            state.permissions = permissions;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
        SET_APPINITIALIZED(state, appInitialized) {
            state.appInitialized = appInitialized;
        },
        SET_THEME_INITIALIZED(state, themeInitialized) {
            state.themeInitialized = themeInitialized;
        },
        SET_THEME(state, theme) {
            state.theme = theme;
        },
        SET_API_URL(state, url) {
            state.api_url = url;
        },
        SET_ROLE_TYPE(state, role_type) {
            state.role_type = role_type;
        },
        SET_ACTIVE_CHAT(state, trajectory) {
            state.activeChat = trajectory;
        },
        SET_NOTIFICATIONS(state, notifications) {
            state.notifications = notifications;
            // update session storage for notifications.
            sessionStorage.setItem("youngheroes_notifications", JSON.stringify(state.notifications));
        },
        add_notification(state, notification) {
            state.notifications.push(notification);
            this.commit("SET_NOTIFICATIONS", state.notifications);
        }
    },
    actions: {
        logout({ commit, dispatch }) {
            return AuthService.logout()
                .then(() => {

                    // disconnect echo to leave all channels
                    Echo.disconnect();

                    commit("SET_USER", null);
                    commit("SET_ROLE_TYPE", null);
                    commit("SET_PERMISSIONS", null);
                    dispatch("setGuest", { value: "isGuest" });
                    Cookies.set("youngheroes_user_id_session", "undefined");
                    if (router.currentRoute.name !== "login")
                        router.push({ path: "/login" });
                })
                .catch((error) => {
                    commit("SET_ERROR", getError(error));
                });
        },
        async getAuthUser({ dispatch, commit }) {
            commit("SET_LOADING", true);
            try {
                const response = await AuthService.getAuthUser();
                commit("SET_USER", response.data.data);
                if (typeof response.data.data !== "undefined" && response.data.data.roles.length > 0) {
                    commit("SET_ROLE_TYPE", response.data.data.roles[0].type.name.toLowerCase());
                }
                await dispatch("getUserPermissions"); // wait for `getUserPermissions` to finish
                
                commit("SET_LOADING", false);
                commit("SET_APPINITIALIZED", true);
                return response.data;
            } catch (error) {
                commit("SET_APPINITIALIZED", true);
                commit("SET_LOADING", false);
                commit("SET_USER", null);
                commit("SET_ROLE_TYPE", null);
                commit("SET_ERROR", getError(error));
            }
        },
        async getUserPermissions({ commit, state }) {
            
            if (!state.user) {
                return null;
            }
            
            commit("SET_LOADING", true);
            const response = await AuthService.getUserPermissions();
            const permissions = response.data.data;
            commit("SET_PERMISSIONS", permissions.map(permission => permission.name));
            commit("SET_LOADING", false);
            return response.data;
        },
        async setAppNotInitialized({ commit, state }) {
            commit("SET_APPINITIALIZED", false);
        },
        async setActiveChat({ commit }, trajectory) {
            commit("SET_ACTIVE_CHAT", trajectory);
        },
        setGuest(context, { value }) {
            window.localStorage.setItem("guest", value);
        },
        setAuthUser({ commit }, data) {
            commit("SET_USER", data.user);
            if (typeof data.user !== "undefined" && data.user.roles?.length > 0) {
                commit("SET_ROLE_TYPE", data.user.roles[0].type.name.toLowerCase());
            }
        }
    },
    getters: {
        theme: (state) => {
            return state.theme;  
        },
        api_url: (state) => {
            return state.api_url; 
        },
        roleType(state) {
            return state.role_type;
        },
        authUser: (state) => {
            return state.user;
        },
        appInitialized: (state) => {
            return state.appInitialized;
        },
        themeInitialized: (state) => {
            return state.themeInitialized;
        },
        isAdmin: (state) => {
            return state.role_type ? state.role_type === "admin" : false;
        },
        isCoach: (state) => {
            return state.role_type ? state.role_type === "coach" : false;
        },
        isProfessional: (state) => {
            return state.role_type ? state.role_type === "professional" : false;
        },
        hasPermission: (state) => (permission) => {
            return state.permissions ? Object.values(state.permissions).includes(permission) : false;
        },
        error: (state) => {
            return state.error;
        },
        loading: (state) => {
            return state.loading;
        },
        setActiveChat: (state) => {
            return state.activeChat;
        },
        loggedIn: (state) => {
            return !!state.user;
        },
        guest: () => {
            const storageItem = window.localStorage.getItem("guest");
            if (!storageItem) return false;
            if (storageItem === "isGuest") return true;
            if (storageItem === "isNotGuest") return false;
        },
    },
});

export default store;
