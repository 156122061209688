<template>
        <jet-form-section>
            <template #title>
                <div v-if="activeVacancy">
                    Edit vacancy {{ form.title }}
                </div>
                <div v-else>
                    Add Vacancy
                </div>
            </template>
            <template #form>
                <div class="col-span-12 sm:col-span-12">
                    <form>
                        <ul class="form-wrapper">
                            <li class="form-row checkbox-input">
                                <div class="checkbox-grp">
                                    <div class="checkbox-row">
                                        <input
                                            id="published"
                                            type="checkbox"
                                            :checked="isPublished"
                                            @click="setDatePublished"
                                        >
                                        <label
                                            for="published"
                                            class="checkbox-label"
                                        >Published</label>
                                    </div>
                                </div>
                            </li>
                            <li
                                class="form-row"
                                :class="{
                                    'input-error' : form.errors.title, 
                        
                                    'input-filled' : !v$.form.title.$invalid
                                }"
                            >
                                <div class="input-grp">
                                    <label class="form-label">Title</label>
                                    <input
                                        v-model="form.title"
                                        class="form-input"
                                        type="text"
                                    >
                                    <span
                                        v-show="form.errors.title"
                                        class="error-msg"
                                    >{{ form.errors.title }}</span>
                                </div>
                            </li>

                            <li
                                v-for="(file, index) in form.images"
                                :key="index"
                                class="form-row custom-file-upload"
                            >
                                <div class="profile-area">
                                    <span
                                        v-show="file.link"
                                        :ref="'preview-file-'+index"
                                        class="photoPreview"
                                        :style="'background-image: url(\'' + file.link + '\');'"
                                        @click.prevent="$refs['file-'+index].click()"
                                    />
                                </div>
                            
                                <div class="input-grp">
                                    <label class="form-label">
                                        Image {{ parseInt(index)+1 }}
                                        <button
                                            v-if="file.id"
                                            class="item-button item-delete-button"
                                            @click.prevent="deleteImage(file, index, $event)"
                                        >
                                            <span class="icon icon-bin" />
                                        </button>
                                    </label>
                                    <input
                                        :ref="'file-'+index"
                                        type="file"
                                        accept="image/*"
                                        class="hidden"
                                        @change="upload(file, index, $event)"
                                    >

                                    <secondary-button
                                        icon="upload"
                                        title="Image"
                                        @click.prevent="$refs['file-'+index].click()"
                                    />
                                </div>
                            </li>
                        
                            <li class="form-row checkbox-input">
                                <div class="checkbox-grp">
                                    <label class="form-label">Type of work</label>
                                    <div
                                        v-for="work_type in work_types"
                                        :key="work_type"
                                        class="checkbox-row"
                                    >
                                        <input
                                            :id="'work_type'+work_type.id"
                                            v-model="form.work_types"
                                            type="checkbox"
                                            :value="work_type"
                                        >
                                        <label
                                            :for="'work_type'+work_type.id"
                                            class="checkbox-label"
                                        >{{ work_type.name }}</label>
                                    </div>
                                    <br><br>
                                </div>
                            </li>
                            <li class="form-row two-input">
                                <div
                                    class="input-grp"
                                    :class="{ 'input-error' : form.errors.employment_type_id, 'input-filled' : !v$.form.employment_type_id.$invalid}"
                                >
                                    <label class="form-label">Type of employment</label>
                                    <select
                                        id="employment_type_id"
                                        v-model="form.employment_type_id"
                                        class="form-input"
                                    >
                                        <option
                                            disabled
                                            value=""
                                        >
                                            Please select
                                        </option>
                                        <option
                                            v-for="employment_type in employment_types"
                                            :key="employment_type.id"
                                            :value="employment_type.id"
                                        >
                                            {{ employment_type.name }}
                                        </option>
                                    </select>
                                
                                    <span
                                        v-show="form.errors.employment_type_id"
                                        class="error-msg"
                                    >{{ form.errors.employment_type_id }}</span>
                                </div>
                                <div
                                    class="input-grp"
                                    :class="{ 'input-error' : form.errors.education_level_id, 'input-filled' : !v$.form.education_level_id.$invalid}"
                                >
                                    <label class="form-label">Education</label>
                                    <select
                                        id="education_level_id"
                                        v-model="form.education_level_id"
                                        class="form-input"
                                    >
                                        <option
                                            disabled
                                            value=""
                                        >
                                            Please select
                                        </option>
                                        <option
                                            v-for="education_level in education_levels"
                                            :key="education_level.id"
                                            :value="education_level.id"
                                        >
                                            {{ education_level.name }}
                                        </option>
                                    </select>
                                    <span
                                        v-show="form.errors.education_level_id"
                                        class="error-msg"
                                    >{{ form.errors.education_level_id }}</span>
                                </div>
                            </li>

                            <li class="form-row two-input">
                                <div
                                    class="input-grp"
                                    :class="{ 'input-error' : form.errors.company, 'input-filled' : !v$.form.company.$invalid}"
                                >
                                    <label class="form-label">Company</label>
                                    <input
                                        v-model="form.company"
                                        class="form-input"
                                        type="text"
                                    >
                                    <span
                                        v-show="form.errors.company"
                                        class="error-msg"
                                    >{{ form.errors.company }}</span>
                                </div>
                                <div
                                    class="input-grp"
                                    :class="{ 'input-error' : form.errors.city, 'input-filled' : !v$.form.city.$invalid}"
                                >
                                    <label class="form-label">Company location</label>
                                    <input
                                        v-model="form.city"
                                        class="form-input"
                                        type="text"
                                    >
                                    <span
                                        v-show="form.errors.city"
                                        class="error-msg"
                                    >{{ form.errors.city }}</span>
                                </div>
                            </li>
                            <li
                                class="form-row"
                                :class="{ 'input-error' : form.errors.country_id, 'input-filled' : !v$.form.country_id.$invalid}"
                            >
                                <div class="input-grp">
                                    <label class="form-label">Country</label>
                                    <select
                                        id="country_id"
                                        v-model="form.country_id"
                                        class="form-input"
                                        required
                                        autocomplete="country"
                                    >
                                        <option
                                            disabled
                                            value=""
                                        >
                                            Please select
                                        </option>
                                        <option
                                            v-for="country in countries"
                                            :key="country.id"
                                            :value="country.id"
                                        >
                                            {{ country.name }}
                                        </option>
                                    </select>
                                    <span
                                        v-show="form.errors.country_id"
                                        class="error-msg"
                                    >{{ form.errors.country_id }}</span>
                                </div>
                            </li>
                            <li class="form-row checkbox-input">
                                <div class="checkbox-grp">
                                    <div class="checkbox-row">
                                        <input
                                            id="featured"
                                            v-model="form.featured"
                                            type="checkbox"
                                        >
                                        <label
                                            for="featured"
                                            class="checkbox-label"
                                        >Featured</label>
                                    </div>
                                </div>
                            </li>
                            <li class="form-row checkbox-input">
                                <div class="checkbox-grp">
                                    <div class="checkbox-row">
                                        <input
                                            id="popular"
                                            v-model="form.popular"
                                            type="checkbox"
                                        >
                                        <label
                                            for="popular"
                                            class="checkbox-label"
                                        >Popular</label>
                                    </div>
                                </div>
                            </li>
                            <li
                                class="form-row"
                                :class="{ 'input-error' : form.errors.summary, 'input-filled' : !v$.form.summary.$invalid}"
                            >
                                <div class="input-grp">
                                    <label class="form-label">Summary</label>
                                    <ckeditor
                                        v-model="form.summary"
                                        :editor="editor"
                                        :config="editorConfig"
                                    />
                                    <span
                                        v-show="form.errors.summary"
                                        class="error-msg"
                                    >{{ form.errors.summary }}</span>
                                </div>
                            </li>
                            <li
                                class="form-row"
                                :class="{ 'input-error' : form.errors.company_description, 'input-filled' : !v$.form.company_description.$invalid}"
                            >
                                <div class="input-grp">
                                    <label class="form-label">Company description</label>
                                    <ckeditor
                                        v-model="form.company_description"
                                        :editor="editor"
                                        :config="editorConfig"
                                    />
                                    <span
                                        v-show="form.errors.company_description"
                                        class="error-msg"
                                    >{{ form.errors.company_description }}</span>
                                </div>
                            </li>
                            <li
                                class="form-row"
                                :class="{ 'input-error' : form.errors.job_description, 'input-filled' : !v$.form.job_description.$invalid}"
                            >
                                <div class="input-grp">
                                    <label class="form-label">Job description</label>
                                    <ckeditor
                                        v-model="form.job_description"
                                        :editor="editor"
                                        :config="editorConfig"
                                    />
                                    <span
                                        v-show="form.errors.job_description"
                                        class="error-msg"
                                    >{{ form.errors.job_description }}</span>
                                </div>
                            </li>
                            <li
                                class="form-row"
                                :class="{ 'input-error' : form.errors.requirements, 'input-filled' : !v$.form.requirements.$invalid}"
                            >
                                <div class="input-grp">
                                    <label class="form-label">Requirements</label>
                                    <ckeditor
                                        v-model="form.requirements"
                                        :editor="editor"
                                        :config="editorConfig"
                                    />
                                    <span
                                        v-show="form.errors.requirements"
                                        class="error-msg"
                                    >{{ form.errors.requirements }}</span>
                                </div>
                            </li>
                            <li
                                class="form-row"
                                :class="{ 'input-error' : form.errors.benefits, 'input-filled' : !v$.form.benefits.$invalid}"
                            >
                                <div class="input-grp">
                                    <label class="form-label">Benefits & Conditions</label>
                                    <ckeditor
                                        v-model="form.benefits"
                                        :editor="editor"
                                        :config="editorConfig"
                                    />
                                    <span
                                        v-show="form.errors.benefits"
                                        class="error-msg"
                                    >{{ form.errors.benefits }}</span>
                                </div>
                            </li>
                            <li class="form-row">
                                <primary-button
                                    title="Save vacancy"
                                    :disabled="isDisabled"
                                    @click.prevent="submitForm"
                                />
                            </li>
                        </ul>
                    </form>
                </div>
            </template>
        </jet-form-section>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import store from "@/store";
    import JetFormSection from "@/Jetstream/FormSection";
    import { useVuelidate } from "@vuelidate/core";
    import {
        required,
    } from "@vuelidate/validators";
    import moment from "moment";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import SecondaryButton from "@/components/form/SecondaryButton";
    
    export default {
        components: {
            JetFormSection,
            PrimaryButton,
            SecondaryButton
        },
        props: {
            activeVacancy: {
                type: Object,
                default: () => {},
            }
        },
        emits: ["vacancyUpdated"],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                vacancy: null,
                countries: {},
                work_types: {},
                employment_types: {},
                education_levels: {},
                form: {
                    errors: {},
                    processing: false,
                    title: (this.activeVacancy) ? this.activeVacancy.title : null,
                    images: {
                            0: (this.activeVacancy?.images[0]) ? this.activeVacancy.images[0] : {},
                            1: (this.activeVacancy?.images[1]) ? this.activeVacancy.images[1] : {}
                    },
                    work_types: (this.activeVacancy) ? this.activeVacancy.work_types : [],
                    employment_type_id: (this.activeVacancy) ? this.activeVacancy.employment_type.id : "",
                    education_level_id: (this.activeVacancy) ? this.activeVacancy.education_level.id : "",
                    company: (this.activeVacancy) ? this.activeVacancy.company.name : null,
                    city: (this.activeVacancy) ? this.activeVacancy.company_location.city : null,
                    country_id: (this.activeVacancy) ? this.activeVacancy.company_location.country_id : "",
                    featured: (this.activeVacancy) ? !!this.activeVacancy.featured : false,
                    popular: (this.activeVacancy) ? !!this.activeVacancy.popular : false,
                    summary: (this.activeVacancy) ? this.activeVacancy.summary : null,
                    company_description: (this.activeVacancy) ? this.activeVacancy.company_description : null,
                    job_description: (this.activeVacancy) ? this.activeVacancy.job_description : null,
                    requirements: (this.activeVacancy) ? this.activeVacancy.requirements : null,
                    benefits: (this.activeVacancy) ? this.activeVacancy.benefits : null,
                    date_published: (this.activeVacancy) ? this.activeVacancy.date_published : null
                }
            };
        },
        computed: {
            isPublished() {
                return !!this.form.date_published;
            },
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        validations: {
            form: {
                title: {required},
                employment_type_id: {required},
                education_level_id: {required},
                company: { required },
                city: { required},
                country_id: { required},
                summary: {required},
                company_description: {required},
                job_description: {required},
                requirements: {required},
                benefits: {required},
            }
        },
        mounted() {
            this.fetchCountries();
            this.fetchWorkTypes();
            this.fetchEmploymentTypes();
            this.fetchEducationLevels();
        },
        methods: {
            fetchWorkTypes() {
                apiClient.get("/work-types")
                    .then(response => {
                        this.work_types = response.data;
                    });
            },
            fetchCountries() {
                apiClient.get("/countries")
                    .then(response => {
                        this.countries = response.data;
                    });
            },
            fetchEmploymentTypes() {
                apiClient.get("/employment-types")
                    .then(response => {
                        this.employment_types = response.data;
                    });
            },
            fetchEducationLevels() {
                apiClient.get("/education-levels")
                    .then(response => {
                        this.education_levels = response.data;
                    });
            },
            setDatePublished(event) {
                if(!event.target.checked) {
                    this.form.date_published = null;
                } else {
                    this.form.date_published = moment().format("YYYY-MM-DD");
                }
            },
            async deleteImage(file, index, e) {

                delete this.form.images[index].file;
                this.$refs["preview-file-"+index].style = "background-image: none)";
                if (this.$refs["file-"+index]?.value) {
                    this.$refs["file-"+index].value = null;
                }
                if (file.id) {
                    await apiClient.get( ApiService.getApiUrl() + "/sanctum/csrf-cookie");
                    apiClient({
                        method: "DELETE",
                        url: "vacancies/" + this.activeVacancy.id + "/images/"+file.id,
                    })
                        .then(response => {
                            this.form.images[0] = (response.data.data[0]) ? response.data.data[0] : {};
                            this.form.images[1] = (response.data.data[1]) ? response.data.data[1] : {};
                        });
                    
                }
            },
            upload(file, index, e){
                let reader = new FileReader();
                let f = e.target.files[0];
                
                this.form.images[index].file = f;
                reader.readAsDataURL(f);

                reader.onload = (e) => {
                    this.$refs["preview-file-"+index].style = "background-image: url('" + e.target.result + "')";

                };
            },
            async submitForm() {
                let payload = Object.assign({}, this.form);
                delete payload.errors;
                delete payload.processing;

                if(this.activeVacancy){
                    var url = "vacancies/" + this.activeVacancy.id;
                    var method = "put";
                }else{
                    var url = "vacancies";
                    var method = "post";
                    
                }
                await apiClient.get( ApiService.getApiUrl() + "/sanctum/csrf-cookie");
                apiClient({
                    method: method,
                    url: url,
                    data: payload,
                })
                    .then(response => {
                        
                        let image_count = Object.keys(this.form.images).length;

                        if (this.activeVacancy && image_count > 0 ) {
                            for (var i = 0; i < image_count; i++) {
                                let image = this.form.images[i];

                                if (image.file) {
                                    const formData = new FormData();
                                    
                                    // replace existing file
                                    if (image.id) {
                                        url = "vacancies/" + this.activeVacancy.id + "/images/"+image.id;
                                        formData.append("_method", "PUT");
                                        formData.append("image", image.file);
                                    } else {
                                        // upload new file
                                        url = "vacancies/" + this.activeVacancy.id + "/images";
                                        formData.append("images[" + i + "]", image.file);
                                    }

                                    apiClient.get(ApiService.getApiUrl() + "/sanctum/csrf-cookie"); // TODO not await possible here..
                                    apiClient({
                                        method: "POST",
                                        url: url,
                                        data: formData,
                                        headers: { "Content-Type": "multipart/form-data" },
                                    })
                                        .then(response => {
                                            this.form.images[0] = (response.data.data[0]) ? response.data.data[0] : {};
                                            this.form.images[1] = (response.data.data[1]) ? response.data.data[1] : {};
                                        });
                                }
                            }
                        }
                        this.$emit("vacancyUpdated");
                    });
            }
        }
    };
</script>