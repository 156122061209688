<template>
        <div class="w-60 pl-0 mb-6 flex flex-col">
            <input
                ref="file"
                type="file"
                class="hidden"
                accept=".doc, .docx, application/msword, application/pdf, image/png, image/PNG"
                @change="updateFilePreview"
            >
            <div
                v-if="filePreview.type?.includes('image') || filePreview.type?.includes('pdf')"
                class="flex items-center mr-4"
            >
                <div
                    class="w-14 h-14 min-w-14 overflow-hidden flex justify-center items-center"
                    @click.prevent="upload"
                >
                    <img
                        v-show="filePreview.type?.includes('image')"
                        class="w-full h-full object-contain"
                        :src="filePreview.data"
                        :alt="filePreview.name"
                    >
                    <iframe
                        v-show="filePreview.type === 'application/pdf'"
                        :src="filePreview.data"
                        class="w-14 h-14 m-0.5 border-0"
                    />
                </div>
            </div>
            <div
                v-show="filePreview.name"
                class="mb-4"
            >
                {{ filePreview.name }}
            </div>
            <div class="w-40">
                <secondary-button
                    :title="filePreview.data || file ? 'Change file' : 'Upload file'"
                    icon="upload"
                    @click.prevent="() => $refs.file.click()"
                />
            </div>
        </div>
</template>

<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import SecondaryButton from "@/components/form/SecondaryButton";

    export default {
        components: {
            SecondaryButton,
        },
        props: {
            file: {
                type: Object,
                default: null,
            },
            upload: {
                type: Function,
                default: () => {},
            },
            errors: {
                type: Object,
                default: []
            }
        },
        emits: ["upload"],
        mounted() {
            this.updateFilePreview()
        },
        data() {
            return {
                filePreview: {},
            };
        },
        methods: {
            updateFilePreview() {
                this.filePreview = {};
                let [file_ref] = this.$refs.file.files;

                if (!file_ref) {
                    file_ref = this.file;
                }
                if (file_ref instanceof File) {
                    // new files
                    this.$emit("upload", file_ref);
                    this.$refs.file.value = null;

                    this.filePreview = {
                        type: file_ref.type,
                        name: file_ref.name,
                        data: URL.createObjectURL(file_ref),
                    };

                } else if (file_ref && file_ref.id) {
                    // existing files
                    apiClient.get("/files/" + file_ref.id)
                        .then(response => {
                            this.filePreview = {
                                type: file_ref.mime_type,
                                name: file_ref.name,
                                data: response.data.data.content,
                            };
                        });
                }
            },
        }
    };
</script>
