<template>
        <div class="info-blocks">
            <div class="info-top-part">
                <h2
                    v-if="role"
                    class="bottom-shape"
                >
                    Edit role {{ role.name }} ({{ role.type.name }})
                </h2>
                <h2
                    v-else
                    class="bottom-shape"
                >
                    New Role
                </h2>
                <p class="mt-1 text-sm text-gray-600" />
                <div class="px-4 sm:px-0" />
            </div>


            <div class="col-span-12 sm:col-span-12">
                <form>
                    <ul class="form-wrapper">
                        <li class="form-row two-input">
                            <div
                                class="input-grp"
                                :class="{ 'input-error' : form.errors.name, 'input-filled' : !v$.form.name.$invalid }"
                            >
                                <label class="form-label">Name</label>
                                <input
                                    data-hj-suppress
                                    v-model="form.name"
                                    class="form-input"
                                    type="text"
                                >
                                <span
                                    v-for="error in form.errors.name"
                                    :key="error"
                                    class="error-msg"
                                >{{ error }}</span>
                            </div>
                            <div
                                class="input-grp"
                                :class="{ 'input-error' : form.errors.type, 'input-filled' : !v$.form.type.$invalid }"
                            >
                                <label class="form-label">Type</label>
                                <select
                                    data-hj-suppress
                                    id="role_type_id"
                                    v-model="form.type"
                                    :disabled="role"
                                    class="form-input"
                                >
                                    <option
                                        value=""
                                        disabled
                                    >
                                        Select type
                                    </option>
                                    <option value="1">
                                        Admin
                                    </option>
                                    <option value="2">
                                        Professional
                                    </option>
                                    <option value="3">
                                        Coach
                                    </option>
                                    <option value="4">
                                        Company
                                    </option>
                                </select>
                                <span
                                    v-for="error in form.errors.type"
                                    :key="error"
                                    class="error-msg"
                                >{{ error }}</span>
                            </div>
                        </li>
                        <li class="form-row checkbox-input">
                            <div class="checkbox-grp" data-hj-suppress>
                                <label class="form-label">General</label>
                                <div class="checkbox-row">
                                    <input
                                        id="permission52"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="52"
                                    >
                                    <label
                                        for="permission52"
                                        class="checkbox-label"
                                    >Share with friends</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission53"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="53"
                                    >
                                    <label
                                        for="permission53"
                                        class="checkbox-label"
                                    >View My Learnings</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission55"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="55"
                                    >
                                    <label
                                        for="permission55"
                                        class="checkbox-label"
                                    >View My Coaches</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission14"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="14"
                                    >
                                    <label
                                        for="permission14"
                                        class="checkbox-label"
                                    >Can view all profiles</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission16"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="16"
                                    >
                                    <label
                                        for="permission16"
                                        class="checkbox-label"
                                    >Can view 'searching for job' user profiles</label>
                                </div>
                            
                                <label class="form-label">User management</label>
                                <div class="checkbox-row">
                                    <input
                                        id="permission57"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="57"
                                    >
                                    <label
                                        for="permission57"
                                        class="checkbox-label"
                                    >Edit users</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission58"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="58"
                                    >
                                    <label
                                        for="permission58"
                                        class="checkbox-label"
                                    >View user roles</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission59"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="59"
                                    >
                                    <label
                                        for="permission59"
                                        class="checkbox-label"
                                    >Edit user roles</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission2"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="2"
                                    >
                                    <label
                                        for="permission2"
                                        class="checkbox-label"
                                    >Invite professional</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission3"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="3"
                                    >
                                    <label
                                        for="permission3"
                                        class="checkbox-label"
                                    >Remove professional</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission4"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="4"
                                    >
                                    <label
                                        for="permission4"
                                        class="checkbox-label"
                                    >Invite coach</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission5"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="5"
                                    >
                                    <label
                                        for="permission5"
                                        class="checkbox-label"
                                    >Remove coach</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission6"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="6"
                                    >
                                    <label
                                        for="permission6"
                                        class="checkbox-label"
                                    >Invite company user</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission7"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="7"
                                    >
                                    <label
                                        for="permission7"
                                        class="checkbox-label"
                                    >Remove company user</label>
                                </div>
                            
                                <label class="form-label">Billing</label>
                                <div class="checkbox-row">
                                    <input
                                        id="permission17"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="17"
                                    >
                                    <label
                                        for="permission17"
                                        class="checkbox-label"
                                    >View invoices</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission18"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="18"
                                    >
                                    <label
                                        for="permission18"
                                        class="checkbox-label"
                                    >Change billing plan</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission19"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="19"
                                    >
                                    <label
                                        for="permission19"
                                        class="checkbox-label"
                                    >Manage billing details</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission20"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="20"
                                    >
                                    <label
                                        for="permission20"
                                        class="checkbox-label"
                                    >Billing email alerts</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission21"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="21"
                                    >
                                    <label
                                        for="permission21"
                                        class="checkbox-label"
                                    >Manage payment method</label>
                                </div>

                                <label class="form-label">Dashboard</label>
                                <div class="checkbox-row">
                                    <input
                                        id="permission22"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="22"
                                    >
                                    <label
                                        for="permission22"
                                        class="checkbox-label"
                                    >View poll</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission23"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="23"
                                    >
                                    <label
                                        for="permission23"
                                        class="checkbox-label"
                                    >Vote in poll</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission24"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="24"
                                    >
                                    <label
                                        for="permission24"
                                        class="checkbox-label"
                                    >View video</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission25"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="25"
                                    >
                                    <label
                                        for="permission25"
                                        class="checkbox-label"
                                    >View featured content My Learning</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission26"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="26"
                                    >
                                    <label
                                        for="permission26"
                                        class="checkbox-label"
                                    >View featured content My Coach</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission27"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="27"
                                    >
                                    <label
                                        for="permission27"
                                        class="checkbox-label"
                                    >View featured content Vacancies</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission28"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="28"
                                    >
                                    <label
                                        for="permission28"
                                        class="checkbox-label"
                                    >View featured content Blog</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission29"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="29"
                                    >
                                    <label
                                        for="permission29"
                                        class="checkbox-label"
                                    >Add coachees</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission30"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="30"
                                    >
                                    <label
                                        for="permission30"
                                        class="checkbox-label"
                                    >View coachee information tile</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission31"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="31"
                                    >
                                    <label
                                        for="permission31"
                                        class="checkbox-label"
                                    >View event calendar</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission32"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="32"
                                    >
                                    <label
                                        for="permission32"
                                        class="checkbox-label"
                                    >Sign up for events</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission33"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="33"
                                    >
                                    <label
                                        for="permission33"
                                        class="checkbox-label"
                                    >View notifications</label>
                                </div>
                                <label class="form-label">Profile</label>
                                <div class="checkbox-row">
                                    <input
                                        id="permission36"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="36"
                                    >
                                    <label
                                        for="permission36"
                                        class="checkbox-label"
                                    >Specify being hired</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission56"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="56"
                                    >
                                    <label
                                        for="permission56"
                                        class="checkbox-label"
                                    >View progress bar</label>
                                </div>
                            
                                <label class="form-label">Account management</label>
                                <div class="checkbox-row">
                                    <input
                                        id="permission37"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="37"
                                    >
                                    <label
                                        for="permission37"
                                        class="checkbox-label"
                                    >Update email address</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission38"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="38"
                                    >
                                    <label
                                        for="permission38"
                                        class="checkbox-label"
                                    >Delete account</label>
                                </div>
                            
                                <label class="form-label">Shared coach space</label>
                                <div class="checkbox-row">
                                    <input
                                        id="permission39"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="39"
                                    >
                                    <label
                                        for="permission39"
                                        class="checkbox-label"
                                    >View coaching trajectory</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission40"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="40"
                                    >
                                    <label
                                        for="permission40"
                                        class="checkbox-label"
                                    >Edit coaching trajectory</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission41"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="41"
                                    >
                                    <label
                                        for="permission41"
                                        class="checkbox-label"
                                    >View professional goals and motivations</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission42"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="42"
                                    >
                                    <label
                                        for="permission42"
                                        class="checkbox-label"
                                    >Edit professional goals and motivations</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission43"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="43"
                                    >
                                    <label
                                        for="permission43"
                                        class="checkbox-label"
                                    >Upload documents</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission44"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="44"
                                    >
                                    <label
                                        for="permission44"
                                        class="checkbox-label"
                                    >Download documents</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission45"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="45"
                                    >
                                    <label
                                        for="permission45"
                                        class="checkbox-label"
                                    >Recommend learnings</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission46"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="46"
                                    >
                                    <label
                                        for="permission46"
                                        class="checkbox-label"
                                    >View recommended learnings</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission47"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="47"
                                    >
                                    <label
                                        for="permission47"
                                        class="checkbox-label"
                                    >View favorite learnings</label>
                                </div>
                            
                                <label class="form-label">Vanacy pages</label>
                                <div class="checkbox-row">
                                    <input
                                        id="permission48"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="48"
                                    >
                                    <label
                                        for="permission48"
                                        class="checkbox-label"
                                    >Add vacancies</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission49"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="49"
                                    >
                                    <label
                                        for="permission49"
                                        class="checkbox-label"
                                    >View vacancy users</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission50"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="50"
                                    >
                                    <label
                                        for="permission50"
                                        class="checkbox-label"
                                    >View vacancies</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission51"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="51"
                                    >
                                    <label
                                        for="permission51"
                                        class="checkbox-label"
                                    >Respond to vacancies</label>
                                </div>

                                <label class="form-label">Role pages</label>

                                <div class="checkbox-row">
                                    <input
                                        id="permission60"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="60"
                                    >
                                    <label
                                        for="permission60"
                                        class="checkbox-label"
                                    >Edit roles</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission61"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="61"
                                    >
                                    <label
                                        for="permission61"
                                        class="checkbox-label"
                                    >Create roles</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission8"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="8"
                                    >
                                    <label
                                        for="permission8"
                                        class="checkbox-label"
                                    >Appoint admin</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission9"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="9"
                                    >
                                    <label
                                        for="permission9"
                                        class="checkbox-label"
                                    >Demote admin</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission10"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="10"
                                    >
                                    <label
                                        for="permission10"
                                        class="checkbox-label"
                                    >Appoint contributor</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission11"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="11"
                                    >
                                    <label
                                        for="permission11"
                                        class="checkbox-label"
                                    >Demote contributor</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission12"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="12"
                                    >
                                    <label
                                        for="permission12"
                                        class="checkbox-label"
                                    >Appoint owner</label>
                                </div>
                                <div class="checkbox-row">
                                    <input
                                        id="permission13"
                                        v-model="form.permissions"
                                        type="checkbox"
                                        value="13"
                                    >
                                    <label
                                        for="permission13"
                                        class="checkbox-label"
                                    >Demote owner</label>
                                </div>
                                <br><br>
                            </div>
                        </li>
                    </ul>
                    <primary-button
                        :disabled="isDisabled"
                        title="Save role"
                        @click.prevent="submitForm"
                    />
                </form>
            </div>
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import { useVuelidate } from "@vuelidate/core";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import {
        required,
    } from "@vuelidate/validators";
    export default {
        components: {
            PrimaryButton
        },
        props: {
            role: {
                type: Object,
                default: () => {},
            }
        },
        emits: ["roleUpdated"],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                //permissions: {},
                form: {
                    errors: {},
                    processing: false,
                    name: (this.role) ? this.role.name : "",
                    type: (this.role) ? this.role.type.id : "",
                    permissions: (this.role) ? this.role.permissions.map(function(a) {return a.id;}) : [],
                }
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        validations: {
            form: {
                errors: {},
                processing: false,
                name: {required},
                type: {required},
                permissions: {required}
            }
        },
        mounted() {
            //this.fetchPermissions();
        },
        
        methods: {
            // fetchPermissions() {
            //     apiClient.get('/permissions')
            //         .then(response => {
            //             this.permissions = response.data.data;
            //         });
            // },
            async submitForm() {
                let payload = Object.assign({}, this.form);
                delete payload.errors;
                delete payload.processing;
                
                this.form.errors = {};
                this.form.processing = true;
                

                let url = "roles";
                let method = "post";
                
                if (this.role) {
                    url = "roles/" + this.role.id;
                    method = "patch";
                }

                await apiClient.get( ApiService.getApiUrl() + "/sanctum/csrf-cookie");
                apiClient({
                    method: method,
                    url: url,
                    data: payload,
                })
                    .then(response => {
                        this.$emit("roleUpdated");
                    }).catch(error => {
                        this.form.errors = error.response.data.errors;
                    }).finally(() => {
                        this.form.processing = false;
                });
            }
        }
    };

</script>
<style scoped>
    .checkbox-row {
        width: 50%;
    }
</style>
