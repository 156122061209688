<template>
        <div>
            <ul class="notification-list">
                <li
                    v-for="notification in notifications"
                    :key="notification.id"
                    data-hj-suppress
                    :class="{ unread: notification.read }"
                    @click.stop="notificationRead(notification.id)"
                >
                    <a v-if="notification.url" :href="notification.url">{{ notification.message }}</a>
                    <span v-else>{{ notification.message }}</span>
                </li>
            </ul>
            <button
                class="dismiss-notifications"
                @click.stop="setNotificationsToRead"
            >
                Dismiss all
            </button>
        </div>
</template>
<script>
    export default {
        props: {
            showNotifications: {
                type: Boolean,
            },
            notifications: {
                type: Array,
                default: () => [],
            }
        },
        data() {
            return {
            };
        },
        methods: {
            setNotificationsToRead() {
                this.notifications.forEach((notification) => notification.read = 1);
                this.$store.commit("SET_NOTIFICATIONS", this.notifications);
            },
            notificationRead(id) {
                const objIndex = this.notifications.findIndex((notification => notification.id === id));
                this.notifications[objIndex].read = 1;
                this.$store.commit("SET_NOTIFICATIONS", this.notifications);
            }
        },
    };
</script>