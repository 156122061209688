<template>
        <button
            :type="type"
            class="secondary-btn"
        >
            <slot />
        </button>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: "button",
            },
        }
    };
</script>
