<template>
        <jet-authentication-card>
            <template #logo>
                <Logo />
            </template>


            <div class="login-form-wrapper">
                <form
                    class="main-form"
                    @submit.prevent="submit"
                >
                    <ul class="form-wrapper">
                        <li class="form-row">
                            <div
                                class="input-grp"
                                :class="{ 'input-error' : form.errors.email, 'input-filled' : !v$.form.email.$invalid }"
                            >
                                <label class="form-label">Email *</label>
                                <input
                                    data-hj-suppress
                                    id="email"
                                    v-model="form.email"
                                    type="email"
                                    class="form-input"
                                    autofocus
                                >
                                <span
                                    v-for="error in form.errors.email"
                                    :key="error"
                                    class="error-msg"
                                >{{ error }}</span>
                            </div>
                        </li>

                        <li class="form-row tooltip-show">
                            <div
                                class="input-grp"
                                :class="{ 'input-error' : form.errors.password, 'input-filled' : !v$.form.password.$invalid }"
                            >
                                <label class="form-label">Password *</label>
                                <input
                                    data-hj-suppress
                                    id="password"
                                    v-model="form.password"
                                    type="password"
                                    class="form-input"
                                    @focus="showPasswordTooltip = true"
                                    @blur="showPasswordTooltip = false"
                                >
                                <span class="error-msg">{{ form.errors.password }}</span>
                                <div
                                    v-show="showPasswordTooltip"
                                    class="info-tooltip"
                                >
                                    <ul class="password-hint">
                                        <li :class="{ 'checked': (!v$.form.password.minLength.$invalid && !v$.form.password.required.$invalid) }">
                                            min. 8 characters
                                        </li>
                                        <li :class="{ 'checked': (!v$.form.password.containsNumber.$invalid) }">
                                            min. 1  number
                                        </li>
                                        <li :class="{ 'checked': (!v$.form.password.containsUppercase.$invalid) }">
                                            min. 1 uppercase character
                                        </li>
                                        <li :class="{ 'checked': (!v$.form.password.containsLowercase.$invalid) }">
                                            min. 1 lowercase character
                                        </li>
                                        <li :class="{ 'checked': (!v$.form.password.containsSpecial.$invalid) }">
                                            min. 1 special character
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>

                        <li
                            class="form-row tooltip-show"
                            :class="{ 'input-error' : form.errors.password_confirmation, 'input-filled' : !v$.form.password_confirmation.$invalid }"
                        >
                            <div class="input-grp">
                                <label class="form-label">Confirm password *</label>
                                <input
                                    data-hj-suppress
                                    id="password_confirmation"
                                    v-model="form.password_confirmation"
                                    type="password"
                                    class="form-input"
                                >
                            </div>
                        </li>

                        <li class="form-row">
                            <jet-button
                                class="form-button"
                                :disabled="isDisabled"
                                :class="{ 'opacity-25': isDisabled }"
                            >
                                Reset Password
                            </jet-button>
                        </li>
                    </ul>
                </form>
            </div>
        </jet-authentication-card>
</template>

<script>
    import JetAuthenticationCard from "@/Jetstream/AuthenticationCard";
    import JetButton from "@/Jetstream/DefaultButton";
    import Logo from "@/components/LogoComponent";
    import { useVuelidate } from "@vuelidate/core";
    import {
        required,
        minLength,
        email,
    } from "@vuelidate/validators";
    import AuthService from "../../services/AuthService";

    export default {
        components: {
            JetAuthenticationCard,
            JetButton,
            Logo
        },
        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                showPasswordTooltip: false,
                form: {
                    processing: false,
                    errors: {},
                    token: this.$route.query.token,
                    email: this.$route.query.email,
                    password: "",
                    password_confirmation: "",
                }
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        validations: {
            form: {
                email: { required, email },
                password: { required,
                    valid: function(value) {
                        const containsUppercase = /[A-Z]/.test(value);
                        const containsLowercase = /[a-z]/.test(value);
                        const containsNumber = /[0-9]/.test(value);
                        const containsSpecial = /[#?!@$%^&*-]/.test(value);
                        return containsUppercase && containsLowercase && containsNumber && containsSpecial;
                    },
                    minLength: minLength(8),
                    containsUppercase: function(value) {
                        return /[A-Z]/.test(value);
                    },
                    containsLowercase: function(value) {
                        return /[a-z]/.test(value);
                    },
                    containsNumber: function(value) {
                        return /[0-9]/.test(value);
                    },
                    containsSpecial: function(value) {
                        return /[#?!@$%^&*-]/.test(value);
                    }
                },
                password_confirmation: { required,
                    sameAsPassword: function(value){
                        return this.form.password_confirmation === this.form.password;
                    }
                },
            },
        },
        methods: {
            submit() {
                const payload = {
                    "token": this.form.token,
                    "email": this.form.email,
                    "password": this.form.password,
                    "password_confirmation": this.form.password_confirmation
                };
                AuthService.resetPassword(payload)
                    .then((response) => {
                        // reset to login?resetPassword=true
                        this.$router.push({name: "login", query: {"resetpassword": true} });
                    })
                    .catch((error) => {
                        this.form.errors = error.response.data.errors;
                    }).finally(function() {
                        this.form.password = "";
                        this.form.password_confirmation = "";
                    });
            }
        }
    };
</script>
