<template>
        <jet-form-section @submitted="updateProfileInformation">
            <template #title>
                Update email
            </template>

            <template #description>
                Update your account's email address.
            </template>

            <template #form>
                <ul class="form-wrapper">
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors?.email, 'input-filled' : !v$.form.email.$invalid }"
                    >
                        <label
                            class="form-label"
                            for="email"
                            value="Email"
                        />
                        <input
                            data-hj-suppress
                            id="email"
                            ref="email"
                            v-model="form.email"
                            type="text"
                            class="form-input"
                        >
                        <span
                            v-for="error in form.errors?.email"
                            :key="error"
                            class="error-msg"
                        >{{ error }}</span>
                    </li>

                    <div
                        v-show="form.message && !form.errors?.email"
                        class="form-row input-error"
                    >
                        <span class="error-msg">{{ form.message }}</span>
                    </div>

                    <li class="form-row mt-4">
                        <input
                            class="form-button"
                            value="Save email"
                            type="submit"
                            :class="{ 'opacity-25': isDisabled }"
                            :disabled="isDisabled"
                        >
                    </li>
                </ul>
            </template>
        </jet-form-section>
</template>

<script>
    import AuthService from "@/services/AuthService";
    import { useVuelidate } from "@vuelidate/core";
    import {
        required,
        email
    } from "@vuelidate/validators";
    
    export default {
        props: {
            user: {
                type: Object,
                default: () => {},
            },
        },            
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                form: {
                    _method: "PUT",
                    processing: false,
                    errors: {},
                    name: this.user.name,
                    email: this.user.email,
                    photo: null,
                },

                photoPreview: null,
            };
        },
        computed: {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
        },
        validations: {
            form: {
                name: { required },
                email: { required, email },
            }
        },
        methods: {
            updateProfileInformation() {

                this.form.errors = {};
                this.form.processing = true;
                const payload = {name: this.form.name, email: this.form.email};
                AuthService.updateEmail(payload)
                    .then((response) => {
                        // update vuex user
                        this.$store.dispatch("getAuthUser").then(() => {
                            this.$router.push({name: "verify-email"});
                        });
                    })
                    .catch((error) => {
                        this.form.errors = error.response.data.errors;
                        this.form.message = error.response.data.message;
                        this.$refs.email.focus();
                    }).finally(() => {
                    this.form.processing = false;
                });
            },
        },
    };
</script>
