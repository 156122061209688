<template>
        <div class="button-row group min-h-24 bg-white mt-4 border border-[#efeff2] rounded-lg">
            <div
                v-if="!showEditDocument"
                class="w-full"
            >
                <div class="flex items-center w-full">
                    <div class="w-20 md:w-40 h-24 min-w-20 md:min-w-40 overflow-hidden flex justify-center items-center">
                        <img
                            v-if="file.thumbnail"
                            :src="file.thumbnail"
                            alt="document"
                            class="w-full h-full object-cover"
                        >
                        <img
                            v-else
                            src="../../../images/icon-document.svg"
                            alt="thumbnail"
                            class="w-10 h-10"
                        >
                    </div>
                    <div
                        v-if="!showDeleteDocument"
                        class="flex-grow flex"
                    >
                        <div class="font-bold ml-8 flex flex-col" data-hj-suppress>
                            <p class="mb-0 text-base">
                                {{ file.name }}
                            </p>
                            <p class="font-normal mb-0">
                                {{ "Added: "+formatDate(file.created_at) }}
                            </p>
                        </div>
                        <float-right-component>
                            <action-button
                                color="secondary"
                                icon="download"
                                @click="downloadDocument"
                            />
                            <action-button
                                color="primary"
                                icon="bin"
                                @click="showDeleteDocument = true"
                            />
                            <action-button
                                color="secondary"
                                icon="pencil"
                                @click="showEditDocument = !showEditDocument"
                            />
                        </float-right-component>
                    </div>
                    <div
                        v-else
                        class="flex justify-between items-center flex-grow bg-hover h-24"
                    >
                        <img
                            src="../../../images/icon-bin.svg"
                            alt="remove"
                            class="w-4 h-4 ml-7 mr-5"
                        >
                        <p class="text-xs md:text-base font-bold mb-0">
                            Are you sure you want to delete this document?
                        </p>
                        <div class="h-12 flex">
                            <secondary-button
                                title="YES"
                                @click="deleteDocument"
                            />
                            <div class="mr-5 w-16 ml-2">
                                <primary-button
                                    title="NO"
                                    @click="showDeleteDocument = false"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-else
                class="p-4"
            >
                <documents-form
                    :file="file"
                    @edited-document="editDocument"
                />
            </div>
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import DocumentsForm from "./DocumentsForm";
    import ActionButton from "@/components/form/button/ActionButton";
    import downloadBase64 from "@/helpers/downloadBase64";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import FloatRightComponent from "@/components/FloatRightComponent";

    export default {
        components: {
            DocumentsForm,
            ActionButton,
            SecondaryButton,
            PrimaryButton,
            FloatRightComponent
        },
        props: {
            file: {
                type: Object,
                default: null
            }
        },
        emits: {"updateDocumentList": null},
        data() {
            return {
                showEditDocument: false,
                showDeleteDocument: false,
            };
        },
        methods: {
            deleteDocument() {
                if (this.$store.getters.authUser?.id) {
                    apiClient.delete("/files/" + this.file.id)
                        .then(response => {
                            this.$emit("updateDocumentList");
                        }).catch((error) => {
                    });
                }
            },
            editDocument() {
                this.$emit("updateDocumentList",this.file.id);
                this.showEditDocument = false;
            },
            downloadDocument() {
                apiClient.get("/files/" + (this.file.id))
                    .then(response => downloadBase64(response.data.data));
            },
            formatDate(date) {
                if (!date) return "";
                const [year, month, day] = date.split("T")[0].split("-");
                return `${day}-${month}-${year}`;
            },
        }
    };
</script>