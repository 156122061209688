<template>
        <div class="data-display-part relative">
            <div
                v-if="tasks?.length"
                class="work-info education-info"
            >
                <tasks-item
                    v-for="item in (showAllTasks ? tasks : tasks?.slice(0, 3))"
                    :key="item.id"
                    :task="item"
                    @update-task-list="fetchTrajectoryTasks"
                />
            </div>
            <p
                v-else
                class="font-medium mb-0 text-base"
            >
                When added, your tasks will appear here
            </p>
            <div
                class="flex w-full justify-between"
                :class="tasks?.length > 3 && 'mt-5'"
            >
                <div
                    v-show="isCoach"
                    class="add-task common-add-btn extend-btn-info"
                    @click="showAddTask=!showAddTask"
                >
                    <h4>Add task</h4>
                </div>
                <button
                    v-if="tasks?.length > 3"
                    class="pt-6 font-semibold whitespace-nowrap"
                    style="font-size: 16px"
                    @click="showAllTasks = !showAllTasks"
                >
                    {{ showAllTasks ? '-' : '+' }}
                    <span class="underline ml-2">
                        View
                        {{ showAllTasks ? 'less' : 'all' }}
                        tasks
                    </span>
                </button>
            </div>
            <tasks-form
                v-show="showAddTask"
                @edited-task="fetchTrajectoryTasks"
            />
        </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import TasksItem from "./TasksItem";
    import TasksForm from "./TasksForm";

    export default {
        components: {
            TasksItem,
            TasksForm
        },
        props: {
            open: Boolean
        },
        data() {
            return {
                showAddTask: false,
                tasks: null,
                showAllTasks: false,
            };
        },
        computed: {
            ...mapGetters(["isCoach", "authUser", "isProfessional"]),
        },
        created() {
            this.fetchTrajectoryTasks();
        },
        methods: {
            fetchTrajectoryTasks() {
                apiClient.get("/trajectories/" + (this.isProfessional ? this.authUser.activeTrajectory?.id : this.$route.params.id) + "/tasks" + (this.open ? "?filter[completed]=0" : ""))
                    .then(response => {
                        this.tasks = response.data.data;
                        this.showAddTask = false;
                    });
            },
        }
    };
</script>
