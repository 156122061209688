<template>
        <section class="login-main-part">
            <div class="login-wrapper">
                <div class="login-area">
                    <two-factor-challenge v-if="showTwoFactorChallenge" />
                    <div
                        v-if="showLoginForm"
                        class="login-box"
                    >
                        <div
                            v-if="$route.query.resetpassword"
                            class="confirm-msg show"
                        >
                            <i><img
                                src="./../../images/icon-correct.svg"
                                alt="Correct"
                            ></i>Your password has been reset!
                        </div>
                        <div class="login-logo">
                            <Logo />
                        </div>
                        <div class="login-form-wrapper">
                            <form class="main-form">
                                <ul class="form-wrapper">
                                    <li
                                        class="form-row"
                                        :class="{ 'input-error' : (form.errors?.email) }"
                                    >
                                        <div class="input-grp">
                                            <label class="form-label">Email</label>
                                            <input
                                                data-hj-suppress
                                                id="email"
                                                v-model="form.email"
                                                class="form-input"
                                                type="text"
                                                autofocus
                                            >
                                            <span
                                                v-for="error in form.errors.email"
                                                v-show="form.errors?.email"
                                                :key="error"
                                                class="error-msg"
                                            >{{ error }}</span>
                                        </div>
                                    </li>
                                    <li
                                        class="form-row"
                                        :class="{ 'input-error' : (form.errors?.password)}"
                                    >
                                        <div class="input-grp">
                                            <label class="form-label">Password</label>
                                            <input
                                                data-hj-suppress
                                                id="password"
                                                v-model="form.password"
                                                class="form-input"
                                                type="password"
                                            >
                                            <span
                                                v-for="error in form.errors.password"
                                                v-show="form.errors?.password"
                                                :key="error"
                                                class="error-msg"
                                            >{{ error }}</span>
                                        </div>
                                    </li>
                           
                                    <li class="form-row">
                                        <primary-button
                                            :disabled="form.processing"
                                            title="Login"
                                            @click.prevent="login"
                                        />
                                    </li>
                                </ul>
                            </form>
                        </div>
                        <div class="form-footer">
                            <p>
                                Whoops.
                                <router-link :to="{ name: 'forgot-password' }">Forgot my password</router-link>
															<span class="text-sm m-0">🤦‍♀️</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
    import AuthService from "@/services/AuthService";
    import TwoFactorChallenge from "@/Pages/Auth/TwoFactorChallenge";
    import Logo from "@/components/LogoComponent";
    import PrimaryButton from "@/components/form/PrimaryButton";

    export default {
        components: {
            Logo,
            PrimaryButton,
            TwoFactorChallenge
        },
        data() {
            return {
                showTwoFactorChallenge: false,
                showLoginForm: true,
                form: {
                    message: "",
                    errors: {},
                    email: "",
                    password: "",
                    remember: false
                }
            };
        },

        methods: {
            async login() {
                try {
                    const payload = {
                        email: this.form.email,
                        password: this.form.password,
                        remember: this.form.remember
                    };
                    const loginResult = await AuthService.login(payload);
                    if (loginResult) {
                        this.showLoginForm = false;
                        if (loginResult.data.two_factor) {
                            this.showTwoFactorChallenge = true;
                            return;
                        }
                        this.$store.dispatch("setAppNotInitialized");

                        const authUser = await this.$store.dispatch("getAuthUser");
                        if (authUser) {

                            this.$store.dispatch("setGuest", { value: "isNotGuest" });
                            if (this.$route.query.redirect && !this.$route.query.redirect.includes("login")) {
                                this.$router.push(this.$route.query.redirect);
                            } else {
                                this.$router.push("/");
                            }
                        } else {
                            const error = Error(
                                "Unable to fetch user after login, check your API settings."
                            );
                            error.name = "Fetch User";
                            throw error;
                        }
                        
                    }
                } catch (error) {
                    this.form.message = error.response.data.message;
                    this.form.errors = error.response.data.errors;
                }
            }
        }
    };
</script>
