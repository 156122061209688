<template>
        <div class="h-24 mb-4 w-full text-white rounded-lg relative overflow-hidden">
            <img
                class="object-cover"
                src="../../images/banner.jpg"
                alt="banner"
            >
            <div class="w-full h-full absolute inset-0 text-sm flex justify-between items-center bg-black bg-opacity-50 px-3 sm:px-4 xl:px-9">
                <div
                    v-if="show('contentType')"
                    class="flex flex-col items-center h-16"
                >
                    <img
                        src="../../images/icon-elearning.svg"
                        alt="learning-item"
                    >
                    <p class="m-0 text-white">
                        Type
                    </p>
                    <h5 class="font-bold">
                        {{ learning.learning_type?.name }}
                    </h5>
                </div>
                <div
                    v-if="show('duration')"
                    class="flex flex-col items-center h-16"
                >
                    <img
                        src="../../images/icon-time-copy.svg"
                        alt="learning-item"
                        style="min-height: 24px"
                    >
                    <p class="m-0 text-white">
                        Duration
                    </p>
                    <h5 class="font-bold">
                        {{ `${learning.duration} minutes` }}
                    </h5>
                </div>
            </div>
        </div>
</template>

<script>
    import learningContent from "./lib/learningContent";

    export default {
        props: {
            learning: {
                type: Object,
                default: () => {}
            }
        },
        methods: {
            show(el) {
                return learningContent[this.learning.learning_type?.name]?.[el] || {};
            }
        },
    };
</script>
