<template>
        <div class="skills-row">
            <div
                v-show="!showEditSkill"
                class="flex w-full items-center group h-8 relative"
            >
                <label data-hj-suppress><span
                    class="percentage"
                    :class="'stars-'+(Math.round(skill.percentage / 10) * 10)"
                />{{ skill.skill }}</label>
                <float-right-component v-if="canEditSkill">
                    <action-button
                        color="secondary"
                        icon="pencil"
                        @click="showEditSkill = !showEditSkill"
                    />
                    <action-button
                        color="primary"
                        icon="bin"
                        @click.prevent="deleteSkill"
                    />
                </float-right-component>
            </div>
            <skill-form
                v-show="showEditSkill"
                :user="user"
                :skill="skill"
                @edited-skill="editSkill"
            />
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import SkillForm from "@/Pages/Profile/SkillForm";
    import ActionButton from "@/components/form/button/ActionButton";
    import FloatRightComponent from "@/components/FloatRightComponent";
    import moment from "moment";

    export default {
        components: {
            SkillForm,
            ActionButton,
            FloatRightComponent
        },
        props: {
            skill: {
                type: Object,
                default: () => {},
            },
            user: {
                type: Object,
                default: () => {},
            },
            canEditSkill: {
                type: Boolean,
            },
        },
        emits: ["updateSkillList"],
        data() {
            return {
                showEditSkill: false,
            };
        },
        methods: {
            deleteSkill() {
                if (this.$store.getters.authUser?.id) {
                    apiClient.delete("/users/" + this.$store.getters.authUser.id + "/skills/" + this.skill.id)
                        .then(response => {
                            this.$emit("updateSkillList");
                        }).catch((error) => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                        //this.form.errors = error.response.data.errors;
                    });
                }
            },
            editSkill() {
                this.$emit("updateSkillList");
                this.showEditSkill = false;
            }
        }
    };
</script>
