<template>
        <div class="education-info-row relative">
            <div
                v-show="!showEditExperience"
                class="flex w-full items-center group h-8"
            >
                <label data-hj-suppress>{{ formatMonthlyDate(experience.start_date) }} / {{ formatMonthlyDate(experience.end_date) }}</label>
                <p class="right mb-0" data-hj-suppress>
                    <strong>{{ experience.title }}, {{ experience.employment_type.name }}</strong><br>
                    {{ experience.company_location.company.name }}, {{ experience.company_location.country.name }}
                </p>
                <float-right-component 
                    v-if="canEditExperience"
                >
                    <action-button
                        color="secondary"
                        icon="pencil"
                        @click="showEditExperience = !showEditExperience"
                    />
                    <action-button
                        color="primary"
                        icon="bin"
                        @click.prevent="deleteExperience"
                    />
                </float-right-component>
            </div>

            <experience-form
                v-show="showEditExperience"
                :experience="experience"
                @edited-experience="editExperience"
            />
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import ExperienceForm from "@/Pages/Profile/ExperienceForm";
    import ActionButton from "@/components/form/button/ActionButton";
    import FloatRightComponent from "@/components/FloatRightComponent";
    import moment from "moment";
    import formatMonthlyDate from "@/helpers/formatMonthlyDate";

    export default {
        components: {
            ExperienceForm,
            ActionButton,
            FloatRightComponent
        },
        props: {
            experience: {
                type: Object,
                default: () => {},
            },
            canEditExperience: {
                type: Boolean,
            },
        },
        emits: ["updateExperienceList"],
        data() {
            return {
                showEditExperience: false,
            };
        },
        methods: {
            deleteExperience() {
                if (this.$store.getters.authUser?.id) {
                    apiClient.delete("/users/" + this.$store.getters.authUser.id + "/experiences/" + this.experience.id)
                        .then(response => {
                            this.$emit("updateExperienceList");
                        }).catch((error) => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                        //this.form.errors = error.response.data.errors;
                    });
                }
            },
            editExperience() {
                this.$emit("updateExperienceList");
                this.showEditExperience = false;
            },
            formatMonthlyDate(date) {
                return formatMonthlyDate(date);
            },
        }
    };
</script>
