<template>
        <router-link
            :to="{ name: toName }"
            class="md:absolute left-0 -top-20 py-2 flex items-center font-semibold mb-4 md:mb-10"
        >
            <i class="flex items-center mr-2 h-full"><img
                src="../../images/icon-left-arrow.svg"
                alt="icon"
            ></i>
            {{ title }}
        </router-link>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                required: true,
            },
            toName: {
                type: String,
                required: true,
            },
        },
    };

</script>