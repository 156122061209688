<template>
        <div class="education-info-row relative">
            <div
                v-show="!showEditEducation"
                class="flex w-full items-center group h-8 h-full"
            >
                <label data-hj-suppress>{{ formatMonthlyDate(education.start_date) }} / {{ formatMonthlyDate(education.end_date) }}</label>

                <p class="right mb-0" data-hj-suppress>
                    <strong>{{ education.name }} {{ education.university_location.city }}, {{ education.university_location.country.name }}</strong><br>
                    {{ education.university_fields_of_study.university.name }}, {{ education.university_fields_of_study.level_of_education.name }}<br>
                    <span v-if="((education.grade_avg_percentage/100)*10) > 0">Grade: {{ (education.grade_avg_percentage/100)*10 }}<br></span>
                    Field of study: {{ education.university_fields_of_study.field_of_study.name }}<br>
                </p>
                <float-right-component v-if="canEditEducation">
                    <action-button
                        color="secondary"
                        icon="pencil"
                        @click="showEditEducation = !showEditEducation"
                    />
                    <action-button
                        color="primary"
                        icon="bin"
                        @click="deleteEducation"
                    />
                </float-right-component>
            </div>

            <education-form
                v-show="showEditEducation"
                :education="education"
                @edited-education="editEducation"
            />
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import EducationForm from "@/Pages/Profile/EducationForm";
    import ActionButton from "@/components/form/button/ActionButton";
    import FloatRightComponent from "@/components/FloatRightComponent";
    import formatMonthlyDate from "@/helpers/formatMonthlyDate";

export default {
        components: {
            EducationForm,
            ActionButton,
            FloatRightComponent
        },
        props: {
            education: {
                type: Object,
                default: () => {},
            },
            canEditEducation: {
                type: Boolean,
            }
        },
        emits: ["updateEducationList"],
        data() {
            return {
                showEditEducation: false,
            };
        },
        methods: {
            deleteEducation() {

                if (this.$store.getters.authUser?.id) {
                    apiClient.delete("/users/" + this.$store.getters.authUser.id + "/educations/" + this.education.id)
                        .then(response => {
                            this.$emit("updateEducationList");
                        }).catch((error) => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                        //this.form.errors = error.response.data.errors;
                    });
                }
            },
            editEducation() {
                this.$emit("updateEducationList",this.education.id);
                this.showEditEducation = false;
            },
            formatMonthlyDate(date) {
                return formatMonthlyDate(date);
            },
        }
    };
</script>
