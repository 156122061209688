import router from "../router";

export default function verified({ next, store }) {
    const authUser = store.getters["authUser"];
    if (authUser) {
        if (authUser.email_verified === true) {
            next();
        } else {
            router.push({path: "/verify-email"});
        }
    }
}