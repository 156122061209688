<template>
        <div class="content-text-part body-text">
            <h2 class="bottom-shape">
                My Learnings
            </h2>
            <h3>Soon to be launched: Online Trainings in Marketing, Communications & Data</h3>
            <p>
                <span v-if="theme === 'youngheroes'">Young Heroes</span><span v-if="theme === 'yfk'">My YFK</span> is your Coaching and Career Network, where it is all about growing and developing into the best professional you can be. One vital part of such growth? Training, training, training!<br>
                <br>


                Therefore, we will soon launch a great library of different trainings. These interactive classes, tutorials and e-learning courses will focus on hard and soft skills that are vital to succeed in your career. <br>
                We would love to hear from you, so choose your top six hard and soft skill trainings below:<br>
                <br>
            </p>
            <div class="common-card">
                <form class="emailform" data-hj-suppress>
                    <p><b>Our Hard Skill Library</b></p>
                    <div class="checkbox-row">
                        <input
                            id="learnings_1"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Online Marketing"
                        >
                        <label
                            for="learnings_1"
                            class="checkbox-label"
                        >Online Marketing</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_2"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="SEO"
                        >
                        <label
                            for="learnings_2"
                            class="checkbox-label"
                        >SEO</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_3"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Social Media"
                        >
                        <label
                            for="learnings_3"
                            class="checkbox-label"
                        >Social Media</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_4"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Content Marketing"
                        >
                        <label
                            for="learnings_4"
                            class="checkbox-label"
                        >Content Marketing</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_5"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Google Analystic/AdWords/Tag Manager"
                        >
                        <label
                            for="learnings_5"
                            class="checkbox-label"
                        >Google Analystic/AdWords/Tag Manager</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_6"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Brand Management"
                        >
                        <label
                            for="learnings_6"
                            class="checkbox-label"
                        >Brand Management</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_7"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Customer Journey"
                        >
                        <label
                            for="learnings_7"
                            class="checkbox-label"
                        >Customer Journey</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_8"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Data Visualization and Dashboarding"
                        >
                        <label
                            for="learnings_8"
                            class="checkbox-label"
                        >Data Visualization and Dashboarding</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_9"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Business Intelligence Tooling"
                        >
                        <label
                            for="learnings_9"
                            class="checkbox-label"
                        >Business Intelligence Tooling</label>
                    </div>
                    <div class="mt-5 mb-4">
                        <b>Our Soft Skill Library</b>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_10"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Project Management"
                        >
                        <label
                            for="learnings_10"
                            class="checkbox-label"
                        >Project Management</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_11"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Agile/Scrum"
                        >
                        <label
                            for="learnings_11"
                            class="checkbox-label"
                        >Agile/Scrum</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_12"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Design-Based Thinking"
                        >
                        <label
                            for="learnings_12"
                            class="checkbox-label"
                        >Design-Based Thinking</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_13"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Time Management"
                        >
                        <label
                            for="learnings_13"
                            class="checkbox-label"
                        >Time Management</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_14"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Personal Brand"
                        >
                        <label
                            for="learnings_14"
                            class="checkbox-label"
                        >Personal Brand</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_15"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="Negotiation"
                        >
                        <label
                            for="learnings_15"
                            class="checkbox-label"
                        >Negotiation</label>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="learnings_16"
                            v-model="form.answer"
                            type="checkbox"
                            class="mr-2"
                            value="DISC Communication"
                        >
                        <label
                            for="learnings_16"
                            class="checkbox-label"
                        >DISC Communication</label>
                    </div>
                    <br>
                    <p>
                        We will let you know personally, when we will launch the first courses! So stay tuned. 🤘
                    </p>
                    <div
                        class="form-row"
                        :class="{ 'input-error' : (form.errors?.answer) }"
                    >
                        <span
                            v-for="msg in form.errors?.answer"
                            :key="msg"
                            class="error-msg mb-3"
                        >{{ msg }}</span>
                    </div>
                    <secondary-button
                        :disabled="form.processing"
                        title="Vote now"
                        @click.prevent="submitForm"
                    />
                    <div
                        v-if="voted"
                        class=""
                    >
                        <strong>Thank you for voting! Your vote has been registered.</strong>
                    </div>
                </form>
            </div>
        </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import store from "@/store";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";

    export default {
        components: {
            SecondaryButton,
        },
        data() {
            return {
                voted: false,
                form: {
                    processing: false,
                    errors: {},
                    question: "My learnings",
                    answer: []
                },
            };
        },
        validations: {
            form: {
                question: {required},
                answer: {required}
            }
        },
        computed: {
            ...mapGetters(["authUser", "isAdmin", "theme"]),
        },
        methods: {
            async submitForm() {
                this.form.processing = true;
                this.form.errors = {};
                await apiClient.get( ApiService.getApiUrl() + "/sanctum/csrf-cookie");
                apiClient({
                    method: "POST",
                    url: "/form/answer",
                    data: {
                        question: this.form.question,
                        answer: this.form.answer,
                    }
                })
                .then(response => {
                    this.form.processing = false;
                    this.voted = true;
                })
                .catch(error => {
                    this.form.processing = false;
                    this.form.errors = (error.response.data.errors) ? error.response.data.errors : {};
                });
            }
        }
    };
</script>
