<template>
        <div>
            <jet-dialog-modal
                :show="showShareEmailModal"
                @close="closeModal"
            >
                <template #title>
                    <p v-if="theme === 'youngheroes'">
                        Share your Young Hero experience with a friend
                    </p>
                    <p v-if="theme === 'yfk'">
                        Share your YFK experience with a friend
                    </p>
                </template>

                <template #content>
                    <div v-show="showConfirmed">
                        <p v-if="theme === 'youngheroes'">
                            Email send. Thank you for telling them about Young Heroes.
                        </p>
                        <p v-if="theme === 'yfk'">
                            Email send. Thank you for telling them about YFK.
                        </p>
                    </div>
                    <div v-show="!showConfirmed">
                        <p v-if="theme === 'youngheroes'">
                            Add your friend’s email address and tell them about Young Heroes.
                        </p>
                        <p v-if="theme === 'yfk'">
                            Add your friend’s email address and tell them about YFK.
                        </p>

                        <div class="mt-4">
                            <div
                                class="form-row"
                                :class="{ 'input-error' : (form.errors.email || form.errors.invalid)}"
                            >
                                <div class="input-grp">
                                    <jet-label
                                        for="email"
                                        class="form-label"
                                        value="Email"
                                    />
                                    <jet-input
                                        data-hj-suppress
                                        ref="emailRef"
                                        v-model="form.email"
                                        type="email"
                                        class="form-input float-none"
                                    />
                                    <span
                                        v-for="msg in form.errors.email"
                                        :key="msg"
                                        class="error-msg"
                                    >{{ msg }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <template #footer>
                    <div
                        v-show="showConfirmed"
                        class="text-center"
                    >
                        <button
                            v-show="showConfirmed"
                            class="secondary-btn"
                            @click="closeModal"
                        >
                            Close
                        </button>
                    </div>

                    <div v-show="!showConfirmed">
                        <button
                            class="secondary-btn"
                            @click="closeModal"
                        >
                            Cancel
                        </button>

                        <button
                            class="edit-btn"
                            :class="{ 'opacity-25': form.processing }"
                            :disabled="form.processing"
                            @click="submitForm()"
                        >
                            Send
                        </button>
                    </div>
                </template>
            </jet-dialog-modal>

            <a
                href="#"
                @click.prevent="openModal()"
            >
                <svg
                    width="27px"
                    height="20px"
                    viewBox="0 0 27 20"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                    <title>icon-mail</title>
                    <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                    >
                        <g
                            id="icon-mail"
                            transform="translate(0.269231, 0.125000)"
                            fill="#FB5E89"
                            fill-rule="nonzero"
                        >
                            <path
                                id="Shape"
                                d="M22.6153846,0 L3.23076923,0 C2.33893781,0 1.57752722,0.315501719 0.946514281,0.94651276 C0.315501643,1.5775276 0,2.33893629 0,3.23076923 L0,16.1538462 C0,17.0456776 0.315501643,17.8070882 0.946514281,18.4381011 C1.57752722,19.0691137 2.33893781,19.3846154 3.23076923,19.3846154 L22.6153846,19.3846154 C23.5072176,19.3846154 24.2686262,19.0691137 24.8996411,18.4381011 C25.5306521,17.8070882 25.8461538,17.0456776 25.8461538,16.1538462 L25.8461538,3.23076923 C25.8461538,2.33893629 25.5306521,1.5775276 24.8996411,0.94651276 C24.2686262,0.315501719 23.5072176,0 22.6153846,0 Z M23.4230769,1.61538462 L12.9230769,9.69230769 L2.42307692,1.61538462 L23.4230769,1.61538462 Z M1.61538462,16.1538462 L1.61538462,3.23076923 L8.88461538,8.88461538 L1.69110586,16.6081729 C1.67427888,16.5408649 1.65745189,16.4693513 1.6406249,16.3936297 C1.62379792,16.3179084 1.61538462,16.2379811 1.61538462,16.1538462 Z M2.87740366,17.7187502 L10.1718755,9.9699524 L12.9230769,12.039663 L15.5733187,9.94471059 L22.968751,17.7187502 C22.9350977,17.7355772 21.8750101,17.7439905 19.7884615,17.7439905 C17.7019129,17.7439905 15.4134755,17.7439905 12.9230769,17.7439905 C10.4326784,17.7439905 8.1442409,17.7439905 6.05769231,17.7439905 C3.97114371,17.7439905 2.91105801,17.7355772 2.87740366,17.7187502 Z M24.2307692,16.1538462 C24.2307692,16.2379811 24.222354,16.3179084 24.2055274,16.3936297 C24.1887008,16.4693513 24.1718742,16.5408649 24.1550476,16.6081729 L16.9615385,8.88461538 L24.2307692,3.23076923 L24.2307692,16.1538462 Z"
                            />
                        </g>
                    </g>
                </svg>
            </a>
        </div>
</template>
<script>
    import JetDialogModal from "@/Jetstream/DialogModal";
    import JetInput from "@/Jetstream/InputField";
    import JetLabel from "@/Jetstream/InputLabel";
    import {default as ApiService, apiClient } from "@/services/ApiService";

    export default {
        components: {
            JetDialogModal,
            JetInput,
            JetLabel
        },
        emits: ["mailSend"],
        data() {
            return {
                showConfirmed: false,
                showShareEmailModal: false,
                form: {
                    processing: false,
                    errors: {},
                    email: "",
                }
            };
        },
        methods: {
            async submitForm() {
                this.form.processing = true;


                await apiClient.get( ApiService.getApiUrl() + "/sanctum/csrf-cookie");
                apiClient.post("users/share", {
                    email: this.form.email,
                }).then(() => {
                    this.form.processing = false;
                    this.showConfirmed = true;
                }).catch(error => {
                    this.form.processing = false;
                    this.form.errors = error.response.data.errors;
                    this.$refs.emailRef.focus();
                });

            },
            openModal() {
                this.showShareEmailModal=true;
                setTimeout(() => this.$refs.emailRef.focus(), 250);
            },
            closeModal() {
                this.form.processing = false;
                this.form.errors = {};
                this.form.email = "";
                this.showShareEmailModal = false;
                this.showConfirmed = false;
            },
        }
    };
</script>