<template>
        <div>
            <h2 class="w-auto">
                Vacancies
            </h2>
            <div class="right-col mb-4">
                <secondary-button
                    v-if="showVacancyForm"
                    title="Back to overview"
                    @click.prevent="activeVacancy=null;showVacancyForm=false;"
                />
                <secondary-button
                    v-if="!showVacancyForm"
                    title="Create Vacancy"
                    @click.prevent="activeVacancy=null;showVacancyForm=true;"
                />
            </div>
   
            <vacancy-form
                v-if="showVacancyForm"
                :active-vacancy="activeVacancy"
                @vacancy-updated="activeVacancy=null;showVacancyForm=false;fetchVacancies();"
            />
    
            <section
                v-if="!showVacancyForm"
                class="vacancy-highlights"
            >
                <div class="block pr-4">
                    <div class="title">
                        Type of work
                    </div>
                    <select
                        v-model="filters['work_type']"
                        class="form-input mt-3"
                        @change="doFilter"
                    >
                        <option value="">
                            All
                        </option>
                        <option
                            v-for="work_type in work_types"
                            :key="work_type"
                            :value="work_type.id"
                        >
                            {{ work_type.name }}
                        </option>
                    </select>
                </div>
                <div class="block pr-4">
                    <div class="title">
                        Education
                    </div>
                    <select
                        v-model="filters['education_level']"
                        class="form-input mt-3"
                        @change="doFilter"
                    >
                        <option value="">
                            All
                        </option>
                        <option
                            v-for="education_level in education_levels"
                            :key="education_level.id"
                            :value="education_level.id"
                        >
                            {{ education_level.name }}
                        </option>
                    </select>
                </div>
                <div class="block">
                    <div class="title">
                        Type of employment
                    </div>
                    <select
                        v-model="filters['employment_type']"
                        class="form-input mt-3"
                        @change="doFilter"
                    >
                        <option value="">
                            All
                        </option>
                        <option
                            v-for="employmentType in employmentTypes"
                            :key="employmentType.id"
                            :value="employmentType.id"
                        >
                            {{ employmentType.name }}
                        </option>
                    </select>
                </div>
            </section>

            <section
                v-if="vacancies.data && !showVacancyForm"
                class="vacancies-table group"
            >
                <div
                    v-for="vacancy in vacancies.data"
                    :key="vacancy"
                    class="vacancy-row"
                >
                    <div class="image">
                        <img
                            v-for="image in vacancy.images.slice(0, 1)"
                            :key="image"
                            :src="image.link"
                        >
                    </div>
                    <div class="partial-left">
                        <div class="title flex relative">
                            <router-link :to="{ name: 'vacancy', params: {vacancy: vacancy.id } }">
                                {{ vacancy.title }}
                            </router-link>

                            <float-right-component>
                                <action-button
                                    color="secondary"
                                    icon="pencil"
                                    @click.prevent="selectVacancy(vacancy)"
                                />
                                <action-button
                                    color="primary"
                                    icon="bin"
                                    @click.prevent="deleteVacancy(vacancy)"
                                />
                            </float-right-component>
                        </div>
                        <div class="work-types">
                            <span
                                v-for="(work_type, index) in vacancy.work_types"
                                :key="index"
                            >
                                <span class="name">{{ work_type.name }}</span><span
                                    v-if="index+1 < vacancy.work_types.length"
                                    class="separator"
                                >/</span>
                            </span>
                        </div>
                        <div
                            class="summary"
                            v-html="vacancy.summary"
                        />
                        <router-link
                            :to="{ name: 'vacancy', params: {vacancy: vacancy.id } }"
                            class="text-link"
                        >
                            <span>Read more</span>
                        </router-link>
                    </div>
                    <div class="partial-right">
                        <div class="block employment-type">
                            <div><span class="image" />{{ vacancy.employment_type.name }}</div>
                        </div>
                        <div class="block education-level">
                            <div><span class="image" />{{ vacancy.education_level.name }}</div>
                        </div>
                        <div class="block location">
                            <div><span class="image" />{{ vacancy.company_location.city }}</div>
                        </div>
                    </div>
                </div>
            </section>
            <section v-if="vacancies.data?.length === 0">
                No vacancies found.
            </section>

<section>
            <BasePagination
                v-if="!activeVacancy && vacancies.meta && vacancies.meta.last_page > 1"
                :queryParams="filters"
                :meta="vacancies.meta"
                :links="vacancies.links"
                @changeRoute="changeRoute"
            />
</section>
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import BasePagination from "@/components/BasePagination";
    import VacancyForm from "@/Pages/VacancyForm";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import ActionButton from "@/components/form/button/ActionButton";
    import FloatRightComponent from "@/components/FloatRightComponent";

    export default {
        components: {
            BasePagination,
            VacancyForm,
            SecondaryButton,
            ActionButton,
            FloatRightComponent
        },
        data() {
            return {
                showVacancyForm: false,
                activeVacancy: null,
                queryParams: [],
                filters: {"employment_type": "", "education_level": "", "work_type": ""},
                vacancies: {},
                employmentTypes: [],
                education_levels: [],
                work_types: []
            };
        },

        created() {
            this.fetchVacancies();
            this.fetchWorkTypes();
            this.fetchEmploymentTypes();
            this.fetchEducationLevels();
        },
        methods: {
            changeRoute(queryParams) {
                this.queryParams = Object.assign({}, queryParams);
                this.fetchVacancies();
            },
            doFilter() {
            


                let query = this.queryParams;

                if (this.filters["work_type"] !== "") {
                    query["filters[work_type]"] = this.filters["work_type"];
                } else {
                    delete query["filter[work_type]"];
                }
                if (this.filters["employment_type"] !== "") {
                    query["filter[employment_type]"] = this.filters["employment_type"];
                } else {
                    delete query["filter[employment_type]"];
                }
                if (this.filters["education_level"] !== "") {
                    query["filter[education_level]"] = this.filters["education_level"];
                } else {
                    delete query["filter[education_level]"];
                }
                if ("page" in query) {
                    // reset the page when filtering
                    query["page"] = 1;
                }

                console.log("filter vacancies", query);
              
                console.log(this.filters);
                this.changeRoute(query);
            },
            fetchVacancies(queryParams) {
                // scroll to top of page
                window.scrollTo(0,0);

                let query = Object.assign({}, this.queryParams || this.$route.query);
                
                return apiClient.get("vacancies", { params: query } )
                    .then(response => {
                        this.vacancies = response.data;
                    });
            },
            fetchWorkTypes() {
                apiClient.get("/work-types")
                    .then(response => {
                        this.work_types = response.data;
                    });
            },
            fetchEmploymentTypes() {
                apiClient.get("/employment-types")
                    .then(response => {
                        this.employmentTypes = response.data;
                    });
            },
            fetchEducationLevels() {
                apiClient.get("/education-levels")
                    .then(response => {
                        this.education_levels = response.data;
                    });
            },
            selectVacancy(vacancy) {
                return apiClient.get("vacancies/"+vacancy.id )
                    .then(response => {
                        // scroll to top of page
                        window.scrollTo(0,0);
                        this.showVacancyForm = true;
                        this.activeVacancy = response.data.data;
                    });
            },
            deleteVacancy(vacancy) {
                return apiClient.delete("vacancies/"+vacancy.id )
                    .then(response => {
                        this.showVacancyForm = false;
                        this.activeVacancy = null;
                        this.fetchVacancies();
                    });
            }
        },
    };
</script>