<template>
        <div class="w-4 h-4 bg-primary text-white group rounded-full relative z-10 flex text-xs justify-center items-center">
            i
            <div :class="['info-tooltip', arrow, 'left-1/2 transform -translate-x-1/2 right-auto bottom-full top-auto mb-3 group-hover:block']">
                <p>{{ title }}</p>
                <p>{{ content }}</p>
            </div>
        </div>
</template>
<script>
    export default {
        props: {
            content:{
                type: String,
                default: "",
            },
            title:{
                type: String,
                default: "",
            },
            arrow: {
                type: String,
                default: ""
            }
        }
    };
</script>