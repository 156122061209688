<template>
        <div class="flex font-medium text-sm md:text-lg mb-12 items-center">
            <i class="flex items-center mt-1"><img
                src="./../images/icon-calender-dark.svg"
                alt="icon"
            ></i>
            <div class="ml-3">
                Start date:
                <span class="font-bold">{{ formatDate(startDate) }}</span>
            </div>
            <div class="ml-6">
                End date:
                <span class="font-bold">{{ formatDate(endDate) }}</span>
            </div>
        </div>
</template>

<script>
    export default {
        props: {
            startDate: {
                type: String,
                default: "",
            },
            endDate: {
                type: String,
                default: ""
            },
        },
        methods: {
            formatDate(date) {
                if (!date) return "";
                const [year, month, day] = date.split("-");
                return `${day}-${month}-${year}`;
            },
        }
    };
</script>