<template>
        <div
            v-if="!showEditTask && !showDeleteTask"
            class="button-row group flex items-center w-full border-b"
            style="min-height: 56px"
        >
            <check-box
                :disabled="!isCoach"
                :checked="task.completed"
                @click="updateTask"
            />

            <div
                id="insertHtml"
                class="flex items-center justify-between w-full ml-3"
            >
                <div
                    data-hj-suppress
                    class="py-2 insert-html"
                    :class="task.completed && 'line-through'"
                    v-html="htmlWithLinks"
                />
                <float-right-component v-if="isCoach">
                    <action-button
                        color="secondary"
                        icon="pencil"
                        @click="showEditTask = !showEditTask"
                    />
                    <action-button
                        color="primary"
                        icon="bin"
                        @click="showDeleteTask = !showDeleteTask"
                    />
                </float-right-component>
            </div>
        </div>
        <div
            v-else
            class="border-t"
        >
            <div
                v-if="showDeleteTask"
                class="flex justify-between items-center flex-grow bg-hover h-24"
            >
                <img
                    src="../../../images/icon-bin.svg"
                    alt="remove"
                    class="w-4 h-4 ml-7 mr-5"
                >
                <p class="text-xs md:text-base font-bold mb-0 w-[900px]">
                    Are you sure you want to delete this task?
                </p>
                <div class="h-12 flex">
                    <secondary-button
                        title="YES"
                        @click="deleteTask"
                    />
                    <div class="mr-5 w-16 ml-2">
                        <primary-button
                            title="NO"
                            @click="showDeleteTask = false"
                        />
                    </div>
                </div>
            </div>
            <tasks-form
                v-else
                :task="task"
                @edited-task="editTask"
            />
        </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import TasksForm from "./TasksForm";
    import CheckBox from "@/components/form/CheckBox";
    import ActionButton from "@/components/form/button/ActionButton";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import FloatRightComponent from "@/components/FloatRightComponent";

    export default {
        components: {
            TasksForm,
            CheckBox,
            ActionButton,
            SecondaryButton,
            PrimaryButton,
            FloatRightComponent,
        },
        props: {
            task: {
                type: Object,
                default: () => {},
            }
        },
        emits: ["updateTaskList"],
        data() {
            return {
                showEditTask: false,
                showDeleteTask: false,
                form: {
                    completed: false,
                },
            };
        },
        computed: {
            ...mapGetters(["isCoach"]),
            htmlWithLinks() {
                return this.task.task.replaceAll("href=\"", "target=\"_blank\" href=\"//").replaceAll("https://", "");
            }
        },
        methods: {
            updateTask() {
                apiClient({
                    method: "put",
                    url: "/tasks/" + this.task.id,
                    data: {
                        id: this.task.id,
                        task: this.task.task,
                        completed: !this.task.completed,
                    }
                }).then(response => {
                    this.$emit("updateTaskList");
                }).catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error.response);
                });
            },
            deleteTask() {
                apiClient.delete("/tasks/" + this.task.id)
                    .then(response => {
                        this.$emit("updateTaskList");
                    }).catch((error) => {
                });
            },
            editTask() {
                this.$emit("updateTaskList");
                this.showEditTask = false;
            },
        }
    };
</script>
