<template>
        <div>
            <form>
                <ul class="form-wrapper">
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.title, 'input-filled' : !v$.form.title.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                class="form-label mt-4"
                                for="title"
                                value="Title *"
                            />
                            <jet-input
                                data-hj-suppress
                                id="title"
                                v-model="form.title"
                                type="text"
                                class="form-input"
                            />
                            <span
                                v-show="form.errors.title"
                                class="error-msg"
                            >{{ form.errors.title }}</span>
                        </div>
                    </li>

                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.employment_type, 'input-filled' : !v$.form.employment_type.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                class="form-label"
                                for="employment_type"
                                value="Employment type *"
                            />
                            <select
                                data-hj-suppress
                                id="employment_type"
                                v-model="form.employment_type"
                                class="form-input"
                                required
                            >
                                <option
                                    v-for="employmentType in employmentTypes"
                                    :key="employmentType.id"
                                    :value="employmentType.id"
                                >
                                    {{ employmentType.name }}
                                </option>
                            </select>
                            <span
                                v-show="form.errors.employment_type"
                                class="error-msg"
                            >{{ form.errors.employment_type }}</span>
                        </div>
                    </li>

                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.company, 'input-filled' : !v$.form.company.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                class="form-label"
                                for="company"
                                value="Company *"
                            />
                            <jet-input
                                data-hj-suppress
                                id="company"
                                v-model="form.company"
                                type="text"
                                class="form-input"
                            />
                            <span
                                v-show="form.errors.company"
                                class="error-msg"
                            >{{ form.errors.company }}</span>
                        </div>
                    </li>
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.country_id, 'input-filled' : !v$.form.country_id.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                class="form-label"
                                for="country_id"
                                value="Country *"
                            />
                            <select
                                data-hj-suppress
                                id="country_id"
                                v-model="form.country_id"
                                class="form-input"
                                required
                                autocomplete="country_id"
                            >
                                <option
                                    disabled
                                    value=""
                                >
                                    Please select
                                </option>
                                <option
                                    v-for="country in countries"
                                    :key="country.id"
                                    :value="country.id"
                                >
                                    {{ country.name }}
                                </option>
                            </select>
                            <span
                                v-show="form.errors.country_id"
                                class="error-msg"
                            >{{ form.errors.country_id }}</span>
                        </div>
                    </li>
                    <li
                        class="form-row"
                        :class="{ 'input-error' : form.errors.city, 'input-filled' : !v$.form.city.$invalid}"
                    >
                        <div class="input-grp">
                            <jet-label
                                class="form-label"
                                for="city"
                                value="City *"
                            />
                            <jet-input
                                data-hj-suppress
                                id="city"
                                v-model="form.city"
                                type="text"
                                class="form-input"
                            />
                            <span
                                v-show="form.errors.city"
                                class="error-msg"
                            >{{ form.errors.city }}</span>
                        </div>
                    </li>
                    <li class="form-row checkbox-input">
                        <div class="checkbox-grp">
                            <div class="checkbox-row">
                                <input
                                    :id="(typeof this.experience != 'undefined')?'hideEndDateFields_'+this.experience?.id:'hideEndDateFields'"
                                    v-model="form.current_job"
                                    type="checkbox"
                                >
                                <label
                                    :for="(typeof this.experience != 'undefined')?'hideEndDateFields_'+this.experience?.id:'hideEndDateFields'"
                                    class="checkbox-label"

                                >This is my current Job</label>
                            </div>
                        </div>
                    </li>
                    <li class="form-row two-input date-input">
                        <div class="input-grp">
                            <label class="form-label">Start date *</label>
                            <div
                                class="inner-input"
                                :class="{ 'input-error' : form.errors.start_date_month, 'input-filled' : !v$.form.start_date_month.$invalid }"
                            >
                                <select
                                    data-hj-suppress
                                    v-model="form.start_date_month"
                                    class="form-input"
                                >
                                    <option value="">
                                        Month
                                    </option>
                                    <option
                                        v-for="month in months"
                                        :key="month"
                                        :value="month"
                                    >
                                        {{ month }}
                                    </option>
                                </select>
                            </div>
                            <div
                                class="inner-input"
                                :class="{ 'input-error' : form.errors.start_date_year, 'input-filled' : !v$.form.start_date_year.$invalid }"
                            >
                                <select
                                    data-hj-suppress
                                    v-model="form.start_date_year"
                                    class="form-input"
                                >
                                    <option value="">
                                        Year
                                    </option>
                                    <option
                                        v-for="year in years"
                                        :key="year"
                                        :value="year"
                                    >
                                        {{ year }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="input-grp">
                            <label class="form-label">End date</label>
                            <div
                                v-if="form.current_job"
                                class="inner-input"
                            >
                                Current
                            </div>
                            <div
                                v-if="!form.current_job"
                                class="inner-input"
                                :class="{ 'input-error' : form.errors.end_date_month, 'input-filled' : !v$.form.end_date_month.$invalid }"
                            >
                                <select
                                    data-hj-suppress
                                    v-model="form.end_date_month"
                                    class="form-input"
                                >
                                    <option value="">
                                        Month
                                    </option>
                                    <option
                                        v-for="month in months"
                                        :key="month"
                                        :value="month"
                                    >
                                        {{ month }}
                                    </option>
                                </select>
                            </div>
                            <div
                                v-if="!form.current_job"
                                class="inner-input"
                                :class="{ 'input-error' : form.errors.end_date_year, 'input-filled' : !v$.form.end_date_year.$invalid }"
                            >
                                <select
                                    data-hj-suppress
                                    v-model="form.end_date_year"
                                    class="form-input"
                                >
                                    <option value="">
                                        Year
                                    </option>
                                    <option
                                        v-for="year in years"
                                        :key="year"
                                        :value="year"
                                    >
                                        {{ year }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </li>
                    <li class="form-row">
                        <primary-button
                            :title="experience ? 'Save experience' : 'Save and add experience'"
                            :disabled="isDisabled"
                            @click.prevent="submitForm"
                        />
                    </li>
                </ul>
            </form>
        </div>
</template>
<script>
    import JetInput from "@/Jetstream/InputField";
    import JetLabel from "@/Jetstream/InputLabel";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import moment from "moment";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import { useVuelidate } from "@vuelidate/core";
    import {
        integer,
        required,
    } from "@vuelidate/validators";

    export default {
        components: {
            JetInput,
            JetLabel,
            PrimaryButton,
        },
        props: {
            user: {
                type: Object,
                default: () => {},
            },
            experience: {
                type: Object,
                default: () => {},
            }
        },
        emits: ["editedExperience"],
        setup: () => ({ v$: useVuelidate({$scope: false}) }),
        data() {
            return {
                countries: {},
                employmentTypes: {},
                form: {
                    processing: false,
                    errors: {},
                    title: (this.experience)?this.experience.title:null,
                    employment_type: (this.experience)?this.experience.employment_type.id:1,
                    company: (this.experience)?this.experience.company_location.company.name:null,
                    country_id: (this.experience)?this.experience.company_location.country_id:null,
                    city: (this.experience)?this.experience.company_location.city:null,
                    current_job: (this.experience)?this.isCurrentJob():true,
                    start_date_month: (this.experience)?this.experience.start_date_month:null,
                    start_date_year: (this.experience)?this.experience.start_date_year:null,
                    end_date_month: (this.experience)?this.experience.end_date_month:null,
                    end_date_year: (this.experience)?this.experience.end_date_year:null,
                }
            };
        },
        validations: {
            form: {
                title: {required},
                employment_type: {required},
                company: {required},
                country_id: {required},
                city: {required},
                start_date_month: {integer, required},
                start_date_year: {integer, required},
                end_date_month: {integer},
                end_date_year: {integer},
            }
        },
        computed : {
            isDisabled() {
                return this.v$.$invalid || this.form.processing;
            },
            months() {
                return Array.from({length: 12 }, (value, index) => 1 + index);
            },
            years() {
                const year = new Date().getFullYear();
                const start_year = year-50;
                // array from 50 years ago until 6 years from now
                return Array.from({length: year - start_year + 6}, (value, index) => start_year + 1 + index);
            },

        },
        created() {
            this.fetchCountries();
            this.fetchEmploymentTypes();
        },
        methods: {
            fetchCountries() {
                apiClient.get("/countries")
                    .then(response => {
                        this.countries = response.data;
                    });
            },
            fetchEmploymentTypes() {
                apiClient.get("/employment-types")
                    .then(response => {
                        this.employmentTypes = response.data;
                    });
            },
            isCurrentJob() {
                if (typeof this.experience !== "undefined") {
                    if (this.experience.end_date) {
                        let end_date = moment(this.experience.end_date);
                        return end_date.diff(moment()) >= 0;
                    }
                }
                return true;
            },
            submitForm() {

                if(this.experience){
                    var url = "/users/" + this.$store.getters.authUser.id + "/experiences/"+this.experience.id;
                    var method = "put";
                }else{
                    var url = "/users/" + this.$store.getters.authUser.id + "/experiences";
                    var method = "post";
                }

                apiClient({
                    method: method,
                    url: url,
                    data: {
                        id: this.experience?.id,
                        title: this.form.title,
                        employment_type: this.form.employment_type,
                        company: this.form.company,
                        country_id: this.form.country_id,
                        city: this.form.city,
                        start_date_month: this.form.start_date_month,
                        start_date_year: this.form.start_date_year,
                        end_date_month: this.form.end_date_month,
                        end_date_year: this.form.end_date_year
                    }
                }).then(response => {
                    this.$emit("editedExperience",response.data);

                    // only clean form when adding a new record
                    if (!this.experience) {
                        this.form.title = null;
                        this.form.employment_type = 1;
                        this.form.company = null;
                        this.form.country_id = null;
                        this.form.country = null;
                        this.form.city = null;
                        this.form.start_date_month = null;
                        this.form.start_date_year = null;
                        this.form.end_date_month = null;
                        this.form.end_date_year = null;
                    }

                    this.form.errors = {};

                }).catch((error) => {
                    this.form.errors = error.response.data.errors;
                });
            }
        }
    };
</script>
