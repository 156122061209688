<template>
        <div class="relative z-10">
            <form>
                <ul class="form-wrapper mb-5">
                    <li class="form-row">
                        <div class="input-grp">
                            <jet-label
                                class="form-label mt-4"
                                for="task"
                                value="Task *"
                            />
                            <ckeditor
                                data-hj-suppress
                                v-model="form.task"
                                :editor="editor"
                                :config="editorConfig"
                            />
                        </div>
                    </li>

                    <li class="form-row">
                        <primary-button
                            :title="task ? 'Save task' : 'Save and add task'"
                            :disabled="isDisabled"
                            @click.prevent="submitForm"
                        />
                    </li>
                </ul>
            </form>
        </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import JetLabel from "@/Jetstream/InputLabel";
    import PrimaryButton from "@/components/form/PrimaryButton";

    export default {
        components: {
            JetLabel,
            PrimaryButton,
        },
        props: {
            task: {
                default: null,
                type: Object,
            },
        },
        emits: ["editedTask"],
        data() {
            return {
                form: {
                    processing: false,
                    task: (this.task)?this.task.task:"",
                }
            };
        },
        computed : {
            ...mapGetters(["authUser", "isProfessional"]),
            isDisabled() {  
                return this.form.processing;
            }
        },
        methods: {
            submitForm() {
                if(this.task){
                    var url = "/tasks/" + this.task.id;
                    var method = "put";
                }else{
                    var url = "/trajectories/" + (this.isProfessional ? this.authUser.activeTrajectory?.id : this.$route.params.id) + "/tasks";
                    var method = "post";
                }

                apiClient({
                    method: method,
                    url: url,
                    data: {
                        id: this.task?.id,
                        task: this.form.task,
                    }
                }).then(response => {
                    this.$emit("editedTask",response.data);

                    // only clean form when adding a new record
                    if (!this.task) {
                        this.form.task = "";
                    }

                }).catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error.response);
                });
            }
        },
    };
</script>
