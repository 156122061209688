<template>
        <div>
            <update-profile-information-form
                v-if="$can('Update email address')"
                :user="authUser"
            />
            <update-password-form class="mt-10" />
            <two-factor-authentication-form class="mt-10" />
            <logout-other-browser-sessions-form class="mt-10" />
            <delete-user-form
                v-if="$can('Delete account')"
                class="mt-10"
            />
        </div>
</template>

<script>
    import DeleteUserForm from "./DeleteUserForm";
    import LogoutOtherBrowserSessionsForm from "./LogoutOtherBrowserSessionsForm";
    import TwoFactorAuthenticationForm from "./TwoFactorAuthenticationForm";
    import UpdatePasswordForm from "./UpdatePasswordForm";
    import UpdateProfileInformationForm from "./UpdateProfileInformationForm";
    import { mapGetters } from "vuex";

    export default {

        components: {
            DeleteUserForm,
            LogoutOtherBrowserSessionsForm,
            TwoFactorAuthenticationForm,
            UpdatePasswordForm,
            UpdateProfileInformationForm,
        },
        computed: {
            ...mapGetters(["authUser"]),
        },
    };
</script>
