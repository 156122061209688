<template>
        <div
            aria-label="Pagination"
            class="flex items-center justify-between py-4"
        >
            <p class="text-sm text-gray-500">
                Page {{ meta.current_page }} of {{ meta.last_page }}
            </p>
            <div class="flex">
                <button
                    v-if="links.prev"
                    rel="first"
                    type="button"
                    class="px-2.5 py-1.5 m-1 text-sm border rounded"
                    @click="firstPage"
                >
                    First
                </button>

                <button
                    v-if="links.prev"
                    rel="prev"
                    type="button"
                    :class="{ 'rounded-r': !links.next }"
                    class="px-2.5 py-1.5 m-1 text-sm border rounded"
                    @click="prevPage"
                >
                    Previous
                </button>

                <button
                    v-if="links.next"
                    rel="next"
                    type="button"
                    :class="{ 'rounded-l': !links.prev }"
                    class="px-2.5 py-1.5 m-1 text-sm border rounded"
                    @click="nextPage"
                >
                    Next
                </button>

                <button
                    v-if="links.next"
                    rel="last"
                    type="button"
                    class="px-2.5 py-1.5 m-1 text-sm border rounded"
                    @click="lastPage"
                >
                    Last
                </button>
            </div>
        </div>
</template>

<script>
    export default {
        props: {
            queryParams: {
                type: Object,
                required: true,
            },
            meta: {
                type: Object,
                required: true,
            },
            links: {
                type: Object,
                required: true,
            },
        },
        methods: {
            firstPage() {


                //this.$store.dispatch(this.action, this.links.first).then(() => {
                        let query = this.queryParams;
                        query['page'] = 1;
                console.log("emit changeRoute", query);

                this.$emit('changeRoute', query)
               // });
            },
            prevPage() {
               // this.$store.dispatch(this.action, this.links.prev).then(() => {

                        let query = this.queryParams;
                        query['page'] = this.meta.current_page - 1;
                console.log("emit changeRoute", query);

                this.$emit('changeRoute', query)

               // });
            },
            nextPage() {
                //this.$store.dispatch(this.action, this.links.next).then(() => {


                        let query = this.queryParams;
                        query['page'] = this.meta.current_page + 1;

                console.log("emit changeRoute", query);

                this.$emit('changeRoute', query)

                //});
            },
            lastPage() {
                //this.$store.dispatch(this.action, this.links.last).then(() => {

                        console.log(this.$route);

                        let query = this.queryParams;
                        query['page'] = this.meta.last_page;
                        console.log("emit changeRoute", query);

                        this.$emit('changeRoute', query)




                //});
            },
        },
    };
</script>