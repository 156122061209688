<template>
        <router-link
            :to="{ name: 'learning' }"
            class="flex items-center font-semibold mb-4 md:mb-10 -mt-16"
        >
            <i class="flex items-center mr-2 h-full"><img
                src="../../images/icon-left-arrow.svg"
                alt="icon"
            ></i>
            Back to all learnings
        </router-link>
</template>
