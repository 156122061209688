<template>
        <div class="block">
            <div
                v-show="!showEditLanguage"
                class="flex group text-base items-center h-16 relative"
            >
                <p
                    class="w-1/2 md:w-1/3 mb-0 font-semibold" data-hj-suppress
                >
                    {{ userLanguage.language.name }}
                </p>
                <p
                    v-if="percentageNames[userLanguage.percentage]"
                    class="mb-0"
                    data-hj-suppress
                >
                    {{ percentageNames[userLanguage.percentage] }}
                </p>
                <float-right-component v-if="canEditLanguage">
                    <action-button
                        color="secondary"
                        icon="pencil"
                        @click="showEditLanguage = !showEditLanguage"
                    />
                    <action-button
                        color="primary"
                        icon="bin"
                        @click.prevent="deleteLanguage"
                    />
                </float-right-component>
            </div>
            <language-form
                v-show="showEditLanguage"
                :language="userLanguage"
                @edited-language="editLanguage"
            />
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import LanguageForm from "@/Pages/Profile/LanguageForm";
    import ActionButton from "@/components/form/button/ActionButton";
    import FloatRightComponent from "@/components/FloatRightComponent";

    export default {
        components: {
            LanguageForm,
            ActionButton,
            FloatRightComponent
        },
        props: {
            userLanguage: {
                type: Object,
                default: () => {},
            },
            canEditLanguage: {
                type: Boolean,
            },
        },
        emits: ["updateLanguageList"],
        data() {
            return {
                showEditLanguage: false,
                percentageNames: {
                    100: "Yes, it's my mother tongue",
                    80: "Yes, I am business fluent",
                    60: "Yes, I can watch movies without subtitles",
                    40: "Yeah, but only know the basics",
                    20: "No"
                }
            };
        },
        methods: {
            deleteLanguage() {
                if (this.$store.getters.authUser?.id) {
                    apiClient.delete("/users/" + this.$store.getters.authUser.id + "/languages/" + this.userLanguage.id)
                        .then(response => {
                            this.$emit("updateLanguageList");
                        }).catch((error) => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                        //this.form.errors = error.response.data.errors;
                    });
                }
            },
            editLanguage() {
                this.$emit("updateLanguageList",this.userLanguage.id);
                this.showEditLanguage = false;
            }
        }
    };
</script>
