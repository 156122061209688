<template>
        <div class="relative">
            <div v-if="files?.length">
                <documents-item
                    v-for="item in (showAllFiles ? files : files?.slice(0, 3))"
                    :key="item.id"
                    :file="item"
                    @update-document-list="fetchTrajectoryFiles"
                />
            </div>
            <p
                v-else
                class="font-medium mb-0 text-base"
            >
                When added, the documents will appear here
            </p>
            <div
                class="common-add-btn extend-btn-info mb-5"
                @click="showAddFile=!showAddFile"
            >
                <div class="w-full justify-between flex">
                    <h4>Add document</h4>
                    <button
                        v-if="files?.length > 3"
                        class="flex items-center font-semibold whitespace-nowrap text-base"
                        :class="isCoach ? 'right-0': 'left-0'"
                        @click="showAllFiles = !showAllFiles"
                    >
                        {{ showAllFiles ? '-' : '+' }}
                        <span class="underline ml-2">
                            View
                            {{ showAllFiles ? 'less' : 'all' }}
                            documents
                        </span>
                    </button>
                </div>
            </div>
            <documents-form
                v-show="showAddFile"
                @edited-document="fetchTrajectoryFiles"
            />
        </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import DocumentsItem from "./DocumentsItem";
    import DocumentsForm from "./DocumentsForm";

    export default {
        components: {
            DocumentsItem,
            DocumentsForm
        },
        data() {
            return {
                showAddFile: false,
                files: null,
                showAllFiles: false,
            };
        },
        computed: {
            ...mapGetters(["authUser", "isProfessional"]),
        },
        created() {
            this.fetchTrajectoryFiles();
        },
        methods: {
            fetchTrajectoryFiles() {
                apiClient.get("/trajectories/" + (this.isProfessional ? this.authUser.activeTrajectory?.id : this.$route.params.id) + "/files")
                    .then(response => {
                        this.files = response.data.data;
                        this.showAddFile = false;
                    });
            }
        }
    };
</script>
