<template>
        <div class="content-text-part body-text">
            <learning-nav />
            <h1
                v-if="editLearning"
                class="mb-10"
            >
                Edit learning
            </h1>
            <h1
                v-else
                class="mb-10"
            >
                Add new learning
            </h1>
            <form @submit.prevent="submitForm">
                <div class="add-learning">
                    <div class="learning-section">
                        <div class="learning-title">
                            <img
                                src="../../images/icon-content-type.svg"
                                alt="content-type"
                            >
                            <h2>Content Type</h2>
                        </div>
                        <div class="radio-grp">
                            <div class="radio-row">
                                <input
                                    id="learning_type1"
                                    v-model="form.learning_type_id"
                                    type="radio"
                                    value="1"
                                >
                                <label
                                    class="radio-label"
                                    for="learning_type1"
                                >eLearning</label>
                            </div>
                            <div class="radio-row">
                                <input
                                    id="learning_type2"
                                    v-model="form.learning_type_id"
                                    type="radio"
                                    value="2"
                                >
                                <label
                                    class="radio-label"
                                    for="learning_type2"
                                >Video</label>
                            </div>
                            <div class="radio-row">
                                <input
                                    id="learning_type3"
                                    v-model="form.learning_type_id"
                                    type="radio"
                                    value="3"
                                >
                                <label
                                    class="radio-label"
                                    for="learning_type3"
                                >Podcast</label>
                            </div>
                            <div class="radio-row">
                                <input
                                    id="learning_type4"
                                    v-model="form.learning_type_id"
                                    type="radio"
                                    value="4"
                                >
                                <label
                                    class="radio-label"
                                    for="learning_type4"
                                >Article</label>
                            </div>
                            <div class="radio-row">
                                <input
                                    id="learning_type5"
                                    v-model="form.learning_type_id"
                                    type="radio"
                                    value="5"
                                >
                                <label
                                    class="radio-label"
                                    for="learning_type5"
                                >Template</label>
                            </div>
                            <span
                                v-for="error in form.errors.learning_type_id"
                                v-show="form.errors.learning_type_id"
                                :key="error"
                                class="error-msg block"
                            >{{ error }}</span>
                        </div>
                    </div>
                    <div class="learning-section">
                        <div class="learning-title">
                            <img
                                src="../../images/icon-category.svg"
                                alt="category"
                            >
                            <h2>Category</h2>
                        </div>
                        <div class="checkbox-row">
                            <input
                                id="learning_category_1"
                                v-model="form.learning_category_id"
                                type="checkbox"
                                class="mr-2"
                                value="1"
                            >
                            <label
                                for="learning_category_1"
                                class="checkbox-label"
                            >Marketing</label>
                        </div>
                        <div class="checkbox-row">
                            <input
                                id="learning_category_3"
                                v-model="form.learning_category_id"
                                type="checkbox"
                                class="mr-2"
                                value="3"
                            >
                            <label
                                for="learning_category_3"
                                class="checkbox-label"
                            >Communication</label>
                        </div>
                        <div class="checkbox-row">
                            <input
                                id="learning_category_2"
                                v-model="form.learning_category_id"
                                type="checkbox"
                                class="mr-2"
                                value="2"
                            >
                            <label
                                for="learning_category_2"
                                class="checkbox-label"
                            >Data</label>
                        </div>
                        <div class="checkbox-row">
                            <input
                                id="learning_category_4"
                                v-model="form.learning_category_id"
                                type="checkbox"
                                class="mr-2"
                                value="4"
                            >
                            <label
                                for="learning_category_4"
                                class="checkbox-label"
                            >Sales</label>
                        </div>
                        <span
                            v-for="error in form.errors.learning_category_id"
                            v-show="form.errors.learning_category_id"
                            :key="error"
                            class="error-msg block"
                        >{{ error }}</span>
                    </div>
                    <div class="learning-section">
                        <div class="learning-title">
                            <img
                                src="../../images/icon-duration.svg"
                                alt="duration"
                            >
                            <h2>Duration</h2>
                        </div>
                        <div class="learning-duration">
                            <div>
                                <label class="form-label">Hours</label>
                                <select 
                                    v-model="form.hours"
                                    class="form-input"
                                >
                                    <option
                                        v-for="hour in hours"
                                        :key="hour"
                                        :value="hour"
                                    >
                                        {{ hour }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label class="form-label">Minutes</label>
                                <select
                                    v-model="form.minutes"
                                    class="form-input"
                                >
                                    <option
                                        v-for="minute in minutes"
                                        :key="minute"
                                        :value="minute"
                                    >
                                        {{ minute }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="learning-section">
                        <div class="input-grp">
                            <label class="form-label">Title</label>
                            <input
                                v-model="form.title"
                                class="form-input"
                                type="text"
                            >
                            <span
                                v-for="error in form.errors.title"
                                v-show="form.errors.title"
                                :key="error"
                                class="error-msg block"
                            >{{ error }}</span>
                        </div>
                        <div class="input-grp">
                            <label
                                class="form-label"
                            >What will you learn?</label>
                            <ckeditor
                                v-model="form.summary"
                                :editor="editor"
                                :config="editorConfig"
                                class="list-style-type: disc"
                            />
                            <span
                                v-for="error in form.errors.summary"
                                v-show="form.errors.summary"
                                :key="error"
                                class="error-msg block"
                            >{{ error }}</span>
                        </div>
                        <div class="input-grp">
                            <label
                                class="form-label"
                            >Outline</label>
                            <ckeditor
                                v-model="form.outline"
                                :editor="editor"
                                :config="editorConfig"
                            />
                            <span
                                v-show="form.errors.outline"
                                class="error-msg"
                            >{{ form.errors.outline }}</span>
                        </div>
                        <div class="input-grp">
                            <label class="form-label">External content link</label>
                            <input
                                v-model="form.external_link"
                                class="form-input"
                                type="text"
                            >
                            <span
                                v-show="form.errors.external_link"
                                class="error-msg"
                            >{{ form.errors.external_link }}</span>
                        </div>
                        <div class="input-grp" :class="{ 'input-error' : form.errors.image}">
                            <label class="form-label">Image upload</label>
                            <upload-field
                                :file="
                                    typeof form.image === 'string'
                                        ?
                                            {file:
                                                form.image}
                                        :
                                            form.image"
                                @upload="(value) => form.image = value"
                            />
                            <span
                                    v-for="error in form.errors.image"
                                    :key="error"
                                    class="error-msg"
                            >{{ error }}</span>
                        </div>
                        <div class="input-grp" :class="{ 'input-error' : form.errors.file}">
                            <label class="form-label">Upload file</label>
                            <upload-field
                                :file="form.file"
                                @upload="(value) => form.file = value"
                            />
                            <span
                                    v-for="error in form.errors.file"
                                    :key="error"
                                    class="error-msg"
                            >{{ error }}</span>
                        </div>
                    </div>
                    <div class="checkbox-row">
                        <input
                            id="publish"
                            v-model="form.publish"
                            type="checkbox"
                            class="mr-2"
                            value="Publish learning"
                        >
                        <label
                            for="publish"
                            class="checkbox-label"
                        >Publish learning</label>
                    </div>
                    <primary-button
                        title="Save learning"
                        @click.prevent="submitForm"
                    />
                    <div
                        v-if="submitted"
                        class=""
                    >
                        <strong>Learning saved!</strong>
                    </div>
                </div>
            </form>
        </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import Compressor from "compressorjs";
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import LearningNav from "./LearningNav";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import UploadField from "@/components/form/UploadField";


    export default {
        components: {
            LearningNav,
            PrimaryButton,
            UploadField,
        },
        data() {
            return {
                submitted: false,
                form: {
                    errors: {},
                    processing: false,
                    learning_category_id: [],
                    learning_type_id: "",
                    title: "",
                    summary: "",
                    image: null,
                    file: null,
                    outline: "",
                    duration: "",
                    external_link: "",
                    date_published: "",
                    publish: false,
                    hours: 0,
                    minutes: 0,
                },
            };
        },
        validations: {
            form: {
            }
        },
        computed: {
            ...mapGetters(["authUser", "isAdmin", "theme"]),
            minutes() {
                const minuteArray = [];
                for(let i = 0; i < 12; i ++) {
                    minuteArray.push(5 * i);
                }
                return minuteArray;
            },
            hours() {
                const hours = [];
                for(let i = 0; i < 24; i ++) {
                    hours.push(i);
                }
                return hours;
            },
            editLearning() {
                return this.$route.params.id;
            }
        },
        mounted() {
            if(this.$route.params.id) {
                return this.getLearning();
            }
        },
        methods: {
            async getLearning() {
                return apiClient.get("/learnings/"+ this.$route.params.id)
                    .then(response => {
                        this.form.learning_category_id = [response.data?.data.learning_category_id.toString()];
                        this.form.learning_type_id = response.data?.data.learning_type_id.toString();
                        this.form.title = response.data?.data.title;
                        this.form.outline = response.data?.data.outline;
                        this.form.summary = response.data?.data.summary;
                        this.form.date_published = response.data?.data.date_published;
                        this.form.hours = this.formatDurationInHours(response.data?.data.duration);
                        this.form.minutes = this.formatDurationInMinutes(response.data?.data.duration);
                        this.form.publish = response.data?.data.date_published ? true : false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line no-console
                        console.log(e);
                        //test
                    });
            },
            async submitForm() {
                this.calculateDuration(this.form.hours, this.form.minutes);
                if(this.form.learning_category_id.length !== 1) return this.form.errors.learning_category_id = ["The learning type id field is required. Please select only one"];
                this.form.processing = true;
                this.form.errors = {};

                const uploadFiles = new FormData();
                const uploadLearning = new FormData();

                if (this.form.file) {
                    uploadFiles.append("name", this.form.file.name);
                    uploadFiles.append("file", this.form.file);
                }
                if(this.form.image) {
                        const compressedImage = await this.compressFile(this.form.image);
                        uploadLearning.append("image", compressedImage);
                }
                
                
                this.form.publish ? uploadLearning.append("date_published", new Date().toISOString().split("T")[0]) : uploadLearning.append("date_published", "");

                uploadLearning.append("learning_category_id", this.form.learning_category_id[0]);
                uploadLearning.append("learning_type_id", this.form.learning_type_id);
                uploadLearning.append("title", this.form.title);
                uploadLearning.append("summary", this.form.summary);
                uploadLearning.append("outline", this.form.outline);
                uploadLearning.append("duration", this.form.duration);
                uploadLearning.append("external_link", this.form.external_link);

                if(this.$route.params.id) uploadLearning.append("_method", "PUT");
                const url = this.$route.params.id ? `/learnings/${this.$route.params.id}` : "/learnings";

                
                apiClient({
                    method: "post",
                    url: url,
                    data: uploadLearning
                })
                .then(response => {
                    const id = response.data.data.id;
                    if(this.form.file) {
                        apiClient({
                            method: "post",
                            url: `/learnings/${id}/files`,
                            data: uploadFiles
                        }).then(() => {
                            return this.$router.push({ name: "learning-details", params: { id } });
                            
                        })
                            .catch((error) => {
                                this.form.processing = false;
                                this.form.errors = (error.response.data.errors) ? error.response.data.errors : {};
                                return this.$router.push({ name: "edit-learning", params: { id } });
                            });
                    } else {
                        return this.$router.push({ name: "learning-details", params: { id } });
                    };
                
                }).catch(error => {
                    this.form.processing = false;
                    this.form.errors = (error.response.data.errors) ? error.response.data.errors : {};
                });
            },
            calculateDuration(hours, minutes) {
                return this.form.duration = hours * 60 + minutes;
            },
            formatDurationInHours(duration) {
                return Math.floor(duration / 60);
            },
            formatDurationInMinutes(duration) {
                return duration % 60;
            },
            compressFile(file) {
            const quality = file.size > 500000 ? 0.1 : 0.7;
            /* eslint-disable no-new */
            return new Promise((resolve, reject) => {
                new Compressor(file, {
                quality,
                success: (result) => resolve(new File([result], file.name, { type: file.type })),
                error: (e) => reject(e),
                });
            });
            },
        }
    };
</script>
