<template>
        <div class="hero-row">
            <div
                v-for="trajectory in trajectories"
                :key="trajectory.id"
                data-hj-suppress
                class="hero-col"
            >
                <user-widget
                    v-if="trajectory.user"
                    :user="trajectory.user"
                    bio
                    button-title="Go to hub"
                    button-icon="hub bg-white"
                    @button-click="() => $router.push({ name: 'hub-coach', params: { id: trajectory.id }, })"
                />
            </div>
            <div
                v-if="trajectories.length === 0"
                class="flex"
            >
                <div class="empty-hero">
                    <h2>No heroes assigned yet. They appear here when assigned to you.</h2>
                </div>
                <div class="empty-hero" />
                <div class="empty-hero" />
            </div>
        </div>
</template>

<script>
import {default as ApiService, apiClient } from "@/services/ApiService";
import { mapGetters } from "vuex";
import UserWidget from "@/components/cards/UserWidget";
    
    export default {
        components: {
            UserWidget
        },
        data() {
            return {
                trajectories: [],
            };
        },
        computed: {
            ...mapGetters(["authUser"]),
        },
        mounted() {
            this.fetchTrajectories();
        },
        methods: {
            fetchTrajectories() {
                apiClient.get(`/users/${this.authUser.id}/trajectories?filter[status]=2`)
                    .then(response => {
                        this.trajectories = response.data?.data;
                    });
            },
        }
    };
</script>
