import axios from "axios";
import store from "@/store";

const { VUE_APP_DOMAIN } = process.env;

function getApiUrl() {
    if (window.location.host === `${VUE_APP_DOMAIN}:81`) {
        return `http://${VUE_APP_DOMAIN}:8080`;
    }
    if (window.location.host === `${VUE_APP_DOMAIN}:82`) {
        return `http://${VUE_APP_DOMAIN}:8081`;
    }
    if (window.location.host.indexOf("yfk") > -1) {
        return "https://api.yfk.marketing";
    }
    if (window.location.host.indexOf("staging") > -1) {
        return "https://api-staging.youngheroes.com";
    }
    return "https://api.youngheroes.com";
}

function getWebsocketUrl() {
    if (window.location.host.indexOf("local") > -1) {
        return `${VUE_APP_DOMAIN}`;
    }
    if (window.location.host.indexOf("yfk") > -1) {
        return "api.yfk.marketing";
    }
    if (window.location.host.indexOf("staging") > -1) {
        return "api-staging.youngheroes.com";
    }
    return "api.youngheroes.com";
}

export const apiClient = axios.create({
    baseURL: getApiUrl() +"/api",
    withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (
            error.response &&
            [401, 419].includes(error.response.status) &&
            store.getters["authUser"]
        ) {
            store.dispatch("logout");
        }
        return Promise.reject(error);
    }
);

export default {
    getApiUrl,
    getWebsocketUrl
};
