<template>
        <div class="content-text-part">
            <h2 class="bottom-shape">
                {{ title }}
            </h2>
        </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: "",
            },
        },
    };
</script>