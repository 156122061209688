<template>
        <button
            :disabled="disabled"
            class="bg-primary hover:bg-primary-hover group flex flex-row justify-center items-center transition-colors duration-300 h-12 px-5 py-6 text-black w-full text-base rounded-full"
            :class="{'bg-opacity-25 pointer-events-none': disabled, 'flex-row-reverse': reversed }"
        >
            <span
                v-if="icon"
                class="icon"
                :class="'icon-'+icon + (reversed ? ' ml-1 md:ml-2 mr-0 md:mr-0' : ' mr-1 md:mr-2')"
            />
            {{ title }}
        </button>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
                default: ""
            },
            icon: {
                type: String,
                default: ""
            },
            reversed: Boolean,
            disabled: Boolean,
        },
    };
</script>