<template>
        <jet-action-section>
            <template #title>
                Browser sessions
            </template>

            <template #description>
                Manage and log out your active sessions on other browsers and devices.
            </template>

            <template #content>
                <div class="max-w-xl text-sm text-gray-600">
                    If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.
                </div>

                <!-- Other Browser Sessions -->
                <div
                    v-if="sessions.length > 0"
                    class="mt-5 space-y-6"
                >
                    <div
                        v-for="(session, i) in sessions"
                        :key="i"
                        class="flex items-center"
                    >
                        <div data-hj-suppress>
                            <svg
                                v-if="session.agent.is_desktop"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                class="w-8 h-8 text-gray-500"
                            >
                                <path d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>

                            <svg
                                v-else
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="w-8 h-8 text-gray-500"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    stroke="none"
                                /><rect
                                    x="7"
                                    y="4"
                                    width="10"
                                    height="16"
                                    rx="1"
                                /><path d="M11 5h2M12 17v.01" />
                            </svg>
                        </div>

                        <div class="ml-3" data-hj-suppress>
                            <div class="text-sm text-gray-600">
                                {{ session.agent.platform }} - {{ session.agent.browser }}
                            </div>

                            <div>
                                <div class="text-xs text-gray-500">
                                    {{ session.ip_address }},

                                    <span
                                        v-if="session.is_current_device"
                                        class="text-green-500 font-semibold"
                                    >This device</span>
                                    <span v-else>Last active {{ session.last_active }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex items-center mt-5">
                    <secondary-button
                        title="Log out other browser sessions"
                        @click.prevent="confirmLogout"
                    />

                    <jet-action-message
                        :on="form.recentlySuccessful"
                        class="ml-3"
                    >
                        Done.
                    </jet-action-message>
                </div>

                <!-- Log Out Other Devices Confirmation Modal -->
                <jet-dialog-modal
                    :show="confirmingLogout"
                    @close="closeModal"
                >
                    <template #title>
                        Log out other browser sessions
                    </template>

                    <template #content>
                        Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.
                    
                        <div
                            class="form-row"
                            :class="{ 'input-error' : form.errors.password }"
                        >
                            <div class="input-grp">
                                <jet-input
                                    ref="password"
                                    v-model="form.password"
                                    type="password"
                                    class="form-input"
                                    placeholder="Password"
                                    @keyup.enter="logoutOtherBrowserSessions"
                                />
    
                                <span
                                    v-for="error in form.errors.password"
                                    :key="error"
                                    class="error-msg"
                                >{{ error }}</span>
                            </div>
                        </div>
                    </template>

                    <template #footer>
                        <div class="flex justify-between w-full">
                            <secondary-button
                                title="Cancel"
                                @click.prevent="closeModal"
                            />

                            <div class="w-64 inline-block">
                                <primary-button
                                    title="Log out other browser sessions"
                                    :disabled="isDisabled"
                                    @click.prevent="logoutOtherBrowserSessions"
                                />
                            </div>
                        </div>
                    </template>
                </jet-dialog-modal>
            </template>
        </jet-action-section>
</template>

<script>
    import JetActionMessage from "@/Jetstream/ActionMessage";
    import JetActionSection from "@/Jetstream/ActionSection";
    import JetDialogModal from "@/Jetstream/DialogModal";
    import JetInput from "@/Jetstream/InputField";
    import SecondaryButton from "@/components/form/SecondaryButton";
    import PrimaryButton from "@/components/form/PrimaryButton";
    import AuthService from "../../services/AuthService";

    export default {

        components: {
            JetActionMessage,
            JetActionSection,
            JetDialogModal,
            JetInput,
            SecondaryButton,
            PrimaryButton,
        },

        data() {
            return {
                confirmingLogout: false,
                sessions: [],
                form: {
                    errors: {},
                    processing: false,
                    password: "",
                }
            };
        },
        mounted() {
            this.getActiveSessions();
        },
        methods: {
            getActiveSessions() {
                AuthService.getUserSessions()
                    .then((response) => {
                        this.sessions = response.data;
                });
            },
            confirmLogout() {
                this.confirmingLogout = true;

                setTimeout(() => this.$refs.password.focus(), 250);
            },
            logoutOtherBrowserSessions() {
                this.form.errors = {};
                this.form.processing = true;
                const payload = {password: this.form.password};

                AuthService.deleteOtherSessions(payload)
                    .then((response) => {
                        this.getActiveSessions();
                        this.closeModal();
                    })
                    .catch((error) => {
                        this.form.errors = error.response.data.errors;
                        this.$refs.password.focus();
                    }).finally(() => {
                        this.form.password = "";
                        this.form.processing = false;
                });
            },

            closeModal() {
                this.confirmingLogout = false;

                this.form.password = "";
                this.form.errors = {};
                this.form.processing = false;
            },
        },
    };
</script>
