<template>
        <div>
            <div class="data-display-part">
                <div class="education-info">
                    <education-item
                        v-for="item in educations"
                        :key="item.id"
                        :education="item"
                        :can-edit-education="canAddEducation"
                        @update-education-list="fetchUserEducations(); fetchUserProfilePercentage();"
                    />
                </div>
            </div>

            <div
                v-if="canAddEducation"
                class="add-education common-add-btn extend-btn-info "
                @click="showAddEducation=!showAddEducation"
            >
                <h4>Add education</h4>
                <p v-if="initProfile">
                    (you can also add this later)
                </p>
            </div>

            <education-form
                v-show="showAddEducation"
                @edited-education="fetchUserEducations(); fetchUserProfilePercentage();"
            />
        </div>
</template>
<script>
    import {default as ApiService, apiClient } from "@/services/ApiService";
    import EducationItem from "@/Pages/Profile/EducationItem";
    import EducationForm from "@/Pages/Profile/EducationForm";
    
    export default {
        components: {
            EducationItem,
            EducationForm
        },
        props: {
            initProfile: {
                type: Boolean
            },
            canAddEducation: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                showAddEducation: false,
                educations: null
            };
        },
        created() {
            this.fetchUserEducations();
        },
        methods: {
            fetchUserEducations() {
                const id = this.$route.params.id || this.$store.getters.authUser?.id;
                if (this.$store.getters.authUser?.id) {
                    apiClient.get("/users/" + id + "/educations")
                        .then(response => {
                            this.educations = response.data;
                            this.showAddEducation = false;
                        });
                }
            },
            fetchUserProfilePercentage() {
                this.$store.dispatch("getAuthUser");
            }
        }
    };
</script>
