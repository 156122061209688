<template>
        <a
            class="w-full"
            target="_blank"
            :href="link"
        >
            <primary-button
                :title="title"
                icon="external"
                reversed
            />
        </a>
</template>

<script>
    import PrimaryButton from "@/components/form/PrimaryButton";

export default {
    components: {
            PrimaryButton,
        },
    props: {
        link: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
    },
};
</script>
